import React from 'react';
import { exporter } from 'utils/exporter';
import UserList from './list';
import { getAllUsers } from '../actions';

const stateProps = function (state, props) {
    return {};
};

const dispatchProps = {
    getAllUsers
};

class UserListContainer extends React.Component {
    componentDidMount() {
        this.props.getAllUsers();
    }

    render() {
        return <UserList />;
    }
}

export default exporter(UserListContainer)
    .withState(stateProps, dispatchProps)
    .export();
