import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { connectComponentState } from 'react-redux-setstate';
//https://github.com/mmiller42/react-redux-setstate
import withRoot from 'layout/withRoot';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';

//This wraps up some of the very long winded chaining of HOC's used for components and avoids all the includes that go with it
//
// e.g: connect(stateProps, dispatchProps)(withStyles(styles)(connectComponentState()(ManageFramework)));
// exporter(ManageFramework).connectComponentState().withStyles(styles).withState(stateProps, dispatchProps);
class Connector {
    constructor(component) {
        this.component = component;
        this.componentName = component.name;
    }

    /**
     * Sets up JSS styles for the given component, accessible via props.classes
     *@param {Object} styles JSS styles object
     *@param bool inject theme into props.theme
     * @memberof Connector
     */
    withStyles = (styles, withTheme) => {
        this.hasStyles = true;

        if (withTheme != undefined) {
            let options = { withTheme: withTheme };
            this.component = withStyles(styles, options)(this.component);
        } else {
            this.component = withStyles(styles)(this.component);
        }

        return this;
    };

    /**
     * Connects the component to componentState (react-redux-setstate)
     * This must be called prior to any other function
     * @param {Object} componentStateOptions Options to be passed to componentState
     * @memberof Connector
     */
    withComponentState = generateName => {
        if (!generateName) {
            generateName = () => this.componentName;
        } else if (typeof generateName !== 'function') {
            //generateName must be a function
            throw 'generateName must be a function';
        }

        // component state needs to be run first, lets check
        if (this.hasState || this.hasRoot || this.hasStyles || this.hasForm) {
            throw 'withComponentState() must be the first export modifier called';
        }

        this.hasComponentState = true;

        this.component = connectComponentState(
            generateName,
            'components'
        )(this.component);
        return this;
    };

    /**
     * Connects the component to Redux
     *  @param {Object} stateProps state properties to be connected
     *  @param {Object} dispatchProps dispatch properties to be connected
     */
    withState = (stateProps, dispatchProps) => {
        this.hasState = true;
        this.component = connect(stateProps, dispatchProps)(this.component);
        return this;
    };

    /**
     * Wraps the component in withRoot()
     *
     * @memberof Connector
     */
    withRoot = () => {
        this.hasRoot = true;
        this.component = withRoot(this.component);
        return this;
    };

    /**
     * Connects the component to reduxForm.
     * If there is multiple occurances of a form at one time ensure the component is given a form="name" property
     * By default the form has enableReinitialize and keepDirtyOnReinitialize set to true
     * @param {Object} options options object to be passed on to ReduxForm
     * @memberof Connector
     */
    withForm = options => {
        if (this.hasState || this.hasRoot || this.hasStyles || this.hasRouter) {
            throw 'Called from: ' +
                this.componentName +
                ' - withForm() must be called before withRoot, withState, withRouter and withStyles';
        }

        this.hasForm = true;

        var formOptions = {
            enableReinitialize: true, // This allows the form to be updated via data changes
            keepDirtyOnReinitialize: true,
            updateUnregisteredFields: true,
            ...options
        };

        if (!formOptions.form) {
            // If there isnt a form name passed into the options, use a default one
            // If props.form is set, this setting is ignored and overridden with what is passed in
            formOptions.form = this.componentName + '_form';
        }
        this.component = reduxForm(formOptions)(this.component);

        return this;
    };

    withRouter = () => {
        this.hasRouter = true;
        this.component = withRouter(this.component);
        return this;
    };

    /**
     * Final call in the chain, this will return the component object ready for exporting.
     *
     * @memberof Connector
     */
    export = () => {
        if (this.hasForm && !this.hasState) {
            throw 'Called from: ' +
                this.componentName +
                ' - You need to use .withState() if you are using withForm()';
        }

        return this.component;
    };
}

/**
 * Function used to generate a chainable export wrapper.
 * This simplifies some of the long winded chaining sometimes needed.
 *
 * @param {*} component Component
 * @returns
 */
export function exporter(component) {
    return new Connector(component);
}
