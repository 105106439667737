import React from 'react';
import { connect } from 'react-redux';

import withRoot from 'layout/withRoot';
import { resetPassword } from '../../account/actions'
import ResetPasswordForm from './ResetPasswordForm'

const stateProps = function (state, props) {
    return {
        accountState: state.account,
        token: props.match.params.token
    }
}
const dispatchProps = {
    resetPassword: resetPassword
}

class ResetPasswordFormContainer extends React.Component {
    resetPassword = (values) => {
        this.props.resetPassword(values);
    }

    render() {
        const { token, accountState } = this.props;
        return (
            <ResetPasswordForm onSubmit={this.resetPassword} accountState={accountState} 
                initialValues={{token: token}}/>
        );
    }
}

export default connect(stateProps, dispatchProps)(withRoot(ResetPasswordFormContainer));
