import { createSelector } from 'reselect';
import types from 'prism/types';
import { requestLookup } from 'ax/rest';

const getQuestionsRequestState = state =>
    requestLookup(state, types.get_tag_questions);

const questionsState = state => state.prism.questions;
const tagState = state => state.prism.tags;
const getTagId = (state, props) => props.tagId;

const questionsLoading = createSelector(
    [getQuestionsRequestState],
    getQuestionsRequestState => {
        return getQuestionsRequestState.loading;
    }
);

const _findPriority = (controlSetting, tag) => {
    let priority = 1;
    if (controlSetting) {
        priority = controlSetting.priority;
        if (controlSetting.tagPriorities) {
            let tagPriority = controlSetting.tagPriorities.find(
                x => x.tag == tag
            );
            if (tagPriority) {
                priority = tagPriority.priority;
            }
        }
    }
    return priority;
};

const getGroupedQuestions = createSelector(
    [questionsState, tagState, getTagId],
    (questions, tags, tagId) => {
        if (!questions) return [];

        let result = {};
        result['Other'] = [];

        let tag = tags[tagId];

        // Map the questions into an array and filter based on the tag
        let controls = Object.keys(questions).map(id => questions[id]);
        let filteredQuestions = controls.filter(x =>
            x.revision.tags.some(z => z == tagId)
        );

        if (!tag.settings) {
            result['Other'] = filteredQuestions;
            return result;
        }

        let ordering = tag.settings.ordering;
        for (const name of ordering) {
            // Filter down the questions into groups for ordering
            result[name] = controls
                .filter(x => x.revision.tags.some(z => z == name))
                .sort((x, z) => {
                    //Sort based on priority
                    var first = x.revision.tagSettings.find(
                        t => t.tag == tagId
                    );
                    var second = z.revision.tagSettings.find(
                        t => t.tag == tagId
                    );

                    var firstPriority = _findPriority(first, name);
                    var secondPriority = _findPriority(second, name);

                    return firstPriority - secondPriority;
                })
                .reverse();

            //Set the remaining questions to exclude any that were in this group
            filteredQuestions = filteredQuestions.filter(
                x => !result[name].includes(x)
            );
        }

        //Assign any questions not within a group to "Other"
        result['Other'] = filteredQuestions;
        return result;
    }
);

const getQuestions = createSelector(
    [questionsState, getTagId],
    (questions, tagId) => {
        if (!questions) return [];
        return Object.keys(questions)
            .map(id => questions[id])
            .filter(x => x.revision.tags.some(z => z == tagId));
    }
);

export {
    //currentTag,
    questionsLoading,
    getQuestions,
    getGroupedQuestions
};
