import { createActions } from 'ax/utils';
import types from './types.js';
import prismService from './service';
import { ActionGenerator, generateAction } from 'ax/utils';
import config from '../common/config';

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.overseerUrl,
        sendInstance: false
    },
    signalr: {
        send: true
    }
});

const actions = createActions(types);

const getTags = () =>
    generator.GET(types.get_frameworks, {
        url: 'api/prism/tags'
    });

const patchTag = (original, updated) =>
    generator.PATCH(types.patch_tag, {
        url: 'api/prism/tags/' + original.id,
        source: original,
        target: updated,
        snackbar: {
            error: 'Failed to update Tag',
            success: 'Tag updated'
        }
    });

const createTag = tag =>
    generateAction(types.create_framework, prismService.createTag(tag));
const getQuestionsForTag = (tag, mode) =>
    generateAction(
        types.get_tag_questions,
        prismService.getQuestions(tag, mode)
    );
const createQuestion = tag =>
    generateAction(types.create_question, prismService.createQuestion(tag));
const patchQuestionRevision = (original, revision) =>
    generator.PATCH(types.patch_revision, {
        url:
            'api/prism/questions/' +
            revision.questionId +
            '/revision/' +
            revision.id,
        source: original,
        target: revision
    });
// generateAction(
//     types.patch_revision,
//     prismService.patchQuestionRevision(original, revision)
// );
const getIssues = () =>
    generateAction(types.get_issues, prismService.getIssues());

const tagAdded = tag => actions.tag_added(tag);
const tagUpdated = tag => actions.tag_updated(tag);

const getControlsForTag = (tag, mode) => {
    return generator.create({
        shouldUpdate: ({ request }) => {
            return !request.called;
        },
        action: generator.GET,
        type: types.get_tag_controls,
        options: {
            url: `api/prism/tags/${tag}/controls/${mode}`,
            snackbar: { error: 'Could not load tag controls' }
        },
        key: `${tag}_${mode}`
    });
};

const createControl = tag => {
    return generator.create({
        action: generator.POST,
        type: types.create_control,
        options: {
            url: `api/prism/controls/create/${tag}`,
            snackbar: {
                success: 'Successfully created control',
                error: 'Could not create control'
            }
        }
    });
};

const patchControlRevision = (source, target) =>
    generator.PATCH(types.patch_control_revision, {
        url: `api/prism/controls/${target.controlId}/revision/${target.id}`,
        source,
        target,
        snackbar: {
            error: 'Failed to update Control',
            success: 'Control updated'
        }
    });

export {
    getTags,
    createTag,
    patchTag,
    tagAdded,
    tagUpdated,
    getQuestionsForTag,
    createQuestion,
    patchQuestionRevision,
    getIssues,
    getControlsForTag,
    createControl,
    patchControlRevision
};
