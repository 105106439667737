import { createActions } from 'ax/utils';
import types from './types.js';
import usersService from '../services/usersService';
import jsonPatch from 'fast-json-patch';
import { send } from 'ax/SignalR';
import { ActionGenerator } from 'ax/utils';
import config from 'common/config';

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.overseerUrl,
        sendInstance: false
    }
});

const actions = createActions(types);

const getAllUsers = () => {
    return generator.GET(types.get_all_users, {
        url: 'api/users',
        snackbar: {
            error: 'Could not get all users'
        }
    });
};

const getUserById = props => dispatch => {
    const userId = props.match.params.userId;

    dispatch(actions.get_user());

    const request = usersService
        .getUserById(userId)
        .then(data => {
            dispatch(actions.get_user_success(data));
        })
        .catch(error => {
            dispatch(actions.get_user_failed(error));
        });

    return request;
};

const patchUser = (current, newValues) => {
    return generator.PATCH(types.patch_user, {
        snackbar: {
            error: 'Something went wrong. Please try again.',
            success:
                'User updated. For any two factor authentication changes to take effect user will need to logout and then login.'
        },
        url: `api/users/` + current.id,
        source: current,
        target: newValues
    });
};

const resetPassword = id =>
    generator.GET(types.force_reset_password, {
        url: 'api/users/' + id + '/force',
        snackbar: {
            error: 'Could not reset password',
            success: 'Password has been reset'
        }
    });

const getUserActivity = id =>
    generator.GET(types.get_user_activity, {
        url: `api/vault/activity/user/${id}`,
        snackbar: {
            error: 'Could not get user activity'
        }
    });

const getUserBrowserInfo = id =>
    generator.GET(types.get_user_browser_info, {
        url: `api/vault/browser/${id}`,
        snackbar: {
            error: 'Could not get user browser info'
        }
    });

export {
    getAllUsers,
    patchUser,
    getUserById,
    resetPassword,
    getUserActivity,
    getUserBrowserInfo
};
