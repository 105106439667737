import React from 'react';
import { connect } from 'react-redux';

import FrameworkList from './list'
import { getTags } from '../../actions'
import { frameworksSelector, frameworksLoading } from '../../selectors'

const stateProps = function (state, props) {
    return {
        frameworks: frameworksSelector(state),
        //loading: frameworksLoading(state)
    }
}

const dispatchProps = {
    getTags: getTags
}

class FrameworkListContainer extends React.Component {

    componentDidMount() {
      //  this.props.getTags();
    }

    render() {
        const { frameworks } = this.props;
        return (
           
                <FrameworkList frameworks={frameworks} />
        
        );
    }
}

export default connect(stateProps, dispatchProps)(FrameworkListContainer);
