import React from 'react';
import { exporter } from 'utils/exporter';
import { SvgIcon } from '@material-ui/core';

class Logo extends React.Component {
    render() {
        return (
            <SvgIcon className={this.props.className} viewBox="0 0 270 50">
                <path
                    d="M78.81,95.17a13.53,13.53,0,0,1,10,13,13.33,13.33,0,0,1-1.72,6.67,10.7,10.7,0,1,0-8.25-19.68Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M78.58,98.06a13.54,13.54,0,0,1-8.12,19.73,10.51,10.51,0,0,0,4.78,1.12,10.69,10.69,0,0,0,3.34-20.85Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M71.83,94.59A13.55,13.55,0,0,1,80,74.88h0a10.7,10.7,0,1,0-8.16,19.72Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M66.8,98.62a10.63,10.63,0,0,0,4.8-1.13,13.55,13.55,0,0,1-9.11-17.7,12,12,0,0,1,.94-2,10.5,10.5,0,0,0-4.19,2.59A10.7,10.7,0,0,0,66.8,98.62Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M76.05,80.36l-.09.08c-.09.11-.19.21-.29.31l-.08.09-.27.33,0,0c-.11.13-.21.26-.31.4l0,0-.27.39h0a10.77,10.77,0,0,0-1.76,6.47h0A10.62,10.62,0,0,0,74,92.71a12.29,12.29,0,0,1,.55-1.58h0c.09-.23.19-.45.3-.67l0-.07.08-.15c.09-.18.18-.36.28-.53l.25-.42.12-.2,0,0,.18-.28c.08-.12.16-.24.25-.35l.15-.22.45-.57h0l.35-.4.14-.14.12-.13.19-.2.19-.18.24-.23.36-.31.26-.22.37-.29.29-.21.37-.26.31-.2.16-.1.28-.17.19-.1.1-.06.53-.27.1-.05c.23-.11.45-.21.68-.3h0l.71-.26.12,0,.7-.21.13,0c.25-.07.49-.13.74-.18a11.86,11.86,0,0,1,1.35-.2,12.63,12.63,0,0,1,1.38-.07,13.47,13.47,0,0,1,6.68,1.76,10.72,10.72,0,0,0-10.16-7.33h0a10.55,10.55,0,0,0-5.4,1.47A10.16,10.16,0,0,0,76.05,80.36Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M73.47,99.71a13.53,13.53,0,0,1-19.71-8.17,10.57,10.57,0,0,0-1.14,4.79,10.7,10.7,0,0,0,20.85,3.38Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M87.09,85.63A10.71,10.71,0,0,0,77,92.94a13.55,13.55,0,0,1,16.24,2.22,13.27,13.27,0,0,1,1.73,2.12,13.48,13.48,0,0,1,1.74,3.83,10.7,10.7,0,0,0-9.57-15.48Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M76.37,100a13.54,13.54,0,0,1-19.72,8.16A10.7,10.7,0,1,0,76.37,100Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M106.41,112.35c.77-2.07,1.64-4.3,2.59-6.7s2-4.85,3-7.36,2.14-5.08,3.27-7.67,2.25-5.13,3.36-7.6a6,6,0,0,1,1.9-2.57,4.69,4.69,0,0,1,2.69-.72,5.21,5.21,0,0,1,1.18.14,7.24,7.24,0,0,1,1,.32c.29.13.54.24.74.35a3.62,3.62,0,0,1,.35.21c.49.87,1.13,2.15,1.92,3.85s1.63,3.6,2.55,5.72,1.84,4.32,2.8,6.62,1.84,4.48,2.66,6.54,1.54,3.87,2.16,5.46,1,2.73,1.29,3.41h-6.11c-.16-.37-.36-.9-.6-1.58l-.81-2.2c-.3-.79-.6-1.58-.91-2.38s-.58-1.52-.83-2.14H115.12q-1,2.55-1.83,4.71c-.54,1.43-1,2.63-1.32,3.59Zm22.38-13.16c-.44-1.14-.93-2.4-1.49-3.78s-1.1-2.71-1.64-4-1-2.51-1.53-3.59-.86-1.9-1.14-2.45c-.4.86-.85,1.84-1.34,2.94s-1,2.25-1.53,3.47-1.05,2.47-1.57,3.73-1,2.51-1.49,3.71Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M142.27,101.78a16.46,16.46,0,0,1,.92-5.72,11.77,11.77,0,0,1,2.6-4.17,11,11,0,0,1,4-2.57,14.79,14.79,0,0,1,5.23-.88,12.5,12.5,0,0,1,4.87.93,12,12,0,0,1,3.87,2.55,11.53,11.53,0,0,1,2.57,3.89,12.81,12.81,0,0,1,.92,4.91,13.14,13.14,0,0,1-.9,4.93,11.48,11.48,0,0,1-2.5,3.87,11.65,11.65,0,0,1-3.75,2.53,12.12,12.12,0,0,1-4.71.9,10.26,10.26,0,0,1-4.67-1,9.14,9.14,0,0,1-3.2-2.5v12.18h-5.28Zm12.41,6.49a7.09,7.09,0,0,0,2.9-.58,6.68,6.68,0,0,0,2.27-1.6,7.08,7.08,0,0,0,1.46-2.41,8.65,8.65,0,0,0,.51-3,8.33,8.33,0,0,0-.51-2.94,6.83,6.83,0,0,0-3.73-3.94,7.25,7.25,0,0,0-2.9-.58,7.37,7.37,0,0,0-2.89.56,6.78,6.78,0,0,0-2.27,1.53A6.89,6.89,0,0,0,148,97.64a8.87,8.87,0,0,0,0,6,7.77,7.77,0,0,0,1.46,2.41,6.5,6.5,0,0,0,2.25,1.6A7.18,7.18,0,0,0,154.68,108.27Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M183.59,88.44a13.51,13.51,0,0,1,5,.93,12.28,12.28,0,0,1,4,2.57,11.79,11.79,0,0,1,2.69,3.89,12.16,12.16,0,0,1,1,4.89,12,12,0,0,1-1,4.84,11.69,11.69,0,0,1-2.69,3.89,12.69,12.69,0,0,1-4,2.6,13.76,13.76,0,0,1-10.05,0,12.73,12.73,0,0,1-4.06-2.6,11.66,11.66,0,0,1-2.68-3.89,12,12,0,0,1-1-4.84,12.17,12.17,0,0,1,1-4.89,11.77,11.77,0,0,1,2.68-3.89,12.33,12.33,0,0,1,4.06-2.57A13.45,13.45,0,0,1,183.59,88.44Zm0,19.88a7.39,7.39,0,0,0,2.92-.58,6.75,6.75,0,0,0,2.3-1.62,7.91,7.91,0,0,0,1.48-2.41,8.28,8.28,0,0,0,.53-3,8.71,8.71,0,0,0-.53-3,7.44,7.44,0,0,0-1.48-2.43,6.84,6.84,0,0,0-2.3-1.6,7.69,7.69,0,0,0-5.86,0,6.82,6.82,0,0,0-2.27,1.6,7.28,7.28,0,0,0-1.48,2.43,8.49,8.49,0,0,0-.53,3,8.07,8.07,0,0,0,.53,3,7.72,7.72,0,0,0,1.48,2.41,6.73,6.73,0,0,0,2.27,1.62A7.34,7.34,0,0,0,183.59,108.32Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M199.85,97.38a9.09,9.09,0,0,1,.79-3.89,7.82,7.82,0,0,1,2.16-2.78A9.6,9.6,0,0,1,206,89a13.26,13.26,0,0,1,4-.58,12,12,0,0,1,5.14,1.07,8.49,8.49,0,0,1,3.61,3.15,8.62,8.62,0,0,1,3.57-3.15,11.56,11.56,0,0,1,5-1.07,13.89,13.89,0,0,1,4,.56,9,9,0,0,1,3.19,1.66,8,8,0,0,1,2.13,2.78,9,9,0,0,1,.79,3.9v15h-5.28V99.14q0-3.19-1.44-4.56a5.41,5.41,0,0,0-3.89-1.37,5.52,5.52,0,0,0-4,1.53q-1.6,1.53-1.6,5v12.65h-5.28V99.7q0-3.44-1.6-5a6,6,0,0,0-7.85-.14q-1.49,1.4-1.48,4.59v13.16h-5.29Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M262.91,112.25a14.11,14.11,0,0,1-1.46.21c-.66.08-1.44.16-2.34.23s-1.9.15-3,.21-2.27.1-3.48.1a22.81,22.81,0,0,1-5.9-.63,10.08,10.08,0,0,1-3.62-1.67,5.3,5.3,0,0,1-1.8-2.41,8.13,8.13,0,0,1-.49-2.8,8.82,8.82,0,0,1,.58-3.34,5.45,5.45,0,0,1,1.9-2.4,9.14,9.14,0,0,1,3.45-1.46,23.33,23.33,0,0,1,5.19-.49q1.75,0,3.33.12c1.05.07,1.84.14,2.37.2a5.89,5.89,0,0,0-.54-2.68,4.15,4.15,0,0,0-1.45-1.67,6.1,6.1,0,0,0-2.23-.84,16.77,16.77,0,0,0-2.87-.23,12,12,0,0,0-4.24.61,8.33,8.33,0,0,0-2.11,1,5.84,5.84,0,0,1-.76-.89,2.49,2.49,0,0,1-.49-1.57,2.59,2.59,0,0,1,.35-1.27,3,3,0,0,1,1.23-1.12,7.88,7.88,0,0,1,2.36-.76,20.49,20.49,0,0,1,3.75-.28,21.92,21.92,0,0,1,5,.53,11,11,0,0,1,3.87,1.69A8,8,0,0,1,262,93.72a10.67,10.67,0,0,1,.9,4.59ZM257.67,102l-1.73-.28a29.87,29.87,0,0,0-3.83-.18,8.43,8.43,0,0,0-4.58.95,3.31,3.31,0,0,0-1.44,3,3.8,3.8,0,0,0,.3,1.53,2.66,2.66,0,0,0,1,1.18,5.69,5.69,0,0,0,2,.75,16.6,16.6,0,0,0,3.17.25A27.55,27.55,0,0,0,256,109c.83-.11,1.39-.19,1.66-.26Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M267.54,85a2.25,2.25,0,0,1,.74-1.88,3.23,3.23,0,0,1,2-.58,5.48,5.48,0,0,1,1.55.21c.48.14.8.24.95.3v6.07h6.72a3,3,0,0,1,2.16.63,2.48,2.48,0,0,1,.62,1.83,4.5,4.5,0,0,1-.23,1.45,10.14,10.14,0,0,1-.37,1h-8.9v10.34a4.94,4.94,0,0,0,.33,2,3,3,0,0,0,.9,1.23,3.42,3.42,0,0,0,1.34.65,6.49,6.49,0,0,0,1.6.19,7.25,7.25,0,0,0,2.5-.42,6.9,6.9,0,0,0,1.67-.79l2.13,3.52a12.08,12.08,0,0,1-1,.7,9.68,9.68,0,0,1-1.64.79,15.79,15.79,0,0,1-2.18.62,12.7,12.7,0,0,1-2.64.26c-2.72,0-4.77-.71-6.16-2.11a7.54,7.54,0,0,1-2.09-5.54Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M288.9,78.67a3.16,3.16,0,1,1-2.27.92A3.08,3.08,0,0,1,288.9,78.67Zm-2.64,12.88a2.25,2.25,0,0,1,.74-1.88,3.28,3.28,0,0,1,2.08-.58,5.24,5.24,0,0,1,1.53.21q.7.21.93.3v22.75h-5.28Z"
                    transform="translate(-52.62 -73.74)"
                />
                <path
                    d="M294.74,112.35c.15-.22.52-.69,1.11-1.42l2.15-2.64,2.83-3.42c1-1.26,2.09-2.51,3.17-3.78-1.45-1.58-2.69-2.88-3.73-3.92s-1.87-1.9-2.52-2.61a11.51,11.51,0,0,1-1.44-1.86,3.05,3.05,0,0,1-.46-1.57,2,2,0,0,1,.6-1.41,2.67,2.67,0,0,1,2-.63,4.87,4.87,0,0,1,1.49.21,8,8,0,0,1,.83.3c.12.15.41.49.86,1s1,1.12,1.62,1.83,1.3,1.48,2,2.31,1.42,1.64,2.14,2.41c.58-.74,1.18-1.47,1.78-2.18s1.15-1.36,1.64-2,.92-1.11,1.28-1.52.58-.69.67-.82a6.12,6.12,0,0,1,1.48-1.18,3.75,3.75,0,0,1,1.76-.39,4.46,4.46,0,0,1,2.23.53,4,4,0,0,1,1.15.86l-1.22,1.41c-.57.67-1.25,1.45-2,2.37l-2.57,3-2.9,3.43,3.29,3.8,3,3.43,2.37,2.73c.66.77,1.11,1.33,1.36,1.67h-6.53c-.71-.84-1.63-1.91-2.76-3.22s-2.48-2.85-4.05-4.61c-1.39,1.67-2.66,3.2-3.82,4.61s-2,2.48-2.57,3.22Z"
                    transform="translate(-52.62 -73.74)"
                />
            </SvgIcon>
        );
    }
}

export default exporter(Logo).export();
