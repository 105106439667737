import { handleActions } from 'redux-actions';
import types from './types';
import { setAccessToken } from 'ax/auth/service';

const calculateTokenExpiry = expires_in => {
    const dateNow = new Date();
    var expiryDate = new Date(dateNow.getTime() + expires_in * 1000).getTime();

    return expiryDate;
};

const reducer = handleActions(
    {
        [types.logout]: (state, { payload }) => {
            return {
                ...state,
                user: null,
                userToken: null,
                authenticated: false
            };
        },
        [types.login_started]: (state, { payload }) => {
            return { ...state, loading: true };
        },
        [types.login.failed]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                loginNotification: payload ? payload : { message: 'Error' }
            };
        },
        [types.login.success]: (state, { payload }) => {
            let base64Url = payload.access_token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let user = JSON.parse(window.atob(base64));

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem(
                'userToken',
                JSON.stringify(payload.access_token)
            );

            localStorage.setItem('refreshToken', payload.refresh_token);

            localStorage.setItem(
                'tokenExpiry',
                calculateTokenExpiry(payload.expires_in)
            );

            setAccessToken(payload.access_token);

            var result = {
                ...state,
                user: user,
                userToken: payload.access_token,
                loading: false,
                authenticated: true,
                loginNotification: null
            };
            return result;
        },
        [types.register_started]: (state, { payload }) => {
            return { ...state, loading: true };
        },
        [types.register_success]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                loginMessage:
                    'Final step. We have sent a confirmation email for you to complete your Apomatix account.'
            };
        },
        [types.register_failed]: (state, { payload }) => {
            return { ...state, errorResult: payload, loading: false };
        },
        [types.forgot_password_started]: (state, { payload }) => {
            return { ...state, loading: true };
        },
        [types.forgot_password_success]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                loginNotification: {
                    message:
                        'If that address exists in our system we have sent a password reset link.'
                }
            };
        },
        [types.forgot_password_failed]: (state, { payload }) => {
            return { ...state, errorResult: payload, loading: false };
        },
        [types.reset_password_started]: (state, { payload }) => {
            return { ...state, loading: true };
        },
        [types.reset_password_success]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                loginMessage: 'Password successfully changed.'
            };
        },
        [types.reset_password_failed]: (state, { payload }) => {
            return { ...state, errorResult: payload, loading: false };
        },
        [types.confirm_email_started]: (state, { payload }) => {
            return { ...state, loading: true };
        },
        [types.confirm_email_success]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                loginMessage: 'Email address confirmed.'
            };
        },
        [types.confirm_email_failed]: (state, { payload }) => {
            return { ...state, errorResult: payload, loading: false };
        }
    },
    {
        loading: false,
        authenticated: Boolean(localStorage.getItem('userToken')),
        user: JSON.parse(localStorage.getItem('user')),
        userToken: JSON.parse(localStorage.getItem('userToken'))
    }
);

export default reducer;
