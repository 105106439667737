import React from 'react';

import {
    Button,
    Grid,
    Paper,
    MenuItem,
    Tooltip,
    Popper,
    Fade,
    InputLabel,
    FormControl,
    FormControlLabel,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Grow,
    IconButton,
    Chip,
    Fab
} from '@material-ui/core';
//import { TextField } from 'redux-form-material-ui';
import { AxTextField } from 'ax/components/AxTextField';
import AxSelect from 'ax/components/AxSelect/AxSelect';
//import { Select as MaterialUISelect } from 'redux-form-material-ui';
import Select from 'ax/form/Select';
import { validators } from 'ax/form';
import { Field, FieldArray } from 'redux-form';
import { formValueSelector, arrayPush } from 'redux-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { showError } from 'ax/error';
import DeleteIcon from '@material-ui/icons/Delete';

import { getQuestions } from 'prism/frameworks/manage/questions/selectors';

import { getTags } from '../../../selectors';

import { patchQuestionRevision } from '../../../actions';

import types from '../../../types';
import { send } from 'ax/SignalR';

import { getInstancesFiltered } from 'instances/selectors';

// import { newGuid } from 'common'
import { newGuid } from 'ax/utils';
import { exporter } from 'utils/exporter';

import { answerTypes, flowTypes } from 'ax/constants';

const weightValidator = value =>
    value < 0 || value > 1 ? 'Must be between 0 and 1' : undefined;
const acceptableRiskValidator = value =>
    value == undefined || (value >= 0 && value <= 5)
        ? undefined
        : 'Must be between 0 and 5';
const priorityValidator = value =>
    value < 0 ? 'Priority must be a positive number' : undefined;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(1)
    },
    chipContainer: {
        marginLeft: 20
    },
    chipTitle: {
        marginBottom: 20
    },
    logicField: {
        // marginRight: theme.spacing.unit * 2,
        maxWidth: 350,
        minWidth: 100
    },

    inputField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250
    },
    tagSelect: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 350
    },
    fullWidthField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    topSpacer: {
        paddingTop: theme.spacing(3)
    },
    addButton: {
        marginLeft: '10px',
        width: '36px',
        height: '36px'
    },
    deleteButton: {
        margin: theme.spacing(1)
    },
    logicText: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    paddingRight: {
        paddingRight: theme.spacing(1)
    },
    expansionDelete: {
        position: 'absolute',
        right: 25,
        left: 'auto',
        top: 5
    },
    tagPriority: {
        paddingTop: '30px'
    },
    selectPadding: {
        paddingTop: '16px'
    }
});

const stateProps = function(state, props) {
    return {
        initialValues: props.initialValues,
        answerType: formValueSelector(props.form)(state, 'answerType'),
        flowType: formValueSelector(props.form)(state, 'flowType'),
        options: formValueSelector(props.form)(state, 'options'),
        tagSettings: formValueSelector(props.form)(state, 'tagSettings'),
        formTags: formValueSelector(props.form)(state, 'tags'),
        questions: getQuestions(state, props),
        tags: state.prism.tags,
        tagsArray: getTags(state, props),
        instances: getInstancesFiltered(state)
    };
};

const dispatchProps = {
    showError,
    formArrayPush: arrayPush,
    patchQuestionRevision
};

class QuestionItem extends React.PureComponent {
    componentDidMount() {}

    componentDidUpdate(prevProps) {
        //
        if (this.props.tagsArray != prevProps.tagsArray) {
            this.setState({
                tagOptions: this.props.tagsArray
                    //  .filter(x => !values || !values.includes(x.id))
                    .map((item, i) => ({
                        value: item.id,
                        label: item.id
                    }))
            });
        }

        let changedProps = 0;
        Object.entries(this.props).forEach(([key, val]) => {
            if (prevProps[key] !== val) {
                console.log('----------------------------');
                console.log(`Prop '${key}' changed`);
                console.log('Previous:', prevProps[key]);
                console.log('New:', val);
                console.log('----------------------------');
                changedProps++;
            }
        });
        console.log('Total changed props on redraw:' + changedProps);
    }

    constructor(props) {
        super(props);
        this.state = {
            expanders: {},
            tagOptions: undefined
        };
    }

    removeOption = (fields, index) => {
        fields.remove(index);
    };

    getUnusedOptions = values => {
        const { options } = this.props;
        if (!options) return [];
        return options.filter(x => !values.some(z => z.answerId == x.id));
    };

    pushToAutoScore = (fields, values) => {
        const unusedOptions = this.getUnusedOptions(values);

        //Just to double check
        if (unusedOptions.length == 0) return;

        fields.push({ id: newGuid(), answerId: unusedOptions[0].id });
    };

    addToTagSettings = tag => {
        //Check to see if there are already existing settings for that tag

        var exists = this.props.tagSettings.some(x => x.tag == tag);
        if (!exists) {
            this.props.formArrayPush(this.props.form, 'tagSettings', {
                tag: tag,
                logic: [],
                autoScoring: []
            });
        }
    };

    removeTag = (fields, i) => {
        //Check to see if there are already existing settings for that tag

        var allTags = fields.getAll();

        if (allTags.length <= 1) {
            this.props.showError(
                'This is the last tag on this question, please add another before removing it.'
            );
            return;
        }

        var tag = fields.get(i);
        var exists = this.props.tagSettings.some(x => x.tag == tag);

        if (!exists) {
            fields.remove(i);
        } else {
            this.props.showError(
                'This tag has tag settings, please remove the settings before removing the tag.'
            );
        }
    };

    optionIsInUse = (fields, index) => {
        const option = fields.get(index);
        return (
            this.props.tagSettings &&
            this.props.tagSettings.some(x =>
                x.autoScoring.some(z => z.answerId == option.id)
            )
        );
    };

    renderOptions = ({ fields }) => {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h6">
                    Answer Options
                    <Fab
                        color="primary"
                        aria-label="Add"
                        onClick={() => fields.push({ id: newGuid() })}
                        className={classes.addButton}
                    >
                        <AddIcon />
                    </Fab>
                </Typography>

                {fields.map((item, i) => {
                    const optionInUse = this.optionIsInUse(fields, i);
                    return (
                        <Grow in={true} key={i}>
                            <div>
                                <Field
                                    name={`${item}.text`}
                                    className={classes.inputField}
                                    margin="normal"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label={'Answer option ' + (i + 1)}
                                />
                                <Field
                                    name={`${item}.value`}
                                    className={classes.inputField}
                                    margin="normal"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label={'Answer value ' + (i + 1)}
                                />
                                <Tooltip
                                    title={
                                        optionInUse
                                            ? 'This option is in use'
                                            : ''
                                    }
                                >
                                    <span>
                                        <IconButton
                                            disabled={optionInUse}
                                            color="secondary"
                                            onClick={() =>
                                                this.removeOption(fields, i)
                                            }
                                            className={classes.deleteButton}
                                            aria-label="Delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </div>
                        </Grow>
                    );
                })}
            </div>
        );
    };

    renderLogic = ({ fields }) => {
        const { classes } = this.props;
        const values = fields.getAll();
        return (
            <Grid item xs={6}>
                <Typography variant="h6">
                    Question Logic
                    <Fab
                        color="primary"
                        aria-label="Add"
                        onClick={() => fields.push({ id: newGuid() })}
                        className={classes.addButton}
                    >
                        <AddIcon />
                    </Fab>
                </Typography>

                {fields.map((item, i) => {
                    const optionInUse = this.optionIsInUse(fields, i);
                    const value = fields.get(i);
                    return (
                        <Grow in={true} key={i}>
                            <Grid container className={classes.formControl}>
                                <Grid item xs={12}>
                                    <Field
                                        name={`${item}.description`}
                                        fullWidth
                                        margin="normal"
                                        validate={[validators.required]}
                                        component={AxTextField}
                                        label={'Description'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <Typography className={classes.inline} variant="body2">then</Typography> */}
                                    <FormControl className={classes.logicField}>
                                        <Field
                                            component={AxSelect}
                                            name={`${item}.targetQuestion`}
                                            label="Target Question"
                                            validate={[validators.required]}
                                            displayEmpty
                                            options={
                                                this.props.questions &&
                                                this.props.questions.reduce(
                                                    (result, question, i) => {
                                                        // Hide already selected values here

                                                        result.push({
                                                            label:
                                                                question
                                                                    .revision
                                                                    .text,
                                                            value: question.id
                                                        });
                                                        return result;
                                                    },
                                                    []
                                                )
                                            }
                                        ></Field>
                                    </FormControl>

                                    <Typography
                                        className={classes.logicText}
                                        variant="body2"
                                    >
                                        should
                                    </Typography>
                                    <FormControl className={classes.logicField}>
                                        <InputLabel>Show/Hide</InputLabel>
                                        <Field
                                            component={AxSelect}
                                            name={`${item}.outcome`}
                                            label="Outcome"
                                            validate={[validators.required]}
                                            displayEmpty
                                            isDisabled={!value.targetQuestion}
                                            options={[
                                                {
                                                    value: 0,
                                                    label: 'Show'
                                                },
                                                {
                                                    value: 1,
                                                    label: 'Hide'
                                                }
                                            ]}
                                        ></Field>
                                    </FormControl>

                                    <Typography
                                        className={classes.logicText}
                                        variant="body2"
                                    >
                                        if the answer is
                                    </Typography>

                                    <FormControl className={classes.logicField}>
                                        <InputLabel>Answer</InputLabel>
                                        <Field
                                            component={AxSelect}
                                            name={`${item}.answerId`}
                                            label="Related answer"
                                            validate={[validators.required]}
                                            displayEmpty
                                            isDisabled={
                                                value.outcome == null ||
                                                value.outcome === ''
                                            }
                                            options={this.props.options.reduce(
                                                (result, option) => {
                                                    if (
                                                        values.some(
                                                            x =>
                                                                x.answerId ==
                                                                    option.id &&
                                                                x.targetQuestion ==
                                                                    value.targetQuestion
                                                        ) &&
                                                        option.id !=
                                                            value.answerId
                                                    ) {
                                                        return result;
                                                    }
                                                    result.push({
                                                        label: option.text,
                                                        value: option.id
                                                    });

                                                    return result;
                                                },
                                                []
                                            )}
                                        ></Field>
                                    </FormControl>
                                    <IconButton
                                        onClick={() => fields.remove(i)}
                                        className={classes.deleteButton}
                                        aria-label="Delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                <hr />
                            </Grid>
                        </Grow>
                    );
                })}
            </Grid>
        );
    };

    renderTreatments = ({ fields }) => {
        const { classes } = this.props;
        return (
            <Grid item xs={12} className={classes.topSpacer}>
                <Typography variant="h6">
                    Treatments
                    <Fab
                        color="primary"
                        aria-label="Add"
                        onClick={() => fields.push({ id: newGuid() })}
                        className={classes.addButton}
                    >
                        <AddIcon />
                    </Fab>
                </Typography>

                {fields.map((item, i) => {
                    return (
                        <Grow in={true} key={i}>
                            <div>
                                <Field
                                    name={`${item}.score`}
                                    className={classes.inputField}
                                    margin="normal"
                                    type="number"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label={'Score'}
                                />
                                <Field
                                    name={`${item}.text`}
                                    className={classes.inputField}
                                    margin="normal"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label={'Treatment'}
                                />
                                <Field
                                    name={`${item}.help`}
                                    className={classes.inputField}
                                    margin="normal"
                                    component={AxTextField}
                                    label={'Help Text'}
                                />
                                <Field
                                    name={`${item}.treatmentWindow`}
                                    className={classes.inputField}
                                    margin="normal"
                                    type="number"
                                    validate={[validators.required]}
                                    inputProps={{ step: 1, min: 0, max: 365 }}
                                    component={AxTextField}
                                    label={'Treatment window (Days)'}
                                />
                                <IconButton
                                    onClick={() => fields.remove(i)}
                                    className={classes.deleteButton}
                                    aria-label="Delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Grow>
                    );
                })}
            </Grid>
        );
    };

    getUnusedTagsForSettings = () => {
        if (!this.props.formTags) return [];
        return this.props.formTags.filter(
            x => !this.props.tagSettings.some(z => z.tag == x)
        );
    };

    renterTagSettings = ({ fields }) => {
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit'
                }
            })
        };

        return (
            <div>
                <hr />
                {fields.map((name, i) => {
                    var item = fields.get(i);
                    return (
                        <Grow in={true} key={i}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        {item.tag}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                onClick={() => fields.remove(i)}
                                            >
                                                Remove
                                                <DeleteIcon
                                                    className={
                                                        classes.rightIcon
                                                    }
                                                />
                                            </Button>

                                            <FormControl
                                                className={classes.tagSelect}
                                            >
                                                <Field
                                                    component={AxSelect}
                                                    name={`${name}.tag`}
                                                    label="Tag"
                                                    placeholder="Switch tag"
                                                    validate={[
                                                        validators.required
                                                    ]}
                                                    displayEmpty
                                                    options={this.getUnusedTagsForSettings().map(
                                                        x => ({
                                                            value: x,
                                                            label: x
                                                        })
                                                    )}
                                                >
                                                    {this.getUnusedTagsForSettings().map(
                                                        option => {
                                                            // Hide already selected values
                                                            //if (values.some(x => x.answerId == option.id) && option.id != value.answerId) return '';
                                                            return (
                                                                <MenuItem
                                                                    key={option}
                                                                    value={
                                                                        option
                                                                    }
                                                                >
                                                                    {option}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <Button onClick={() => fields.remove(i)} className={classes.deleteButton}>Delete <DeleteIcon /></Button> */}
                                            <Field
                                                name={`${name}.weight`}
                                                className={classes.inputField}
                                                type="number"
                                                inputProps={{
                                                    step: 0.05,
                                                    min: 0,
                                                    max: 1
                                                }}
                                                margin="normal"
                                                validate={[
                                                    validators.required,
                                                    weightValidator
                                                ]}
                                                component={AxTextField}
                                                label={'Weight'}
                                            />
                                            <Field
                                                name={`${name}.priority`}
                                                type="number"
                                                margin="normal"
                                                inputProps={{ min: 0 }}
                                                validate={[
                                                    validators.required,
                                                    priorityValidator
                                                ]}
                                                component={AxTextField}
                                                label={'Priority'}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name={`${name}.acceptableRiskLevel`}
                                                className={classes.inputField}
                                                type="number"
                                                margin="normal"
                                                inputProps={{ min: 0, max: 5 }}
                                                validate={[
                                                    acceptableRiskValidator
                                                ]}
                                                component={AxTextField}
                                                label={
                                                    'Max Acceptable Risk Level'
                                                }
                                            />
                                        </Grid>
                                        <FieldArray
                                            name={`${name}.autoScoring`}
                                            component={this.renderAutoScore}
                                        />

                                        <FieldArray
                                            name={`${name}.logic`}
                                            component={this.renderLogic}
                                        />

                                        <FieldArray
                                            name={`${name}.tagPriorities`}
                                            component={this.renderTagPriorities}
                                        />
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grow>
                    );
                })}
            </div>
        );
    };

    getUnusedTagsForPriorities = values => {
        if (!this.props.formTags) return [];

        console.log(this.props.tags);
        return this.props.formTags.filter(
            x =>
                !this.props.tags[x].isFramework &&
                values &&
                !values.some(z => z.tag == x)
        );
    };

    pushToTagPriorities = (fields, tag) => {
        // const unusedOptions = this.getUnusedOptions(values);

        // //Just to double check
        // if (unusedOptions.length == 0) return;

        fields.push({ id: newGuid(), priority: 1, tag: tag });
    };

    renderTagPriorities = ({ fields }) => {
        const { classes } = this.props;

        var values = fields.getAll();
        var tagsToDisplay = this.getUnusedTagsForPriorities(values);

        return (
            <Grid item container xs={6} className={classes.topSpacer}>
                <Grid item xs={12}>
                    <Typography variant="h6">Tag Priorities</Typography>
                </Grid>
                <Grid item xs={12}>
                    {tagsToDisplay.map(x => {
                        return (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    this.pushToTagPriorities(fields, x)
                                }
                            >
                                {x}
                            </Button>
                        );
                    })}
                </Grid>
                {fields.map((name, i) => {
                    var value = fields.get(i);
                    return (
                        <div key={i}>
                            <Grid className={classes.tagPriority} item xs={12}>
                                <Typography>
                                    {value.tag}{' '}
                                    <IconButton
                                        onClick={() => fields.remove(i)}
                                        className={classes.deleteButton}
                                        aria-label="Delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name={`${name}.priority`}
                                    className={this.props.classes.inputField}
                                    margin="normal"
                                    type="number"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label={'Priority'}
                                />
                            </Grid>
                        </div>
                    );
                })}
            </Grid>
        );
    };

    renderAutoScore = ({ fields }) => {
        var values = fields.getAll();
        const { classes, options } = this.props;

        //Move this out to a selector
        const hasUnusedOptions = this.getUnusedOptions(values) == 0;

        return (
            <Grid item xs={6} className={classes.topSpacer}>
                <Typography variant="h6">
                    Auto scoring
                    <Fab
                        disabled={hasUnusedOptions}
                        color="primary"
                        aria-label="Add"
                        onClick={() => this.pushToAutoScore(fields, values)}
                        className={classes.addButton}
                    >
                        <AddIcon />
                    </Fab>
                </Typography>

                {fields.map((name, i) => {
                    var value = fields.get(i);

                    var options = [];
                    if (this.props.options) {
                        options = this.props.options
                            .filter(
                                option =>
                                    !(
                                        values.some(
                                            x => x.answerId == option.id
                                        ) && option.id != value.answerId
                                    )
                            )
                            .map(option => ({
                                label: option.text,
                                value: option.id
                            }));
                    }

                    return (
                        <Grow key={i} in={true}>
                            <Grid container>
                                <Grid item>
                                    <Field
                                        className={`${this.props.classes.inputField} ${this.props.classes.selectPadding}`}
                                        component={AxSelect}
                                        name={`${name}.answerId`}
                                        label="Related answer"
                                        validate={[validators.required]}
                                        displayEmpty
                                        options={options}
                                    ></Field>
                                </Grid>
                                <Grid item>
                                    <Field
                                        name={`${name}.score`}
                                        className={
                                            this.props.classes.inputField
                                        }
                                        margin="normal"
                                        type="number"
                                        validate={[validators.required]}
                                        component={AxTextField}
                                        label={'Score'}
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() => fields.remove(i)}
                                        className={classes.deleteButton}
                                        aria-label="Delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grow>
                    );
                })}
            </Grid>
        );
    };

    renderTagSelection = fields => {
        const { classes } = this.props;

        return (
            <div className={classes.chipContainer}>
                <Typography variant="h6" className={classes.chipTitle}>
                    Tags
                </Typography>

                <FieldArray
                    name="tagSettings"
                    name="tags"
                    component={({ fields }) => {
                        const values = fields.getAll();
                        console.log('RENDER', values);
                        return (
                            <div>
                                <div className={classes.chipTitle}>
                                    <FormControl
                                        className={this.props.classes.tagSelect}
                                    >
                                        <Field
                                            component={AxSelect}
                                            name="addTag"
                                            displayEmpty
                                            isClearable={true}
                                            placeholder="Add a tag"
                                            onChange={(e, tag) => {
                                                if (tag) fields.push(tag);
                                            }}
                                            options={this.props.tagsArray
                                                .filter(
                                                    x =>
                                                        !values ||
                                                        !values.includes(x.id)
                                                )
                                                .map((item, i) => ({
                                                    value: item.id,
                                                    label: item.id
                                                }))}
                                        />
                                    </FormControl>
                                </div>

                                {fields.map((item, i) => {
                                    const tag = fields.get(i);
                                    return (
                                        <Chip
                                            style={{
                                                backgroundColor: this.props
                                                    .tags[tag].colour
                                            }}
                                            onDelete={() =>
                                                this.removeTag(fields, i)
                                            }
                                            key={i}
                                            label={tag}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                />
            </div>
        );
    };

    renderTagSettingsAdd = () => {
        if (!this.props.formTags) return;
        const { classes } = this.props;
        var tagsToDisplay = this.getUnusedTagsForSettings();

        return (
            <div className={classes.topSpacer}>
                {tagsToDisplay.map((item, i) => {
                    return (
                        <Grow in={true} key={i}>
                            <span className={classes.paddingRight}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    aria-label="Add"
                                    onClick={() => this.addToTagSettings(item)}
                                >
                                    {item} <AddIcon />
                                </Button>
                            </span>
                        </Grow>
                    );
                })}
            </div>
        );
    };

    save = values => {
        // Rather annoying but a quick hack to get rid of the property generated by the AddTag dropdown
        // There may be a better way than this.
        delete values['addTag'];
        return this.props
            .patchQuestionRevision(this.props.initialValues, values)
            .then(data => {
                send({ type: types.patch_revision.success, payload: data });
            });
    };

    render() {
        const { classes, initialValues: question, handleSubmit } = this.props;
        const { reset, pristine, dirty, submitting } = this.props;
        return (
            <Grid item xs={12}>
                <Paper square={true} className={classes.paper}>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(this.save)}
                    >
                        <Grid item xs={12}>
                            <Field
                                name="text"
                                multiline
                                fullWidth
                                margin="normal"
                                validate={[validators.required]}
                                className={classes.fullWidthField}
                                component={AxTextField}
                                label="Question"
                            />
                            <Field
                                name="reference"
                                multiline
                                fullWidth
                                margin="normal"
                                validate={[validators.required]}
                                className={classes.fullWidthField}
                                component={AxTextField}
                                label="Reference"
                            />

                            <Field
                                name={`help`}
                                className={classes.fullWidthField}
                                margin="normal"
                                component={AxTextField}
                                label={'Help Text'}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                className={this.props.classes.inputField}
                            >
                                <Field
                                    component={AxSelect}
                                    name="instanceId"
                                    displayEmpty
                                    isClearable={true}
                                    placeholder="Owned by"
                                    options={this.props.instances.map(
                                        (item, i) => ({
                                            value: item.id,
                                            label: item.companyName
                                        })
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            {this.renderTagSelection()}
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                className={this.props.classes.inputField}
                            >
                                <InputLabel htmlFor="name-disabled">
                                    Show/Hide by default
                                </InputLabel>
                                <Field
                                    component={AxSelect}
                                    name={`defaultVisibility`}
                                    label="Show/Hide by default"
                                    validate={[validators.required]}
                                    displayEmpty
                                    options={[
                                        {
                                            value: 0,
                                            label: 'Show'
                                        },
                                        {
                                            value: 1,
                                            label: 'Hide'
                                        }
                                    ]}
                                ></Field>
                                {/* <Field
                                    component={MaterialUISelect}
                                    name="defaultVisibility"
                                    validate={[validators.required]}
                                    displayEmpty
                                >
                                    <MenuItem value={0}>Show</MenuItem>
                                    <MenuItem value={1}>Hide</MenuItem>
                                </Field> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                className={this.props.classes.inputField}
                            >
                                <InputLabel htmlFor="name-disabled">
                                    Answer type
                                </InputLabel>
                                <Field
                                    component={AxSelect}
                                    name={`answerType`}
                                    label="Answer Type"
                                    validate={[validators.required]}
                                    displayEmpty
                                    options={[
                                        {
                                            value: answerTypes.options,
                                            label: 'Options'
                                        },
                                        {
                                            value: answerTypes.freeText,
                                            label: 'Free text'
                                        }
                                    ]}
                                ></Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                className={this.props.classes.inputField}
                            >
                                <InputLabel htmlFor="name-disabled">
                                    Flow Type
                                </InputLabel>
                                <Field
                                    component={AxSelect}
                                    name={`flowType`}
                                    label="Flow Type"
                                    validate={[validators.required]}
                                    displayEmpty
                                    options={[
                                        {
                                            value: flowTypes.remediation.value,
                                            label: 'Remediation'
                                        },
                                        {
                                            value: flowTypes.dataEntry.value,
                                            label: 'Data Entry'
                                        }
                                    ]}
                                ></Field>
                            </FormControl>
                        </Grid>

                        {this.props.answerType === 0 && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.topSpacer}
                                >
                                    <FieldArray
                                        name="options"
                                        component={this.renderOptions}
                                    />
                                </Grid>
                            </>
                        )}

                        {this.props.flowType == 0 && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.topSpacer}
                                >
                                    <FieldArray
                                        name="treatments"
                                        component={this.renderTreatments}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} className={classes.topSpacer}>
                            <Typography variant="h6">Tag Settings</Typography>

                            {this.renderTagSettingsAdd()}

                            <FieldArray
                                name="tagSettings"
                                component={this.renterTagSettings}
                            />
                        </Grid>
                        <Grid item className={classes.topSpacer} xs={12}>
                            <Button
                                type="submit"
                                disabled={pristine || submitting || !dirty}
                                className={classes.formButton}
                                variant="contained"
                                colour="primary"
                            >
                                Save
                            </Button>
                            <Button
                                disabled={pristine || submitting || !dirty}
                                className={classes.formButton}
                                variant="contained"
                                colour="secondary"
                                onClick={this.props.reset}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        );
    }
}

export default exporter(QuestionItem)
    .withForm({
        enableReinitialize: true, // This allows the form to be updated via data changes
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false // There was issues with unmounting and destroying itsself right after data changes, this is a bit of a hack but works for now
    })
    .withState(stateProps, dispatchProps)
    .withStyles(styles, true)
    .export();
