import { handleActions } from 'redux-actions';
import types from './types';
import jsonPatch from 'fast-json-patch';

const reducer = handleActions(
    {
        [types.get_started]: state => {
            return { ...state, loading: true };
        },
        [types.get_all_users.success]: (state, { payload }) => {
            const users = payload.reduce((map, user) => {
                map[user.id] = { ...user };
                return map;
            }, {});

            const result = { ...state, users: users, loading: false };
            return result;
        },
        [types.patch_success]: (state, { payload }) => {
            const result = { ...state, users: { ...state.users } };
            result.users[payload.id] = { ...payload };
            return result;
        },
        [types.patch_user.success]: (state, { payload }) => {
            const result = { ...state, users: { ...state.users } };
            result.users[payload.id] = { ...payload };
            return result;
        },

        // signalR Events
        [types.user_changed]: (state, { payload }) => {
            const current = state.users[payload.id];
            if (current) {
                let patchedUser = jsonPatch.applyPatch(
                    { ...current },
                    payload.patch
                ).newDocument;
                var users = { ...state.users };
                users[payload.id] = { ...patchedUser };
                return { ...state, users };
            }

            return state;
        },

        [types.get_countries_success]: (state, { payload }) => {
            const result = { ...state, countries: payload };
            return result;
        },

        [types.get_user_success]: (state, { payload }) => {
            let users = { ...state.users };
            users[payload.id] = payload;

            const result = { ...state, users: users };
            return result;
        },
        [types.get_user_activity.success]: (state, { payload }) => {
            return { ...state, userActivity: payload };
        },
        [types.get_user_browser_info.success]: (state, { payload }) => {
            return { ...state, browserInfo: payload };
        }
    },
    { loading: false, users: {}, countries: [], stale: true, browserInfo: [] }
);

export default reducer;
