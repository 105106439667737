import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    List,
    Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    controlsRequest,
    getGroupedControls
} from 'prism/frameworks/manage/controls/selectors';
import { Loader } from 'ax/Loader';
import { history } from 'ax/utils';
import ControlListItem from 'prism/frameworks/manage/controls/ControlListItem';
import { getControlsForTag, createControl } from 'prism/actions';
import { exporter } from 'utils/exporter';
import { AxButton } from 'ax/components';
import types from 'prism/types';
import { send } from 'ax/SignalR';
import { Modes } from 'prism/constants';

const stateProps = function (state, props) {
    return {
        controlsRequest: controlsRequest(state)(
            `${props.tagId}_${Modes.Edit.value}`
        ),
        controls: getGroupedControls(state, props)
    };
};

const dispatchProps = {
    getControlsForTag,
    createControl
};

const styles = theme => ({
    topPadding: {
        paddingTop: theme.spacing(2)
    },
    topMargin: {
        marginTop: theme.spacing(2)
    },
    groupedQuestions: {
        margin: theme.spacing(2)
    },
    groups: {
        marginRight: theme.spacing(2)
    }
});

class ControlsList extends React.Component {
    componentDidMount() {
        this.props.getControlsForTag(this.props.tagId, Modes.Edit.value);
    }

    expansionChange = name => (event, expanded) => {
        let update = {};
        update[name] = expanded;
        this.setState(update);
    };

    renderControls = () => {
        const { classes } = this.props;
        if (!this.props.controlsRequest.loading) {
            let result = [];
            for (const name in this.props.controls) {
                if (this.props.controls.hasOwnProperty(name)) {
                    const element = this.props.controls[name];
                    if (element.length) {
                        result.push(
                            <ExpansionPanel
                                className={classes.groups}
                                key={name}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>{name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        {element &&
                                            element.map(control => {
                                                return (
                                                    <ControlListItem
                                                        key={control.id}
                                                        control={control}
                                                        selected={
                                                            this.props
                                                                .selectedControlId ===
                                                            control.id
                                                        }
                                                    />
                                                );
                                            })}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    }
                }
            }
            return result;
        }
    };

    createClick = () => {
        this.props.createControl(this.props.tagId).then(data => {
            send({ type: types.create_control.success, payload: data });
            history.push('./' + data.id);
        });
    };

    render() {
        const { classes, controlsRequest } = this.props;
        if (controlsRequest.loading) {
            return <Loader loading={true} />;
        }

        return (
            <>
                <AxButton
                    onClick={this.createClick}
                    variant="containedMicro"
                    buttonColor="green"
                    margin="normal"
                    className={classes.topMargin}
                >
                    New Control
                    <AddIcon className={classes.rightIcon} />
                </AxButton>
                <List>{this.renderControls()}</List>
            </>
        );
    }
}

export default exporter(ControlsList)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .export();
