import React from 'react';
import { exporter } from 'utils/exporter';
import { Grid, Typography } from '@material-ui/core';
import { getUserBrowserInfo } from '../actions';
import {
    getBrowserInfo,
    getUser,
    getBrowserInfoRequestState
} from '../selectors';
import { getAllUsers } from '../actions';
import { Loader } from 'ax/Loader';
import { AxTable } from 'ax/components';
import { format, parseISO } from 'date-fns';
import { AxButton } from 'ax/components';

const styles = theme => ({});

const stateProps = function (state, props) {
    return {
        browserInfo: getBrowserInfo(state, props),
        getUser: getUser(state, props),
        browserInfoRequestState: getBrowserInfoRequestState(state, props)
    };
};

const dispatchProps = {
    getAllUsers,
    getUserBrowserInfo
};

class UserBrowserInfo extends React.Component {
    componentDidMount() {
        const { match } = this.props;
        this.props.getAllUsers();
        this.props.getUserBrowserInfo(match.params.userId);
    }

    tableOptions = {
        size: 'small',
        headers: [
            {
                name: `Browser`,
                sortable: true,
                orderBy: item => item.browser,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {item.browser}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `Browser version`,
                sortable: true,
                orderBy: item => item.browserVersion,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {item.browserVersion}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `OS`,
                sortable: true,
                orderBy: item => item.os,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">{item.os}</Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `OS version`,
                sortable: true,
                orderBy: item => item.osVersion,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {item.osVersion}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `Pixel ratio`,
                sortable: true,
                orderBy: item => item.pixelRatio,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {item.pixelRatio}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `Screen height`,
                sortable: true,
                orderBy: item => item.screenHeight,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {item.screenHeight}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `Screen width`,
                sortable: true,
                orderBy: item => item.screenWidth,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {item.screenWidth}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: `Date`,
                sortable: true,
                orderBy: item => item.date,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {format(parseISO(item.date), 'Pp')}
                            </Typography>
                        </Grid>
                    );
                }
            }
        ]
    };

    render() {
        const { browserInfo, browserInfoRequestState, classes } = this.props;

        if (
            browserInfoRequestState.loading &&
            !browserInfoRequestState.success
        ) {
            return <Loader loading={true} />;
        }

        return (
            <Grid container>
                <Grid item xs={12} container justify="flex-end">
                    <AxButton
                        variant="containedMicro"
                        onClick={() => window.history.back()}
                    >
                        Go Back
                    </AxButton>
                </Grid>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant="h5">
                        {this.props.getUser.firstName +
                            ' ' +
                            this.props.getUser.lastName}
                    </Typography>
                    <Typography variant="body1">
                        {this.props.getUser.id}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AxTable options={this.tableOptions} items={browserInfo} />
                </Grid>
            </Grid>
        );
    }
}

export default exporter(UserBrowserInfo)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRouter()
    .export();
