import React from 'react';
import { connect } from 'react-redux';

import withRoot from 'layout/withRoot';
import { register } from '../../account/actions'
import RegistrationForm from './RegistrationForm'

const stateProps = function (state) {
    return {
        accountState: state.account
    }
}
const dispatchProps = {
    register: register
}

class RegistrationFormContainer extends React.Component {
    
    register = (values) => {
        this.props.register(values);
    }

    render() {
        const { accountState } = this.props;

        return (
                <RegistrationForm onSubmit={this.register} accountState={accountState}/>
        );
    }
}

export default connect(stateProps, dispatchProps)(withRoot(RegistrationFormContainer));
