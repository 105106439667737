import { generateAction, ActionGenerator } from 'ax/utils';
import types from './types.js';
import instanceService from './service';
import config from '../common/config';

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.overseerUrl,
        sendInstance: true
    }
});

const getInstances = () =>
    generateAction(types.get_instances, instanceService.getInstances());

const deleteInstance = instanceId =>
    generator.DELETE(types.delete_instance, {
        url: 'api/instances/' + instanceId,
        snackbar: {
            error: 'Could not delete instance',
            success: 'Instance deleted'
        },
        id: instanceId
    });

const getInstanceActivity = id =>
    generator.GET(types.get_instance_activity, {
        url: `api/vault/activity/instance/${id}`,
        snackbar: {
            error: 'Could not get Instance activity'
        }
    });

export { getInstances, deleteInstance, getInstanceActivity };
