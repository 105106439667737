import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
    CallSplit,
    ExpandLess,
    ExpandMore,
    Payment,
    AccountBox,
    BugReport
} from '@material-ui/icons';
import NotFound from 'ax/error/NotFound';
import { AppDrawer } from 'ax/layout';
import GenericError from 'ax/error/generic';
import { SignalRWrapper } from 'ax/SignalR';
import { exporter } from 'utils/exporter';
import AvatarMenu from 'ax/layout/AvatarMenu';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Grid,
    Collapse
} from '@material-ui/core';
import { getProfile } from 'profile/selectors';
import Users from '../users';
import Prism from '../prism';
import Instances from 'instances';
import Account from '../account';
import Licences from '../licences';
import { logout } from '../account/actions';
import config from '../common/config';
import { loadUserDetails } from 'profile/actions';
import AvatarMenuItems from 'layout/AvatarMenuItems';
import { getAppData } from 'app/selectors';
import { loadAppData } from 'app/actions';
import TwoFactorWarning from 'login/components/TwoFactorWarning';
import { history } from 'ax/utils/history';

const stateProps = function (state, props) {
    return {
        profile: getProfile(state, props),
        appData: getAppData(state)
    };
};

const dispatchProps = {
    logout,
    loadUserDetails,
    loadAppData
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 0,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        height: '100%'
        //textAlign: 'center',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minWidth: 0, // So the Typography noWrap works
        height: '100vh',
        overflow: 'auto'
    },
    toolbar: theme.mixins.toolbar,
    paperForm: {
        padding: '100px'
    },
    text: {
        color: 'white'
    },
    bottomSection: {
        paddingLeft: 3,
        paddingTop: theme.spacing(2),
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 70,
        backgroundColor: theme.drawer.selected
    },
    nested: {
        paddingLeft: theme.spacing(2)
    },
    listItem: {
        '&:hover': {
            backgroundColor: theme.colors.blue.darker
        }
    },
    selected: {
        backgroundColor: theme.colors.blue.darker,
        '&:focus, &:hover': {
            backgroundColor: theme.colors.blue.darker
        }
    }
});

const menuItems = [
    { to: '/licences', text: 'Licences', icon: Payment },
    { to: '/instances', text: 'Instances', icon: Payment },
    { to: '/account', text: 'Account', icon: AccountBox },
    { to: '/users', text: 'Users', icon: AccountBox },
    {
        to: '/prism',
        text: 'Prism',
        icon: CallSplit,
        nested: [
            {
                to: '/prism/reports/issues',
                text: 'Issue report',
                icon: BugReport
            }
        ]
    }
];

class Layout extends React.Component {
    componentDidMount() {
        let noAppData =
            !this.props.appData ||
            (Object.keys(this.props.appData).length === 0 &&
                this.props.appData.constructor === Object);

        if (noAppData) {
            this.props.loadAppData();
        }

        // Load the user profile
        this.props.loadUserDetails();
    }

    logout = () => {
        this.props.logout();
    };

    createMenuItem = item => {
        let classes = this.props.classes;

        let selected = this.props.location.pathname.includes(item.to);

        let element = (
            <ListItem
                key={item.to}
                button
                to={item.to}
                component={Link}
                className={selected ? classes.selected : ''}
            >
                <ListItemIcon className={classes.text}>
                    <item.icon />
                </ListItemIcon>
                <ListItemText
                    className={classes.text}
                    primary={
                        <Typography variant="body1" className={classes.text}>
                            {item.text}
                        </Typography>
                    }
                />
                {item.nested ? (
                    selected ? (
                        <ExpandLess />
                    ) : (
                        <ExpandMore />
                    )
                ) : undefined}
            </ListItem>
        );

        if (item.nested) {
            let nestedElements = [];

            for (const nestedItem of item.nested) {
                nestedElements.push(this.createMenuItem(nestedItem));
            }

            let wrappedElements = (
                <Collapse
                    key={`${item.to}_collapse`}
                    in={selected}
                    timeout="auto"
                    unmountOnExit
                    className={classes.nested}
                >
                    {nestedElements}
                </Collapse>
            );

            return [element, wrappedElements];
        }

        return element;
    };

    renderTwoFactorWarning = () => {
        return <TwoFactorWarning />;
    };

    renderContentWithMenu = () => {
        const { classes, profile } = this.props;
        return (
            <div className={classes.root}>
                <AppDrawer>
                    <List>{menuItems.map(x => this.createMenuItem(x))}</List>
                    <Grid className={classes.bottomSection}>
                        <AvatarMenu
                            profile={profile}
                            blobUrl={config.profileImageUrl}
                        >
                            <AvatarMenuItems />
                        </AvatarMenu>
                    </Grid>
                </AppDrawer>
                <main className={classes.content}>
                    <SignalRWrapper url={config.overseerUrl} />
                    <Switch>
                        <Route exact path="/account" component={Account} />
                        <Route path="/licences" component={Licences} />
                        <Route path="/users" component={Users} />
                        <Route path="/prism" component={Prism} />
                        {/* <Route path="/prism/:tab?" component={Prism} /> */}
                        <Route path="/instances" component={Instances} />
                        <Route exact path="/" component={Account} />
                        <Route component={NotFound} />
                    </Switch>
                </main>
                <GenericError />
            </div>
        );
    };

    render() {
        const { appData, location } = this.props;

        if (
            appData &&
            appData.twoFactorRedirect &&
            !location.pathname.endsWith('/twofactor/warning')
        ) {
            history.push(`/twofactor/warning`);
        }

        return (
            <Switch>
                <Route
                    path="/twofactor/warning"
                    render={() => this.renderTwoFactorWarning()}
                />
                <Route render={() => this.renderContentWithMenu()} />
            </Switch>
        );
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired
};

export default exporter(Layout)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRoot()
    .withRouter()
    .export();
