import types from './types.js';
import { ActionGenerator } from 'ax/utils';
import config from 'common/config';

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.auth.url,
        sendInstance: false
    }
});

const loadUserDetails = () =>
    generator.GET(types.load_profile, {
        url: 'api/user/',
        snackbar: { error: 'Could not load profile data' }
    });

export { loadUserDetails };
