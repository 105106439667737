import { createSelector } from 'reselect';
import types from 'prism/types';
import { getKeyedRequestState } from 'ax/rest/selectors';

const controlsState = state => state.prism.controls;
const tagState = state => state.prism.tags;
const getTagId = (state, props) => props.tagId;

const controlsRequest = getKeyedRequestState(types.get_tag_controls);

const _findPriority = (controlSetting, tag) => {
    let priority = 1;
    if (controlSetting) {
        priority = controlSetting.priority;
        if (controlSetting.tagPriorities) {
            let tagPriority = controlSetting.tagPriorities.find(
                x => x.tag == tag
            );
            if (tagPriority) {
                priority = tagPriority.priority;
            }
        }
    }
    return priority;
};

const getGroupedControls = createSelector(
    [controlsState, tagState, getTagId],
    (controls, tags, tagId) => {
        if (!controls) return [];

        let result = {};
        result['Other'] = [];

        let tag = tags[tagId];

        // Map the controls into an array and filter based on the tag
        let controlsArray = Object.keys(controls).map(id => controls[id]);
        let filteredControls = controlsArray.filter(x =>
            x.revision.tags.some(z => z == tagId)
        );

        if (!tag.settings) {
            result['Other'] = filteredControls;
            return result;
        }

        let ordering = tag.settings.ordering;
        for (const name of ordering) {
            // Filter down the controls into groups for ordering
            result[name] = controlsArray
                .filter(x => x.revision.tags.some(z => z == name))
                .sort((x, z) => {
                    //Sort based on priority
                    var first = x.revision.tagSettings.find(
                        t => t.tag == tagId
                    );
                    var second = z.revision.tagSettings.find(
                        t => t.tag == tagId
                    );

                    var firstPriority = _findPriority(first, name);
                    var secondPriority = _findPriority(second, name);

                    return firstPriority - secondPriority;
                })
                .reverse();

            //Set the remaining controls to exclude any that were in this group
            filteredControls = filteredControls.filter(
                x => !result[name].includes(x)
            );
        }

        //Assign any controls not within a group to "Other"
        result['Other'] = filteredControls;
        return result;
    }
);

const getControls = createSelector(
    [controlsState, getTagId],
    (controls, tagId) => {
        if (!controls) return [];
        return Object.keys(controls)
            .map(id => controls[id])
            .filter(x => x.revision.tags.some(z => z == tagId));
    }
);

export { controlsRequest, getControls, getGroupedControls };
