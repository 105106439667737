import React from 'react';
import { Route, Switch } from 'react-router';
import FrameworkList from './list';
import CreateFramework from './create';
import { exporter } from 'utils/exporter';
import FrameworkRoutes from './FrameworkRoutes';

class Frameworks extends React.Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/prism/frameworks/:tagId"
                    component={FrameworkRoutes}
                />
                <Route path="/prism/frameworks/create/">
                    <CreateFramework />
                </Route>
                <Route path="/prism/frameworks/">
                    <FrameworkList />
                </Route>
            </Switch>
        );
    }
}

export default exporter(Frameworks).withState().withRouter().export();
