import React from 'react';
import { connect } from 'react-redux';

import withRoot from 'layout/withRoot';
import { forgotPassword } from '../../account/actions'
import ForgotPasswordForm from './ForgotPasswordForm'

const stateProps = function (state) {
    return {
        accountState: state.account
    }
}
const dispatchProps = {
    forgotPassword: forgotPassword
}

class ForgotPasswordFormContainer extends React.Component {
    
    forgotPassword = (values) => {
        this.props.forgotPassword(values);
    }

    render() {
        const { accountState } = this.props;

        return (
                <ForgotPasswordForm onSubmit={this.forgotPassword} accountState={accountState}/>
        );
    }
}

export default connect(stateProps, dispatchProps)(withRoot(ForgotPasswordFormContainer));
