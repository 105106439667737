import React from 'react';
import { exporter } from 'utils/exporter';
import { Grid, Typography, Button } from '@material-ui/core';
import { getUserActivity, getUserById } from '../actions';
import {
    userActivity,
    getUser,
    getUserActivityRequestState
} from '../selectors';
import UserActivitiesSessionTable from '../components/userActivitiesSessionTable.js';
import { sortUserActivities } from './utils';
import { Loader } from 'ax/Loader';
import { AxButton } from 'ax/components';

const styles = theme => ({});

const stateProps = function (state, props) {
    return {
        userActivity: userActivity(state, props),
        user: getUser(state, props),
        userActivityRequestState: getUserActivityRequestState(state, props)
    };
};

const dispatchProps = {
    getUserActivity,
    getUserById
};

class UserActivities extends React.Component {
    componentDidMount() {
        this.props
            .getUserById(this.props)
            .then(() =>
                this.props.getUserActivity(this.props.match.params.userId)
            );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.userId != this.props.match.params.userId) {
            this.props
                .getUserById(this.props)
                .then(() =>
                    this.props.getUserActivity(this.props.match.params.userId)
                );
        }
    }

    render() {
        const {
            classes,
            userActivityRequestState,
            user,
            userActivity
        } = this.props;

        if (
            userActivityRequestState.loading &&
            !userActivityRequestState.success
        ) {
            return (
                <Grid container>
                    <Loader loading={true} />
                </Grid>
            );
        }

        if (userActivity) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} container justify="flex-end">
                        <AxButton
                            variant="containedMicro"
                            onClick={() => window.history.back()}
                        >
                            Go Back
                        </AxButton>
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h5">
                            {user.firstName + ' ' + user.lastName}
                        </Typography>
                        <Typography variant="body1">{user.id}</Typography>
                    </Grid>
                    {userActivity.length ? (
                        <UserActivitiesSessionTable
                            data={sortUserActivities(
                                this.props.userActivity,
                                'date'
                            )}
                        />
                    ) : (
                        <Grid item>
                            <Typography>No Data Available</Typography>
                        </Grid>
                    )}
                </Grid>
            );
        } else {
            return null;
        }
    }
}

export default exporter(UserActivities)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRouter()
    .export();
