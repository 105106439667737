import { createSelector } from 'reselect';

const controlsState = state => state.prism.controls;
const selectedControlId = (state, props) => props.match.params.controlId;

const getControl = createSelector(
    [selectedControlId, controlsState],
    (controlId, controls) => {
        if (!controls) {
            return {};
        }

        return controls[controlId] || {};
    }
);

export { getControl };
