import React from 'react';
import { exporter } from 'utils/exporter';
import { Grid, Typography, Button } from '@material-ui/core';
import config from 'common/config';
import { Link } from 'react-router-dom';

const styles = theme => ({
    container: {
        minHeight: '50vh'
    },
    link: {
        color: theme.colors.blue.dark,
        textDecoration: 'underline',
        cursor: 'pointer'
    }
});

class TwoFactorWarning extends React.Component {
    onClick = () => {
        window.location.replace(config.phoenixFrontEndUrl);
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Grid item xs={12} align="center">
                    <Typography variant="h6">
                        You must have Two Factor authentication enabled to
                        access Overseer.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Please click the button below to go to Phoenix, login
                        and enable Two Factor authentication on your profile.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        For the change to take effect please logout and login to
                        Overseer.
                    </Typography>
                    <Typography align="center" variant="body1" gutterBottom>
                        <Link to={'/login'} className={classes.link}>
                            Click here to Login to Overseer
                        </Link>
                    </Typography>
                    <Button variant="contained" onClick={this.onClick}>
                        Go to Phoenix
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default exporter(TwoFactorWarning)
    .withState()
    .withStyles(styles)
    .withRouter()
    .export();
