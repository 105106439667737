import React from 'react';
import { Link } from 'react-router-dom';
import { AxTextField } from 'common/AxTextField';
import Bookmark from '@material-ui/icons/Bookmark';
import Description from '@material-ui/icons/Description';

import { exporter } from 'utils/exporter';

import {
    Typography,
    Card,
    CardActionArea,
    CardActions,
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
    CardContent
} from '@material-ui/core';

const styles = theme => ({
    gridContainer: {
        textAlign: 'center'
        //padding: theme.spacing.unit * 2,
    },
    gridContainerPadded: {
        textAlign: 'center',
        padding: theme.spacing(2)
    },
    icon: {
        margin: theme.spacing(2),
        fontSize: 60,
        '&:hover': {
            color: 'grey'
        }
    },
    grid: {
        padding: theme.spacing(2)
    },
    linkStyle: {
        flexGrow: 1,
        textDecoration: 'none',
        color: 'inherit'
    },
    editButton: {
        // position:"absolute",
        // bottom: 0,
        // right:0
    }
});

class TagList extends React.Component {
    render() {
        const { classes, tags, filterState } = this.props;
        return (
            <>
                <Grid>
                    <Button
                        component={Link}
                        to="/prism/tags/create/"
                        variant="contained"
                        color="primary"
                    >
                        Create
                    </Button>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterState.tags}
                                onChange={this.props.filter('tags')}
                                value="tags"
                                color="primary"
                            />
                        }
                        label="Tags"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterState.frameworks}
                                onChange={this.props.filter('frameworks')}
                                value="frameworks"
                            />
                        }
                        label="Frameworks"
                    />
                    <AxTextField
                        id="standard-search"
                        label="Search"
                        type="search"
                        onChange={this.props.search}
                        value={filterState.search}
                        margin="normal"
                    />
                </Grid>

                <Grid container className={classes.gridContainer}>
                    {tags.map(n => {
                        return (
                            <Grid
                                item
                                key={n.id}
                                xs={2}
                                className={classes.grid}
                            >
                                <Card>
                                    <CardActionArea component="div">
                                        <CardContent
                                            component={Link}
                                            to={
                                                n.isFramework
                                                    ? '/prism/frameworks/' +
                                                      n.id +
                                                      '/'
                                                    : '#'
                                            }
                                            className={classes.linkStyle}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {n.id}
                                            </Typography>
                                            <Typography component="p">
                                                {n.isFramework ? (
                                                    <Description
                                                        className={classes.icon}
                                                        style={{
                                                            color: n.colour
                                                        }}
                                                    />
                                                ) : (
                                                    <Bookmark
                                                        className={classes.icon}
                                                        style={{
                                                            color: n.colour
                                                        }}
                                                    />
                                                )}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            component={Link}
                                            to={'prism/tags/' + n.id + '/'}
                                            className={classes.editButton}
                                        >
                                            Edit
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    }
}

export default exporter(TagList)
    .withStyles(styles)
    .export();
