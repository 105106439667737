import React from 'react';
import { exporter } from 'utils/exporter';
import {
    Paper,
    Grid,
    Typography,
    FormControl,
    IconButton,
    FormControlLabel
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { validators, ColorPicker, Switch } from 'ax/form';
import { licences } from 'ax/constants/licences';
import { AxButton, AxDivider, AxTextField } from 'ax/components';
import { newGuid } from 'ax/utils';
import AxSelect from 'common/AxSelect/AxSelect';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import Select from 'react-select';
import { getTags } from 'prism/selectors';
import { getInstancesFiltered } from 'instances/selectors';
import { FrameworkTypes } from 'prism/constants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const stateProps = function (state, props) {
    return {
        isFramework: formValueSelector(props.form)(state, 'isFramework'),
        tags: getTags(state),
        instances: getInstancesFiltered(state),
        frameworkType: formValueSelector(props.form)(state, 'frameworkType')
    };
};

const styles = theme => ({
    container: {
        textAlign: 'left'
    },
    form: {
        padding: theme.spacing(3)
    },
    title: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    controlOption: {
        border: '2px solid',
        borderColor: theme.colors.blue.dark,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    dragContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: 'center'
    }
});
class Tag extends React.Component {
    constructor(props) {
        super(props);
        this.handleColourChange = this.handleColourChange.bind(this);
    }

    checkForSpace = value => {
        if (/\s/.test(value)) {
            return 'Remove empty space in input';
        }
        return undefined;
    };

    handleColourChange(colour) {
        this.props.change('colour', colour);
    }

    addTag = (fields, e) => {
        console.log(e.target);
        console.log('Add');
    };

    handleChange = (fields, value) => {
        let shouldAdd =
            fields.length == 0 ||
            fields.getAll().find(x => x == value.value) == undefined;
        if (shouldAdd) fields.push(value.value);
    };

    onDragEnd = fields => result => {
        // the only one that is required
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        fields.move(result.source.index, result.destination.index);
    };

    frameworkTypeOptions = () => {
        return Object.values(FrameworkTypes).map(type => ({
            value: type.value,
            label: type.name
        }));
    };

    // ******* top level control options ******* //
    addControlManagerOption = fields => () => {
        fields.push({
            id: newGuid(),
            title: undefined,
            options: [{ id: newGuid(), text: undefined, value: undefined }]
        });
    };

    removeControlManagerOption = (fields, index) => () => {
        fields.remove(index);
    };
    // *************************************** //

    // ******** specific option ******* //
    addOption = fields => () => {
        fields.push({
            id: newGuid(),
            text: undefined,
            value: undefined
        });
    };

    removeOption = (fields, index) => () => {
        fields.remove(index);
    };
    // *********************** //

    renderOrdering = ({ fields }) => {
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit'
                }
            })
        };

        return (
            <>
                <AxSelect
                    onChange={value => this.handleChange(fields, value)}
                    options={this.props.tags.map(x => ({
                        value: x.id,
                        label: x.id
                    }))}
                    virtualized
                />
                <DragDropContext onDragEnd={this.onDragEnd(fields)}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}>
                                {fields &&
                                    fields.map((name, i) => {
                                        var value = fields.get(i);
                                        return (
                                            <Draggable
                                                key={i}
                                                draggableId={i}
                                                index={i}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={
                                                            classes.dragContainer
                                                        }
                                                    >
                                                        <Paper>
                                                            <Typography>
                                                                {value}
                                                                <IconButton
                                                                    onClick={() =>
                                                                        fields.remove(
                                                                            i
                                                                        )
                                                                    }
                                                                    className={
                                                                        classes.deleteButton
                                                                    }
                                                                    aria-label="Delete"
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Typography>
                                                        </Paper>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        );
    };

    // individual options
    renderOptions = ({ fields }) => {
        return (
            <Grid item container>
                <Grid item xs={12}>
                    <AxButton
                        variant="selected"
                        buttonColor="green"
                        onClick={this.addOption(fields)}
                    >
                        Add A Specific Option
                    </AxButton>
                    {fields &&
                        fields.map((option, index) => {
                            return (
                                <Grid
                                    item
                                    container
                                    key={index}
                                    alignItems="center"
                                    justify="center"
                                    xs={12}
                                    spacing={2}
                                >
                                    <Grid item xs={7}>
                                        <Field
                                            name={`${option}.text`}
                                            margin="normal"
                                            validate={[validators.required]}
                                            component={AxTextField}
                                            label="Text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={`${option}.value`}
                                            type="number"
                                            margin="normal"
                                            validate={[validators.required]}
                                            component={AxTextField}
                                            label="Value"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            onClick={this.removeOption(
                                                fields,
                                                index
                                            )}
                                            aria-label="Delete"
                                            disabled={fields.length < 2}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
        );
    };

    // top level control options
    renderControlOptions = ({ fields }) => {
        const { classes } = this.props;

        return (
            <Grid item container>
                <Grid item xs={12}>
                    <AxDivider>Control Manager Options</AxDivider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" display="inline">
                        Create An Option
                    </Typography>
                    <IconButton
                        edge="end"
                        onClick={this.addControlManagerOption(fields)}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    {fields &&
                        fields.map((controlOption, index) => {
                            return (
                                <Grid
                                    item
                                    container
                                    key={index}
                                    className={classes.controlOption}
                                >
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        justify="center"
                                    >
                                        <Grid item xs={8}>
                                            <Field
                                                name={`${controlOption}.title`}
                                                margin="normal"
                                                validate={[validators.required]}
                                                component={AxTextField}
                                                label="Title"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4} align="right">
                                            <AxButton
                                                variant="selected"
                                                buttonColor="red"
                                                onClick={this.removeControlManagerOption(
                                                    fields,
                                                    index
                                                )}
                                            >
                                                Delete Control Manager Option
                                            </AxButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <FieldArray
                                            name={`${controlOption}.options`}
                                            component={this.renderOptions}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
        );
    };

    // Color picker is done badly, revisit!
    render() {
        const {
            pristine,
            dirty,
            submitting,
            classes,
            handleSubmit,
            mode
        } = this.props;

        return (
            <Grid item xs={12} className={classes.container}>
                <Paper square={true}>
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <AxDivider>Tag Details</AxDivider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        disabled={mode == 'edit'}
                                        name="id"
                                        margin="normal"
                                        validate={[
                                            validators.required,
                                            this.checkForSpace
                                        ]}
                                        component={AxTextField}
                                        label="Tag Name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="name"
                                        margin="normal"
                                        validate={[validators.required]}
                                        component={AxTextField}
                                        label="Tag Full Name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    name="isPublished"
                                                    component={Switch}
                                                />
                                            }
                                            label="Published"
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    name="isFramework"
                                                    component={Switch}
                                                />
                                            }
                                            label="Framework"
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Field
                                                        ref={input =>
                                                            (this.colourValue = input)
                                                        }
                                                        name="colour"
                                                        component="input"
                                                        type="hidden"
                                                    />
                                                    <Field
                                                        name="colour"
                                                        component={ColorPicker}
                                                        onColourChange={
                                                            this
                                                                .handleColourChange
                                                        }
                                                    />
                                                </>
                                            }
                                            label="colour"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        component={AxSelect}
                                        name="instanceId"
                                        isClearable={true}
                                        placeholder="Owned by"
                                        options={this.props.instances.map(
                                            (item, i) => ({
                                                value: item.id,
                                                label: item.companyName
                                            })
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            {this.props.isFramework && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.title}
                                    >
                                        <AxDivider>
                                            Framework settings
                                        </AxDivider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            component={AxSelect}
                                            name="frameworkType"
                                            isClearable={false}
                                            value={0}
                                            placeholder="Framework Type"
                                            options={this.frameworkTypeOptions()}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name="settings.licenceDataRequirement"
                                            margin="normal"
                                            component={AxTextField}
                                            fullWidth
                                            label="Licence Data Requirement"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={AxSelect}
                                            name="settings.minimumLicenceLevel"
                                            isClearable={true}
                                            placeholder="Minimum licence level"
                                            options={Object.values(
                                                licences
                                            ).map((item, i) => ({
                                                value: item.value,
                                                label: item.name
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        name="settings.isCertifiable"
                                                        component={Switch}
                                                    />
                                                }
                                                label="Certifiable"
                                            />
                                        </FormControl>
                                    </Grid>

                                    {this.props.frameworkType ===
                                        FrameworkTypes.ControlManager.value && (
                                        <Grid item xs={12}>
                                            <FieldArray
                                                name="controlOptions"
                                                component={
                                                    this.renderControlOptions
                                                }
                                            />
                                        </Grid>
                                    )}

                                    <AxDivider>Ordering</AxDivider>

                                    <Grid item xs={6}>
                                        <FieldArray
                                            name="settings.ordering"
                                            component={this.renderOrdering}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <AxButton
                                    to="/prism/frameworks/create/"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    margin="normal"
                                    disabled={pristine || submitting || !dirty}
                                    className={classes.formButton}
                                >
                                    {mode == 'edit' ? 'Update' : 'Create'}
                                </AxButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        );
    }
}

export default exporter(Tag)
    .withForm()
    .withState(stateProps, null)
    .withRouter()
    .withStyles(styles, true)
    .export();
