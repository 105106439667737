import { generateTypes } from 'ax/utils';

export default {
    // Get instance roles
    load_profile: generateTypes('USER/PROFILE/GET'),
    patch_profile: generateTypes('USER/PROFILE/PATCH'),
    patch_profile_apply: 'USER/PROFILE/PATCH/APPLY',
    avatar_updated: 'USER/PROFILE/UPDATED',
    send_phone_code: generateTypes('USER/PHONE/SEND'),
    send_validation_code: generateTypes('USER/PHONE/VALIDATE')
};
