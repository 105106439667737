import React from 'react';
import { exporter } from 'utils/exporter';
import { Link } from 'react-router-dom';
import { Paper, IconButton, Grid } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { getUsers, getAllUsersRequestState } from '../selectors';
import EditIcon from '@material-ui/icons/Edit';
import { AxTable } from 'ax/components';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import { Loader } from 'ax/Loader';
import WebAssetOutlinedIcon from '@material-ui/icons/WebAssetOutlined';

const styles = theme => ({
    icon: {
        color: theme.colors.blue.dark,
        marginBottom: theme.spacing(),
        marginTop: theme.spacing()
    }
});

const stateProps = function (state, props) {
    return {
        users: getUsers(state, props),
        allUsersRequestState: getAllUsersRequestState(state, props)
    };
};

class UserList extends React.Component {
    tableOptions = {
        size: 'small',
        additionalStyling: null,
        headers: [
            {
                name: 'Username',
                sortable: true,
                orderBy: item => item.userName,
                template: item => item.userName
            },
            {
                name: 'First Name',
                sortable: true,
                orderBy: item => item.firstName,
                template: item => item.firstName
            },
            {
                name: 'Last Name',
                sortable: true,
                orderBy: item => item.lastName,
                template: item => item.lastName
            },
            {
                name: 'Phone no.',
                template: item => item.phoneNumber
            },
            {
                name: 'Country',
                sortable: true,
                orderBy: item => item.country,
                template: item => item.country
            },
            {
                name: 'Last Login',
                sortable: true,
                orderBy: item => item.lastLoginDate,
                template: item =>
                    item.lastLoginDate
                        ? format(parseISO(item.lastLoginDate), 'Pp')
                        : null
            },
            {
                name: 'Created on',
                sortable: true,
                //some users have a null created date so passing -1 for those entries gives it something to sort on.
                orderBy: item => (item.createdDate ? item.createdDate : '-1'),
                template: item =>
                    item.createdDate
                        ? format(parseISO(item.createdDate), 'Pp')
                        : null
            },
            {
                name: 'Email verified',
                template: item => (item.emailConfirmed ? 'Yes' : 'No')
            },
            {
                name: 'Origin',
                sortable: true,
                orderBy: item => item.origin,
                template: item => item.origin
            },
            {
                name: 'Activities',
                template: item => {
                    return (
                        <IconButton
                            component={Link}
                            to={`/users/${item.id}/activities`}
                            className={this.props.classes.icon}
                        >
                            <InsertChartOutlinedIcon />
                        </IconButton>
                    );
                }
            },
            {
                name: 'Browser',
                template: item => {
                    return (
                        <IconButton
                            component={Link}
                            to={`/users/${item.id}/browser`}
                            className={this.props.classes.icon}
                        >
                            <WebAssetOutlinedIcon />
                        </IconButton>
                    );
                }
            },
            {
                name: 'Edit',
                template: item => {
                    return (
                        <IconButton
                            component={Link}
                            to={'/users/' + item.id}
                            className={this.props.classes.icon}
                        >
                            <EditIcon />
                        </IconButton>
                    );
                }
            }
        ]
    };
    render() {
        const { allUsersRequestState, users } = this.props;
        if (allUsersRequestState.loading && !allUsersRequestState.success) {
            return (
                <Grid container>
                    <Loader loading={true} />
                </Grid>
            );
        }
        return (
            <Grid container>
                <Grid item xs={12}>
                    <AxTable items={users} options={this.tableOptions} />
                </Grid>
            </Grid>
        );
    }
}

export default exporter(UserList)
    .withState(stateProps)
    .withStyles(styles)
    .export();
