import { createSelector } from 'reselect';
import types from './types';
import { requestLookup } from 'ax/rest';
import { sortString } from 'utils/sort';

const getInstanceState = state => state.instances.instances;
const getInstanceActivities = state => state.instances.instanceActivities;
const getLicencesState = state => state.licences.licences;
const instanceId = (state, props) => props.match.params.instanceId;
const searchTerm = (state, props) => props.searchTerm;

const getInstanceRequestState = state =>
    requestLookup(state, types.get_instances);
const deleteInstanceRequestState = state =>
    requestLookup(state, types.delete_instance);
const getInstanceActivityRequestState = state =>
    requestLookup(state, types.get_instance_activity);

const getInstancesFiltered = createSelector([getInstanceState], instances => {
    return Object.keys(instances).map(id => instances[id]);
});

const getInstancesWithLicencesFiltered = createSelector(
    [getInstanceState, getLicencesState, searchTerm],
    (instances, licences, searchTerm) => {
        var licArr = Object.keys(licences).map(id => licences[id]);
        let instancesWithLicence = Object.keys(instances).map(id => {
            // Grab the licence information for the instance so we can use it in the grid.
            var lic = licArr.filter(x => x.instanceId == id);
            var result = instances[id];
            result.licences = lic;
            // Return the result.
            return result;
        });
        if (searchTerm) {
            let search = searchTerm.toLowerCase();
            instancesWithLicence = instancesWithLicence.filter(
                x =>
                    x.url.toLowerCase().includes(search) ||
                    x.companyName.toLowerCase().includes(search) ||
                    x.creatorsEmail.toLowerCase().includes(search)
            );
        }
        return instancesWithLicence;
    }
);

const instanceRequestState = createSelector(
    [getInstanceRequestState],
    instanceRequestState => {
        return instanceRequestState.loading;
    }
);

const deleteInstanceRequest = createSelector(
    [deleteInstanceRequestState],
    deleteInstanceRequestState => {
        return deleteInstanceRequestState;
    }
);

const getInstance = createSelector(
    [instanceId, getInstanceState],
    (id, instances) => {
        if (!id || Object.keys(instances).length === 0) return null;
        return instances[id];
    }
);

export {
    getInstancesFiltered,
    getInstancesWithLicencesFiltered,
    getInstanceActivities,
    instanceRequestState,
    deleteInstanceRequest,
    getInstance,
    getInstanceRequestState,
    getInstanceActivityRequestState
};
