import React from 'react';
import ReactSelect from 'react-select';
import { MenuItem, Paper, Typography, Chip, ListItem } from '@material-ui/core';
import { exporter } from 'utils/exporter';
import { Cancel } from '@material-ui/icons';
import { AxTextField, AxCheckbox, AxOverflow } from 'ax/components';
import PropTypes from 'prop-types';
import './Select.css';
import { Select as VirtualSelect } from 'react-select-virtualized';
import classNames from 'classnames';

const styles = theme => ({
    input: {
        display: 'flex',
        padding: `${theme.spacing(3.5)}, ${theme.spacing(2.75)}`
    },
    largeInput: {
        display: 'flex',
        padding: '14px 11px'
    },
    valueContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
        maxWidth: '95%'
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    singleValue: {
        fontSize: 14,
        flex: 1
    },
    placeholder: {
        fontSize: 14,
        flex: 1
    },
    paper: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    divider: {
        height: 20,
        backgroundColor: theme.colors.gray.dark,
        width: 1
    },
    chipSpan: {
        maxWidth: '87%'
    },
    checkboxLabel: {
        display: 'inline'
    },
    checkbox: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.colors.gray.light
        }
    },
    selectedCheckbox: {
        backgroundColor: theme.colors.gray.dark,
        cursor: 'pointer',
        order: -1,
        '&:hover': {
            backgroundColor: theme.colors.gray.light
        }
    },
    //There is an inbuilt mechanic to override styling but due to how we are setup, using !important is easier even if its a bit naughty
    menuItem: {
        '&:hover': {
            backgroundColor: theme.colors.blue.lightest + ' !important'
        }
    },
    relative: {
        position: 'relative'
    }
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function IndicatorSeparator({ innerProps, ...props }) {
    return (
        <span className={props.selectProps.classes.divider} {...innerProps} />
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const meta = props.selectProps.meta;
    const showError = meta && meta.error && meta.touched;
    return (
        <AxTextField
            fullWidth
            error={showError}
            helperText={showError && meta.error}
            InputProps={{
                inputComponent,
                inputProps: {
                    className:
                        props.selectProps.size === 'large'
                            ? props.selectProps.classes.largeInput
                            : props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps
                }
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    if (props.selectProps.isChecklist) {
        return (
            <ListItem
                {...props.innerProps}
                checked={props.isSelected}
                className={
                    props.isSelected
                        ? props.selectProps.classes.selectedCheckbox
                        : props.selectProps.classes.checkbox
                }
            >
                <AxCheckbox checked={props.isSelected} />
                <Typography
                    component="span"
                    className={props.selectProps.classes.checkboxLabel}
                >
                    {props.label}
                </Typography>
            </ListItem>
        );
    }

    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            className={props.selectProps.classes.menuItem}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                fontSize: 14
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}
function Placeholder(props) {
    return (
        <AxOverflow
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </AxOverflow>
    );
}

function SingleValue(props) {
    return (
        <AxOverflow
            className={props.selectProps.classes.singleValue}
            {...props.innerProps}
        >
            {props.children}
        </AxOverflow>
    );
}

function ValueContainer(props) {
    if (props.selectProps.isChecklist) {
        let input = props.children[props.children.length - 1];
        let displayText = !(input && input.props && input.props.value);

        let numberOfCategories = props.getValue().length;
        return (
            <div className={props.selectProps.classes.valueContainer}>
                {displayText && (
                    <AxOverflow>
                        {numberOfCategories
                            ? `${numberOfCategories} selected`
                            : 'Show All'}
                    </AxOverflow>
                )}
                {input}
            </div>
        );
    }

    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    );
}

function MultiValue(props, other) {
    let content = <AxOverflow>{props.children}</AxOverflow>;
    return (
        <Chip
            style={{ backgroundColor: props.data.color }}
            tabIndex={-1}
            label={content}
            classes={{
                root: props.selectProps.classes.chip,
                label: props.selectProps.classes.chipSpan
            }}
            onDelete={props.removeProps.onClick}
            deleteIcon={<Cancel {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper
            className="react_select"
            id="react_select"
            square
            className={classNames(
                props.selectProps.classes.paper,
                props.selectProps.relativePosition &&
                    props.selectProps.classes.relative
            )}
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    IndicatorSeparator,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};

// these selects are searchable by default
class AxSelect extends React.Component {
    onChange = selectedItem => {
        let props = this.props;
        let { input } = this.props;
        if (props.input) {
            let hasValue;
            if (selectedItem === undefined || selectedItem === null) {
                hasValue = false;
            } else if (
                typeof selectedItem.value === 'number' &&
                selectedItem.value === 0
            ) {
                hasValue = true;
            } else {
                hasValue = selectedItem.value;
            }

            input.onChange(
                selectedItem && hasValue ? selectedItem.value : null
            );
        }
        if (props.onChange) props.onChange(selectedItem);
    };

    onBlur = selectedItem => {
        let props = this.props;
        let { input } = this.props;
        if (props.input && props.input.value) {
            input.onBlur(input.value);
        }

        if (props.onBlur) props.onBlur(selectedItem);
    };

    onFocus = selectedItem => {
        let props = this.props;
        let { input } = this.props;
        if (props.input) {
            input.onFocus(input.value);
        }

        if (props.onFocus) props.onFocus(selectedItem);
    };

    // Currently if there are no matching options it returns null
    // returning null will clear the drop down
    findOption = value => {
        if (this.props.options.length == 0) return null;
        let option = this.props.options.find(o => o.value === value);
        return option ? option : null;
    };

    // default format option label to fix large options overflowing
    formatOptionLabel = ({ value, label }) => (
        <div style={{ whiteSpace: 'nowrap' }}>
            <div>{label}</div>
        </div>
    );

    render() {
        const { classes, options, input, value, isChecklist } = this.props;

        let selectProps = {};

        // if we have an input being passed in it should be a field
        // If this check is not good enough in the future, change it :)

        let propValue = input ? input.value : value;

        let valueIsObject = value instanceof Object;
        let valueIsArray = Array.isArray(value);

        if (input || (!valueIsObject && !valueIsArray)) {
            let selectedOption = this.findOption(propValue);
            //If the select is being used within a field inject some extra props
            selectProps = {
                value: selectedOption
            };
        } else if (valueIsArray) {
            let values = [];
            // Map an array of values to their option
            for (const i in propValue) {
                if (propValue.hasOwnProperty(i)) {
                    const selected = propValue[i];
                    let selectedOption = this.findOption(selected);
                    values.push(selectedOption);
                }
            }
            selectProps = { value: values };
        } else if (valueIsObject) {
            selectProps = { value };
        }

        if (isChecklist) {
            selectProps.styles = {
                menuList: () => ({
                    display: 'flex',
                    flexDirection: 'column'
                })
            };
        }

        //Remove a few things we dont want overriden
        let {
            onChange,
            onBlur,
            onFocus,
            virtualized,
            ...otherProps
        } = this.props;

        if (virtualized) {
            return (
                <VirtualSelect
                    classes={classes}
                    components={components}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    formatOptionLabel={this.formatOptionLabel}
                    {...otherProps}
                    {...selectProps}
                />
            );
        }

        return (
            <ReactSelect
                classes={classes}
                components={components}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                {...otherProps}
                {...selectProps}
            />
        );
    }
}

AxSelect.propTypes = {
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
};

export default exporter(AxSelect).withStyles(styles).export();
