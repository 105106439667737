import { formatDistanceStrict } from 'date-fns';

const sortUserActivities = (userData, property) => {
    if (!userData || !property) return;

    const getSession = (objectArray, property) => {
        let newArray = [];
        let counter = 0;

        objectArray.forEach((obj, index) => {
            //grab the current date and the date of the previous entry from the data array
            //this is a bit hacky as the first comparsion check will always be undefined for data[index -1] so it currently will check against itself to prevent an error
            let date = obj[property];
            let previousDate = objectArray[index - 1]
                ? objectArray[index - 1][property]
                : new Date(obj[property]);

            //compare the two dates / times to determine the unit value difference eg ["7", "days"]
            let units = formatDistanceStrict(
                new Date(date),
                new Date(previousDate)
            ).split(' ');

            //if units values is equal to minutes minute seconds it pushes the entries to the same session counter. Any other unit measurement is longer than an hour so it goes into a new session.
            //I've setup a split on the unti result so it can be fine tuned to a more specific measurement of time bewtween actions and sessions eg 30 mins 15mins etc
            if (
                units[1] === 'minutes' ||
                units[1] === 'minute' ||
                units[1] === 'seconds'
            ) {
                newArray.push({ [counter]: { ...obj } });
            } else {
                counter++;
                newArray.push({ [counter]: { ...obj } });
            }
        });

        return newArray;
    };

    //result of getSession is then reduced to give us our object of sessions grouped by the session counter
    let groupedDates = getSession(userData, property).reduce(
        (acc, obj, index) => {
            let key = Object.keys(obj);

            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj[key]);
            return acc;
        },
        {}
    );

    return groupedDates;
};

export { sortUserActivities };
