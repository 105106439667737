import { restService } from 'ax/rest'
import config from '../common/config';



function getInstances() {
    return restService.GET({
      url: config.overseerUrl + "api/instances",
      showError: "Could not load instanced"
    })
  }

  export default {
    getInstances
  }