import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { AuthRoute } from 'ax/auth';
import Login from './login';
import Layout from './layout';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'ax/error/NotFound';
import TermsAndCondtions from 'ax/common/TermsAndCondtions';
import ConfirmEmail from './login/components/ConfirmEmail';
import Core from 'ax/Core';
import config from 'common/config';

const App = ({ history }) => {
    //setup core.js
    Core.initialize(config);
    Core.createAxiosAuthHeader();
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Login} />
                <Route path="/forgotPassword" component={Login} />
                <Route path="/resetpassword" component={Login} />
                <Route path="/terms" component={TermsAndCondtions} />
                <Route
                    path="/confirmemail/:token/:email"
                    component={ConfirmEmail}
                />
                <Route path="/" component={Layout} />
                <Route component={NotFound} />
            </Switch>
        </ConnectedRouter>
    );
};

App.propTypes = {
    history: PropTypes.object
};

export default App;
