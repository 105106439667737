import React from 'react';
import {
    Grid,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Typography
} from '@material-ui/core';
import { exporter } from 'utils/exporter';
import { getIssues } from '../actions';
import { Loader } from 'ax/Loader';
import { incompleteTags } from 'prism/selectors/tags';

const stateProps = function(state, routeParams) {
    return {
        issues: state.prism.issues,
        tagIssues: incompleteTags(state)
    };
};

const dispatchProps = {
    getIssues
};

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    rel: {
        position: 'relative'
    },
    gridContainer: {
        textAlign: 'center',
        padding: theme.spacing(2)
    },
    grid: {
        padding: theme.spacing(2),
        border: '1px solid black'
    },
    paper: {
        textAlign: 'center'
    }
});

class IssuesReport extends React.Component {
    componentDidMount() {
        this.props.getIssues();
        console.log(this.props.tagIssues);
    }

    render() {
        const { classes, routeParams, loading } = this.props;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Loader loading={!this.props.issues.loaded}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Question</TableCell>
                                    <TableCell align="right">
                                        Frameworks
                                    </TableCell>
                                    <TableCell align="right">
                                        Missing Treatments
                                    </TableCell>
                                    <TableCell align="right">
                                        Missing Autoscores
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.issues.loaded &&
                                    this.props.issues.items.map(issue => (
                                        <TableRow key={issue.questionId}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {issue.questionText}
                                            </TableCell>
                                            <TableCell align="right">
                                                {JSON.stringify(
                                                    issue.frameworks
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {JSON.stringify(
                                                    issue.missingTreatments
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {JSON.stringify(
                                                    issue.missingAutoScores
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Loader>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <br />
                    <br />
                    <br />
                    <br />

                    <Typography>Tag errors</Typography>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tag</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.tagIssues.map(issue => (
                                <TableRow key={issue.id}>
                                    <TableCell component="th" scope="row">
                                        {issue.id}
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        );
    }
}

export default exporter(IssuesReport)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRoot()
    .withRouter()
    .export();
