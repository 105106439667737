import React from 'react';
import { exporter } from 'utils/exporter';
import { MenuItem } from '@material-ui/core';
import { logout } from '../account/actions';

const stateProps = function(state) {
    return {
        user: state.account.user
    };
};

class AvatarMenuItems extends React.Component {
    render() {
        const { user } = this.props;

        //default menu items
        let menu = [
            <MenuItem key="username">{user.name}</MenuItem>,
            <MenuItem key="logout" onClick={logout}>
                Sign out
            </MenuItem>
        ];

        return menu;
    }
}

export default exporter(AvatarMenuItems)
    .withState(stateProps)
    .withRouter()
    .export();
