import React from 'react';
import {
    Button,
    Grid,
    Paper,
    MenuItem,
    FormControl,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Grow,
    IconButton,
    Chip
} from '@material-ui/core';
import { SlateEditor, AxSelect } from 'common';
import { validators } from 'ax/form';
import { Field, FieldArray, formValueSelector, arrayPush } from 'redux-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { showError } from 'ax/error';
import DeleteIcon from '@material-ui/icons/Delete';
import { getTags } from 'prism/selectors';
import { patchControlRevision } from 'prism/actions';
import types from 'prism/types';
import { send } from 'ax/SignalR';
import { getInstancesFiltered } from 'instances/selectors';
import { newGuid } from 'ax/utils';
import { exporter } from 'utils/exporter';
import { AxButton, AxDivider, AxTextField } from 'ax/components';

const weightValidator = value =>
    value < 0 || value > 1 ? 'Must be between 0 and 1' : undefined;
const priorityValidator = value =>
    value < 0 ? 'Priority must be a positive number' : undefined;

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2)
    },
    chipTitle: {
        marginBottom: 20
    },
    inputField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250
    },
    tagSelect: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    topSpacer: {
        paddingTop: theme.spacing(3)
    },
    addButton: {
        marginLeft: theme.spacing(2),
        width: '36px',
        height: '36px'
    },
    deleteButton: {
        margin: theme.spacing(1)
    },

    paddingRight: {
        paddingRight: theme.spacing(1)
    },
    tagPriority: {
        paddingTop: '30px'
    },
    saveButton: {
        marginRight: theme.spacing(2)
    },
    ownedBy: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    editor: {
        padding: theme.spacing(2),
        border: '2px solid',
        borderColor: theme.colors.blue.dark,
        marginBottom: theme.spacing(1)
    }
});

const stateProps = function (state, props) {
    return {
        initialValues: props.initialValues,
        tagSettings: formValueSelector(props.form)(state, 'tagSettings'),
        formTags: formValueSelector(props.form)(state, 'tags'),
        tags: state.prism.tags,
        tagsArray: getTags(state, props),
        instances: getInstancesFiltered(state)
    };
};

const dispatchProps = {
    showError,
    formArrayPush: arrayPush,
    patchControlRevision
};

const Editor = props => <SlateEditor richText={true} {...props} />;

class ControlItem extends React.PureComponent {
    state = {
        expanders: {},
        tagOptions: undefined
    };

    componentDidUpdate(prevProps) {
        if (this.props.tagsArray != prevProps.tagsArray) {
            this.setState({
                tagOptions: this.props.tagsArray
                    //  .filter(x => !values || !values.includes(x.id))
                    .map((item, i) => ({
                        value: item.id,
                        label: item.id
                    }))
            });
        }

        let changedProps = 0;
        Object.entries(this.props).forEach(([key, val]) => {
            if (prevProps[key] !== val) {
                // console.log('----------------------------');
                // console.log(`Prop '${key}' changed`);
                // console.log('Previous:', prevProps[key]);
                // console.log('New:', val);
                // console.log('----------------------------');
                changedProps++;
            }
        });
        //console.log('Total changed props on redraw:' + changedProps);
    }

    addToTagSettings = tag => {
        //Check to see if there are already existing settings for that tag

        var exists = this.props.tagSettings.some(x => x.tag == tag);
        if (!exists) {
            this.props.formArrayPush(this.props.form, 'tagSettings', {
                tag: tag,
                logic: [],
                autoScoring: []
            });
        }
    };

    removeTag = (fields, i) => {
        //Check to see if there are already existing settings for that tag

        var allTags = fields.getAll();

        if (allTags.length <= 1) {
            this.props.showError(
                'This is the last tag on this control, please add another before removing it.'
            );
            return;
        }

        var tag = fields.get(i);
        var exists = this.props.tagSettings.some(x => x.tag == tag);

        if (!exists) {
            fields.remove(i);
        } else {
            this.props.showError(
                'This tag has tag settings, please remove the settings before removing the tag.'
            );
        }
    };

    renderGuidance = ({ fields }) => {
        const { classes } = this.props;
        return (
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Guidance
                        <IconButton
                            aria-label="Add"
                            onClick={() => fields.push({ id: newGuid() })}
                            className={classes.addButton}
                        >
                            <AddIcon />
                        </IconButton>
                    </Typography>
                </Grid>
                {fields.map((item, i) => {
                    return (
                        <Grid item container xs={12} key={i}>
                            <Grid item xs={11} className={classes.editor}>
                                <Field
                                    name={`${item}.text`}
                                    margin="normal"
                                    validate={[validators.required]}
                                    component={Editor}
                                    label={'Guidance'}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={() => fields.remove(i)}
                                    className={classes.deleteButton}
                                    aria-label="Delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    getUnusedTagsForSettings = () => {
        if (!this.props.formTags) return [];
        return this.props.formTags.filter(
            x => !this.props.tagSettings.some(z => z.tag == x)
        );
    };

    renderTagSettings = ({ fields }) => {
        const { classes } = this.props;

        return (
            <>
                {fields.map((name, i) => {
                    var item = fields.get(i);
                    return (
                        <Grow in={true} key={i}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        {item.tag}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                onClick={() => fields.remove(i)}
                                            >
                                                Remove
                                                <DeleteIcon
                                                    className={
                                                        classes.rightIcon
                                                    }
                                                />
                                            </Button>

                                            <FormControl
                                                className={classes.tagSelect}
                                            >
                                                <Field
                                                    component={AxSelect}
                                                    name={`${name}.tag`}
                                                    label="Tag"
                                                    placeholder="Switch tag"
                                                    validate={[
                                                        validators.required
                                                    ]}
                                                    displayEmpty
                                                    options={this.getUnusedTagsForSettings().map(
                                                        x => ({
                                                            value: x,
                                                            label: x
                                                        })
                                                    )}
                                                >
                                                    {this.getUnusedTagsForSettings().map(
                                                        option => {
                                                            // Hide already selected values
                                                            //if (values.some(x => x.answerId == option.id) && option.id != value.answerId) return '';
                                                            return (
                                                                <MenuItem
                                                                    key={option}
                                                                    value={
                                                                        option
                                                                    }
                                                                >
                                                                    {option}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name={`${name}.weight`}
                                                className={classes.inputField}
                                                type="number"
                                                inputProps={{
                                                    step: 0.05,
                                                    min: 0,
                                                    max: 1
                                                }}
                                                margin="normal"
                                                validate={[
                                                    validators.required,
                                                    weightValidator
                                                ]}
                                                component={AxTextField}
                                                label={'Weight'}
                                            />
                                            <Field
                                                name={`${name}.priority`}
                                                type="number"
                                                margin="normal"
                                                inputProps={{ min: 0 }}
                                                validate={[
                                                    validators.required,
                                                    priorityValidator
                                                ]}
                                                component={AxTextField}
                                                label={'Priority'}
                                            />
                                        </Grid>

                                        <FieldArray
                                            name={`${name}.tagPriorities`}
                                            component={this.renderTagPriorities}
                                        />
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grow>
                    );
                })}
            </>
        );
    };

    getUnusedTagsForPriorities = values => {
        if (!this.props.formTags) return [];

        return this.props.formTags.filter(
            x =>
                !this.props.tags[x].isFramework &&
                values &&
                !values.some(z => z.tag == x)
        );
    };

    pushToTagPriorities = (fields, tag) => {
        // const unusedOptions = this.getUnusedOptions(values);

        // //Just to double check
        // if (unusedOptions.length == 0) return;

        fields.push({ id: newGuid(), priority: 1, tag: tag });
    };

    renderTagPriorities = ({ fields }) => {
        const { classes } = this.props;

        var values = fields.getAll();
        var tagsToDisplay = this.getUnusedTagsForPriorities(values);

        return (
            <Grid item container xs={6} className={classes.topSpacer}>
                <Grid item xs={12}>
                    <Typography variant="h6">Tag Priorities</Typography>
                </Grid>
                <Grid item xs={12}>
                    {tagsToDisplay.map(x => {
                        return (
                            <Button
                                key={x}
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    this.pushToTagPriorities(fields, x)
                                }
                            >
                                {x}
                            </Button>
                        );
                    })}
                </Grid>
                {fields.map((name, i) => {
                    var value = fields.get(i);
                    return (
                        <div key={i}>
                            <Grid className={classes.tagPriority} item xs={12}>
                                <Typography>
                                    {value.tag}{' '}
                                    <IconButton
                                        onClick={() => fields.remove(i)}
                                        className={classes.deleteButton}
                                        aria-label="Delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name={`${name}.priority`}
                                    className={this.props.classes.inputField}
                                    margin="normal"
                                    type="number"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label={'Priority'}
                                />
                            </Grid>
                        </div>
                    );
                })}
            </Grid>
        );
    };

    renderTagSelection = fields => {
        const { classes } = this.props;

        return (
            <>
                <Typography variant="h6" className={classes.chipTitle}>
                    Tags
                </Typography>

                <FieldArray
                    name="tagSettings"
                    name="tags"
                    component={({ fields }) => {
                        const values = fields.getAll();
                        return (
                            <div>
                                <div className={classes.chipTitle}>
                                    <Field
                                        component={AxSelect}
                                        name="addTag"
                                        displayEmpty
                                        isClearable={true}
                                        placeholder="Add a tag"
                                        onChange={(e, tag) => {
                                            if (tag) fields.push(tag);
                                        }}
                                        options={this.props.tagsArray
                                            .filter(
                                                x =>
                                                    !values ||
                                                    !values.includes(x.id)
                                            )
                                            .map((item, i) => ({
                                                value: item.id,
                                                label: item.id
                                            }))}
                                        virtualized
                                        fullWidth
                                    />
                                </div>

                                {fields.map((item, i) => {
                                    const tag = fields.get(i);
                                    return (
                                        <Chip
                                            style={{
                                                backgroundColor: this.props
                                                    .tags[tag].colour
                                            }}
                                            onDelete={() =>
                                                this.removeTag(fields, i)
                                            }
                                            key={i}
                                            label={tag}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                />
            </>
        );
    };

    renderTagSettingsAdd = () => {
        if (!this.props.formTags) return;
        const { classes } = this.props;
        var tagsToDisplay = this.getUnusedTagsForSettings();

        return (
            <div className={classes.topSpacer}>
                {tagsToDisplay.map((item, i) => {
                    return (
                        <Grow in={true} key={i}>
                            <span className={classes.paddingRight}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    aria-label="Add"
                                    onClick={() => this.addToTagSettings(item)}
                                >
                                    {item} <AddIcon />
                                </Button>
                            </span>
                        </Grow>
                    );
                })}
            </div>
        );
    };

    save = values => {
        // Rather annoying but a quick hack to get rid of the property generated by the AddTag dropdown
        // There may be a better way than this.
        delete values['addTag'];
        return this.props
            .patchControlRevision(this.props.initialValues, values)
            .then(data => {
                send({
                    type: types.patch_control_revision.success,
                    payload: data
                });
            });
    };

    render() {
        const {
            classes,
            pristine,
            dirty,
            submitting,
            handleSubmit
        } = this.props;

        return (
            <Grid item xs={12}>
                <Paper square={true} className={classes.paper}>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(this.save)}
                    >
                        <Grid item container xs={12}>
                            <Typography variant="h6">Control Text</Typography>
                            <Grid item xs={12} className={classes.editor}>
                                <Field
                                    name="text"
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    validate={[validators.required]}
                                    component={Editor}
                                    label="Control"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="reference"
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label="Reference"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Field
                                className={classes.ownedBy}
                                component={AxSelect}
                                name="instanceId"
                                displayEmpty
                                isClearable={true}
                                placeholder="Owned by"
                                options={this.props.instances.map(
                                    (item, i) => ({
                                        value: item.id,
                                        label: item.companyName
                                    })
                                )}
                                virtualized
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FieldArray
                                name="guidance"
                                component={this.renderGuidance}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.topSpacer}>
                            {this.renderTagSelection()}
                        </Grid>

                        <Grid item xs={12} className={classes.topSpacer}>
                            <AxDivider>Tag Settings</AxDivider>

                            {this.renderTagSettingsAdd()}

                            <FieldArray
                                name="tagSettings"
                                component={this.renderTagSettings}
                            />
                        </Grid>
                        <Grid item className={classes.topSpacer} xs={12}>
                            <AxButton
                                type="submit"
                                disabled={pristine || submitting || !dirty}
                                className={classes.saveButton}
                                variant="containedMicro"
                                colour="green"
                            >
                                Save
                            </AxButton>
                            <AxButton
                                disabled={pristine || submitting || !dirty}
                                variant="containedMicro"
                                colour="red"
                                onClick={this.props.reset}
                            >
                                Reset
                            </AxButton>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        );
    }
}

export default exporter(ControlItem)
    .withForm({
        enableReinitialize: true, // This allows the form to be updated via data changes
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false // There was issues with unmounting and destroying itsself right after data changes, this is a bit of a hack but works for now
    })
    .withState(stateProps, dispatchProps)
    .withStyles(styles, true)
    .export();
