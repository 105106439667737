import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Button, Grid, FormControlLabel, IconButton } from '@material-ui/core';
import { AxTextField } from 'ax/components/AxTextField';
import { Checkbox } from 'ax/form';
import { validators } from 'ax/form';
import { AxDatePickerInput } from 'common/AxDatePickerInput';
import SelectArrayInput from 'ax/form/SelectArrayInput';
import { exporter } from 'utils/exporter';
import { licences } from 'ax/constants';
import { AxButton } from 'ax/components';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    fullWidth: {
        width: '100%'
    },
    container: {
        marginTop: theme.spacing(4)
    },
    buttonContainer: {
        marginBottom: theme.spacing(4)
    },
    iconButton: {
        marginBottom: theme.spacing(),
        marginTop: theme.spacing(),
        color: 'red',
        '&:disabled': {
            color: theme.colors.gray.darker
        }
    }
});

class LicenceItem extends React.Component {
    checkForSpace = value => {
        if (/\s/.test(value)) {
            return 'Remove empty space in input';
        }
        return undefined;
    };

    renderLicenceData = ({ fields, meta: { error } }) => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <AxButton
                        variant="containedMicro"
                        onClick={() => fields.push()}
                        buttonColor="green"
                    >
                        Add Licence Data
                    </AxButton>
                </Grid>
                <Grid container spacing={2}>
                    {fields.map((data, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={6}>
                                <Field
                                    name={`${data}.name`}
                                    validate={[
                                        validators.required,
                                        this.checkForSpace
                                    ]}
                                    component={AxTextField}
                                    label="Name"
                                    className={classes.fullWidth}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    name={`${data}.value`}
                                    validate={[
                                        validators.required,
                                        this.checkForSpace
                                    ]}
                                    component={AxTextField}
                                    label="Value"
                                    className={classes.fullWidth}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={() => fields.remove(index)}
                                    className={this.props.classes.iconButton}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {error && <li className="error">{error}</li>}
            </>
        );
    };

    render() {
        const {
            initialValues,
            reset,
            pristine,
            dirty,
            submitting,
            classes
        } = this.props;

        return (
            <form onSubmit={this.props.handleSubmit}>
                <Grid container className={classes.container}>
                    <Grid item container xs={9} spacing={2}>
                        {initialValues.licenceId && (
                            <Grid item xs={12}>
                                <Field
                                    name="licenceId"
                                    disabled
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label="Licence Id"
                                    className={classes.fullWidth}
                                />
                            </Grid>
                        )}

                        <Grid item xs={6}>
                            <Field
                                name="instanceId"
                                validate={[validators.required]}
                                component={AxTextField}
                                label="Instance Id"
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="instanceIdentifier"
                                validate={[validators.required]}
                                component={AxTextField}
                                label="Instance Identifier"
                                className={classes.fullWidth}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Field
                                name="startDate"
                                component={AxDatePickerInput}
                                label="Start Date"
                                placeholder="Start Date"
                                autoOk
                                validate={[validators.required]}
                                className={classes.fullWidth}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Field
                                name="endDate"
                                component={AxDatePickerInput}
                                label="End Date"
                                placeholder="End Date"
                                autoOk
                                validate={[validators.required]}
                                className={classes.fullWidth}
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={6}
                            alignItems="flex-end"
                            spacing={4}
                        >
                            <Grid item>
                                <Field
                                    name="licenceType"
                                    validate={[validators.required]}
                                    component={SelectArrayInput}
                                    data={Object.keys(licences).map(
                                        (key, index) => ({
                                            value: licences[key].value,
                                            text: licences[key].name
                                        })
                                    )}
                                    label="Licence Type"
                                    className={classes.fullWidth}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="isTrial"
                                            component={Checkbox}
                                        />
                                    }
                                    label="On a Trial"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <FieldArray
                                name={`licenceData`}
                                component={this.renderLicenceData}
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item>
                                <AxButton
                                    type="submit"
                                    disabled={pristine || submitting || !dirty}
                                    variant="containedMicro"
                                    buttonColor="green"
                                >
                                    Save
                                </AxButton>
                            </Grid>
                            <Grid item>
                                <AxButton
                                    onClick={reset}
                                    disabled={pristine || submitting || !dirty}
                                    variant="containedMicro"
                                    buttonColor="red"
                                >
                                    Reset
                                </AxButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default exporter(LicenceItem)
    // keepDirtyOnReinitialize set to false
    // bit of a hack due to how the form values are initialized after submission
    // if we have any further issues this is an easy place to demonstrate the behaviour
    .withForm({ keepDirtyOnReinitialize: false })
    .withState()
    .withStyles(styles)
    .export();
