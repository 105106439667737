import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { Paper } from '@material-ui/core';

import { confirmEmail } from 'account/actions'
import { ValidationDisplay } from 'ax/error'

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    flex: {
        flex: 1,
    },
    loading: {
        textAlign: "center"
    },
    paper: {
        textAlign: "center"
    },
    padding: {
        paddingRight: theme.spacing(1)
    }

});

const stateProps = function (state) {
    return {
        accountState: state.account
    }
}

const dispatchProps = {
    confirmEmail: confirmEmail
}

class ConfirmEmail extends React.Component {

    componentDidMount() {
        const { token, email } = this.props.match.params;
        this.props.confirmEmail({ token, email });
    }

    render() {
        const { classes, accountState } = this.props;
        return (
            <div>
                <div>
                    <Paper className={classes.paper}>
                        <ValidationDisplay error={accountState.errorResult} />
                        Confirming your email address...
                    </Paper>
                </div>
            </div>
        )
    }
}


export default connect(stateProps, dispatchProps)(withStyles(styles)(ConfirmEmail));