import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import NotFound from 'ax/error/NotFound';
import GenericError from 'ax/error/generic';
import withRoot from 'layout/withRoot';

import LoginFormContainer from './components/LoginFormContainer';
import RegistrationFormContainer from './components/RegistrationFormContainer';
import ForgotPasswordFormContainer from './components/ForgotPasswordFormContainer';
import ResetPasswordFormContainer from './components/ResetPasswordFormContainer';
import Logo from '../common/svg/Logo';
import { exporter } from 'utils/exporter';

const stateProps = function (state) {
    return {
        accountState: state.account
    };
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 0,
        position: 'relative',
        display: 'flex',
        height: '100vh'
    },
    height: {
        height: '100vh'
    },
    content: {
        textAlign: 'left',
        alignItems: 'left',
        flexGrow: 1,
        flex: 1,
        minWidth: 0, // So the Typography noWrap works
        overflow: 'auto'
    },
    leftPane: {
        background: 'url("images/Laptop_Branding.png")',
        backgroundSize: '80% auto',
        backgroundPositionY: '100%',
        backgroundRepeat: 'no-repeat'
    },
    copyright: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5)
    },
    iconGrid: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(20)
    },
    axIcon: {
        fill: theme.toolbar.background,
        width: 200,
        height: 'auto',
        cursor: 'pointer'
    },
    copyright: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5)
    }
});

class Index extends React.Component {
    render() {
        const { classes } = this.props;
        let currentYear = new Date().getFullYear();

        return (
            <Grid container className={classes.root}>
                <Grid
                    item
                    container
                    justify="center"
                    xs={12}
                    className={classes.iconGrid}
                >
                    <Logo className={classes.axIcon} />
                </Grid>
                <Grid container item xs={12}>
                    <main className={classes.content}>
                        <Grid
                            container
                            alignItems="flex-start"
                            justify="center"
                        >
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    component={LoginFormContainer}
                                />
                                <Route
                                    exact
                                    path="/register"
                                    component={RegistrationFormContainer}
                                />
                                <Route
                                    exact
                                    path="/forgotpassword"
                                    component={ForgotPasswordFormContainer}
                                />
                                <Route
                                    path="/resetpassword/:token"
                                    render={props => (
                                        <ResetPasswordFormContainer
                                            {...props}
                                        />
                                    )}
                                />
                                <Route component={NotFound} />
                            </Switch>
                        </Grid>

                        <GenericError />
                    </main>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="flex-end"
                    className={classes.copyright}
                >
                    <Typography variant="caption" align="center">
                        Copyright © {currentYear}, Apomatix Inc. All Rights
                        Reserved. Apomatix is a trademark of Apomatix Inc.
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired
};

export default exporter(Index)
    .withState(stateProps, null)
    .withStyles(styles)
    .withRoot()
    .export();

// export default connect(
//     stateProps,
//     null
// )(withRoot(withStyles(styles)(Index)));
