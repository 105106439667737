import { createSelector } from 'reselect';
import { requestLookup } from 'ax/rest';
import types from './types';

const appDataState = state => state.app;
const appDataRequestState = state => requestLookup(state, types.load_app_data);

const getAppData = createSelector([appDataState], appData => {
    return appData;
});

const appDataRequest = createSelector(
    [appDataRequestState],
    appDataRequestState => {
        return appDataRequestState;
    }
);

export { getAppData, appDataRequest };
