import React from 'react';
import { Field } from 'redux-form';
import { Button, FormControlLabel, Grid } from '@material-ui/core';
import { AxTextField } from 'ax/components/AxTextField';
import { Checkbox } from 'ax/form';
import { CountryDropDown } from 'ax/form';
import { validators } from 'ax/form';
import { exporter } from 'utils/exporter';
import config from '../../common/config';
import { resetPassword } from '../actions';
import { AxButton } from 'ax/components';

const dispatchProps = {
    resetPassword
};

const styles = theme => ({
    country: {
        paddingTop: '12px'
    },
    buttonContainer: {
        marginBottom: theme.spacing(4)
    }
});

class UserItem extends React.Component {
    reset = () => {
        const { match } = this.props;
        this.props.resetPassword(match.params.userId);
    };
    render() {
        const {
            initialValues,
            classes,
            reset,
            pristine,
            dirty,
            submitting
        } = this.props;

        return (
            <div>
                <Grid
                    container
                    justify="flex-end"
                    className={classes.buttonContainer}
                >
                    <AxButton
                        variant="containedMicro"
                        onClick={() => window.history.back()}
                    >
                        Go Back
                    </AxButton>
                </Grid>
                <form onSubmit={this.props.handleSubmit}>
                    <Grid container>
                        <Grid item container xs={9} spacing={4}>
                            {initialValues.userId && (
                                <Grid item xs={12}>
                                    <Field
                                        name="userId"
                                        disabled
                                        validate={[validators.required]}
                                        component={AxTextField}
                                        label="User Id"
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Field
                                    name="firstName"
                                    fullWidth
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label="First Name"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="lastName"
                                    fullWidth
                                    validate={[validators.required]}
                                    component={AxTextField}
                                    label="Last Name"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="phoneNumber"
                                    fullWidth
                                    component={AxTextField}
                                    label="Phone Number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="country"
                                    className={classes.country}
                                    fullWidth
                                    component={CountryDropDown}
                                    url={config.overseerUrl}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="email"
                                    fullWidth
                                    component={AxTextField}
                                    disabled
                                    label="Email"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="origin"
                                    fullWidth
                                    component={AxTextField}
                                    disabled
                                    label="Origin"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="emailConfirmed"
                                            component={Checkbox}
                                            disabled
                                        />
                                    }
                                    label="Email confirmed"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="phoneNumberConfirmed"
                                            component={Checkbox}
                                            disabled
                                        />
                                    }
                                    label="Phone confirmed"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="profileImageExists"
                                            component={Checkbox}
                                            disabled
                                        />
                                    }
                                    label="Has profile image"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="twoFactorEnabled"
                                            component={Checkbox}
                                            disabled
                                        />
                                    }
                                    label="Two Factor Enabled"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="twoFactorType"
                                    fullWidth
                                    component={AxTextField}
                                    disabled
                                    label="Two Factor Type"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="isSupportAccount"
                                            component={Checkbox}
                                        />
                                    }
                                    label="Is Support Account"
                                />
                            </Grid>

                            <Grid item container spacing={2}>
                                <Grid item>
                                    <AxButton
                                        type="submit"
                                        disabled={
                                            pristine || submitting || !dirty
                                        }
                                        variant="containedMicro"
                                        buttonColor="green"
                                    >
                                        Save
                                    </AxButton>
                                </Grid>
                                <Grid item>
                                    <AxButton
                                        onClick={reset}
                                        disabled={
                                            pristine || submitting || !dirty
                                        }
                                        variant="containedMicro"
                                    >
                                        Reset Form
                                    </AxButton>
                                </Grid>
                                <Grid item>
                                    <AxButton
                                        buttonColor="red"
                                        variant="containedMicro"
                                        onClick={this.reset}
                                    >
                                        Reset Password
                                    </AxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default exporter(UserItem)
    .withForm()
    .withRouter()
    .withState(null, dispatchProps)
    .withStyles(styles)
    .export();
