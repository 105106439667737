import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { validators } from 'ax/form';
import { ValidationDisplay } from 'ax/error';
import { Loader } from 'ax/Loader';
import { exporter } from 'utils/exporter';
import { Field } from 'redux-form';
import { AxTextField } from '../../common/AxTextField';
import { AxButton } from '../../common/AxButton';
import { Link } from 'react-router-dom';

const styles = theme => ({
    forgotRoot: {
        textAlign: 'left'
    },
    error: {
        color: 'red'
    },
    textColor: {
        color: theme.colors.blue.dark
    },
    passwordPolicy: {
        paddingBottom: theme.spacing(4)
    },
    loginButton: {
        textAlign: 'right'
    },
    buttonRoot: {
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            textDecorationColor: theme.colors.blue.dark
        }
    },
    fullWidth: {
        width: '100%'
    }
});

class ForgotPasswordForm extends React.Component {
    render() {
        const {
            classes,
            accountState,
            pristine,
            submitting,
            dirty
        } = this.props;
        return (
            <Grid
                container
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.forgotRoot}
            >
                <Grid item container xs={12}>
                    <Grid item xs={6} />
                    <Grid item xs={6} className={classes.loginButton}>
                        <Button
                            to={'/login'}
                            component={Link}
                            classes={{ root: classes.buttonRoot }}
                            disableRipple
                        >
                            <Typography
                                variant="h6"
                                className={classes.textColor}
                            >
                                Login
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Forgot Password?
                        </Typography>
                    </Grid>
                </Grid>
                <Loader loading={accountState.loading}>
                    <Grid item xs={12}>
                        <ValidationDisplay error={accountState.errorResult} />
                        <form
                            onSubmit={this.props.handleSubmit}
                            className={classes.fullWidth}
                        >
                            <Grid item xs={12}>
                                <Field
                                    name="email"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    validate={[
                                        validators.required,
                                        validators.email
                                    ]}
                                    component={AxTextField}
                                    label="Email"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.passwordPolicy}
                            >
                                <Typography variant="caption">
                                    See our{' '}
                                    <a
                                        className={classes.textColor}
                                        href="https://help.apomatix.com/article/63-password-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Password Policy
                                    </a>{' '}
                                    for more details.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AxButton
                                    type="submit"
                                    margin="normal"
                                    variant="contained"
                                    fullWidth
                                    disabled={pristine || submitting || !dirty}
                                >
                                    Submit
                                </AxButton>
                            </Grid>
                        </form>
                    </Grid>
                </Loader>
            </Grid>
        );
    }
}

export default exporter(ForgotPasswordForm)
    .withForm()
    .withState()
    .withStyles(styles)
    .export();
