import React from 'react';
import { Grid } from '@material-ui/core';
import QuestionsList from 'prism/frameworks/manage/questions/QuestionsList';
import QuestionItem from './questions/QuestionItem';
import { exporter } from 'utils/exporter';
import { Route } from 'react-router';
import { getTagId, getQuestion, getTag, getControl } from 'prism/selectors';
import { FrameworkTypes } from 'prism/constants';
import ControlsList from './controls/ControlsList';
import ControlItem from './controls/ControlItem';

const stateProps = function (state, props) {
    return {
        tagId: getTagId(state, props),
        selectedQuestion: getQuestion(state, props),
        selectedControl: getControl(state, props),
        tag: getTag(state, props)
    };
};

const dispatchProps = {};

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1)
    },
    gridScroll: {
        //maxHeight: '500px',
        overflowY: 'scroll',
        minHeight: 'min-content' /* needs vendor prefixes */,
        display: 'flex'
    }
});

class ManageFramework extends React.Component {
    render() {
        const { classes, tag } = this.props;

        return (
            <Grid container className={classes.container}>
                {tag.frameworkType === FrameworkTypes.ControlAnalysis.value && (
                    <>
                        <Grid item xs={3}>
                            <QuestionsList
                                tagId={this.props.tagId}
                                selectedQuestionId={
                                    this.props.selectedQuestion.id
                                }
                            />
                        </Grid>
                        <Grid item xs={9}>
                            {this.props.selectedQuestion.id && (
                                <Route
                                    exact
                                    path="/prism/frameworks/:tagId/:questionId"
                                    component={() => (
                                        <QuestionItem
                                            form={
                                                'QuestionItem_' +
                                                this.props.selectedQuestion.id
                                            }
                                            tagId={this.props.tagId}
                                            initialValues={
                                                this.props.selectedQuestion
                                                    .revision
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </>
                )}
                {tag.frameworkType === FrameworkTypes.ControlManager.value && (
                    <>
                        <Grid item xs={3}>
                            <ControlsList
                                tagId={this.props.tagId}
                                selectedControlId={
                                    this.props.selectedControl.id
                                }
                            />
                        </Grid>
                        <Grid item xs={9}>
                            {this.props.selectedControl.id && (
                                <Route
                                    exact
                                    path="/prism/frameworks/:tagId/:controlId"
                                    component={() => (
                                        <ControlItem
                                            form={
                                                'ControlItem_' +
                                                this.props.selectedControl.id
                                            }
                                            tagId={this.props.tagId}
                                            initialValues={
                                                this.props.selectedControl
                                                    .revision
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

export default exporter(ManageFramework)
    .withStyles(styles)
    .withState(stateProps, dispatchProps)
    .withRouter()
    .export();
