import { handleActions } from 'redux-actions';
import types from './types';

const reducer = handleActions(
    {
        [types.get_instances.success]: (state, { payload }) => {
            const instances = payload.reduce((map, instance) => {
                map[instance.id] = { ...instance };
                return map;
            }, {});

            const result = { ...state, instances: instances };
            return result;
        },
        [types.delete_instance.deleted]: (state, { payload }) => {
            var stateInstances = { ...state.instances };
            delete stateInstances[payload.id];

            const result = { ...state, instances: stateInstances };
            return result;
        },
        [types.get_instance_activity.success]: (state, { payload }) => {
            if (payload.length) {
                return { ...state, instanceActivities: payload };
            }
            return state;
        }
    },
    { instances: {}, instanceActivities: [] }
);

export default reducer;
