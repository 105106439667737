// if(process.env.NODE_ENV == "local") {
//
// } else if(process.env.NODE_ENV == "development") {
//
// } else if(process.env.NODE_ENV == "release") {

// } else {
//     throw "No matching environments for config";
// }

export default {
    auth: {
        url: process.env.REACT_APP_AUTH_URL
    },
    profileImageUrl: process.env.REACT_APP_PROFILE_IMAGE_URL,
    overseerUrl: process.env.REACT_APP_OVERSEER_URL,
    frontEndUrl: process.env.REACT_APP_FRONTEND_URL,
    phoenixFrontEndUrl: process.env.REACT_APP_PHOENIX_URL,
    insights: {
        key: process.env.REACT_APP_INSIGHTS_KEY
    },
    dbLimit: process.env.REACT_APP_DB_LIMIT
};
