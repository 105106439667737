/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import licenceReducer from 'licences/reducer';
import { reducer as formReducer } from 'redux-form';
import accountReducer from 'account/reducer';
import userReducer from 'users/reducer';
import prismReducer from 'prism/reducer';
import profileReducer from 'profile/reducer';
import locationReducer from 'ax/form/CountryDropDown/reducer';
import layoutReducer from 'ax/layout/reducer';
import genericErrorReducer from 'ax/error/generic/reducer';
import restReducer from 'ax/rest/reducer';
import { reducer as componentState } from 'react-redux-setstate';
import instanceReducer from 'instances/reducer';
import appReducer from 'app/reducer';

export default combineReducers({
    form: formReducer,
    licences: licenceReducer,
    layout: layoutReducer,
    genericError: genericErrorReducer,
    account: accountReducer,
    users: userReducer,
    location: locationReducer,
    prism: prismReducer,
    rest: restReducer,
    components: componentState,
    instances: instanceReducer,
    profile: profileReducer,
    app: appReducer
});
