import { exporter } from 'utils/exporter';
import React from 'react';
import { Grid, TextField, InputAdornment, Typography } from '@material-ui/core';
import {
    getInstanceRequestState,
    getInstancesWithLicencesFiltered
} from './selectors';
import { Loader } from 'ax/Loader';
import InstanceList from './list';
import { Search } from '@material-ui/icons';
import config from 'common/config';

const styles = theme => ({
    searchContainer: {
        marginBottom: theme.spacing(4)
    },
    dangerText: {
        color: 'red'
    }
});

const stateProps = function (state, props) {
    return {
        instanceRequestState: getInstanceRequestState(state),
        instances: getInstancesWithLicencesFiltered(state, props)
    };
};

class ListContainer extends React.Component {
    state = {
        search: null
    };

    searchText = e => {
        this.setState({ search: e.target.value });
    };

    render() {
        const { classes, instances, instanceRequestState } = this.props;
        const { search } = this.state;
        const dbLimit = config.dbLimit;
        const dbFractionUsed =
            instances && instances.length
                ? instances.length / dbLimit
                : undefined;
        let dbCountText = undefined;
        let dbCountTextClass = null;

        if (dbFractionUsed > 0.75) {
            dbCountText = `You have too many instances (${instances.length}), please delete some now! Database limit is ${dbLimit}!`;
            dbCountTextClass = classes.dangerText;
        } else {
            dbCountText = `${instances.length} instances used out of ${dbLimit}`;
        }

        if (instanceRequestState.loading && !instanceRequestState.success) {
            return (
                <Grid container>
                    <Loader loading={true} />
                </Grid>
            );
        }

        return (
            <>
                <Grid container alignItems="flex-end">
                    <Grid item xs={8} className={classes.searchContainer}>
                        <Typography className={dbCountTextClass}>
                            {dbCountText}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.searchContainer}>
                        <TextField
                            onChange={this.searchText}
                            placeholder="Search…"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                style: { height: '36px' }
                            }}
                        />
                    </Grid>
                </Grid>
                <InstanceList searchTerm={search} />
            </>
        );
    }
}

export default exporter(ListContainer)
    .withState(stateProps)
    .withStyles(styles)
    .export();
