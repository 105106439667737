import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { exporter } from 'utils/exporter';
import { Transforms, Editor, Range, createEditor } from 'slate';
import { Typography } from '@material-ui/core';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { jsx } from 'slate-hyperscript';
import { isJson } from 'utils/json';
import isUrl from 'is-url';
import { withHistory } from 'slate-history';
import classNames from 'classnames';
import { convertSlate047to050 } from 'utils/slate';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import Code from '@material-ui/icons/Code';
import LooksOne from '@material-ui/icons/LooksOne';
import LooksTwo from '@material-ui/icons/LooksTwo';
import FormatQuote from '@material-ui/icons/FormatQuote';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';

const styles = theme => ({
    icon: {
        fontSize: '18px',
        verticalAlign: 'text-bottom'
    },
    toolbar: {
        position: 'relative',
        padding: '1px 18px 17px',
        margin: '0 -16px',
        borderBottom: '2px solid #eee',
        marginBottom: '20px'
    },
    wrapper: {
        padding: '32.5px 25px',
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.blue.dark,
        borderRadius: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        fontSize: '16px',
        fontFamily: 'Nunito,Roboto,Helvetica,Arial,sans-serif',
        minHeight: '100%'
    },
    wrapperSmall: {
        padding: '16px 12.5px',
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.blue.dark,
        borderRadius: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        fontSize: '16px',
        fontFamily: 'Nunito,Roboto,Helvetica,Arial,sans-serif',
        minHeight: '100%'
    },
    error: {
        color: theme.colors.red.lighter
    }
});

const iconMappings = {
    bold: FormatBold,
    italic: FormatItalic,
    underline: FormatUnderlined,
    code: Code,
    heading: LooksOne,
    subheading: LooksTwo,
    quote: FormatQuote,
    numberedList: FormatListNumbered,
    bulletedList: FormatListBulleted
};

const SlateEditor = ({
    className,
    classes,
    input,
    richText = false,
    placeholder = '',
    meta,
    onBlur
}) => {
    let initialValue = [
        {
            type: 'paragraph',
            children: [{ text: '' }]
        }
    ];

    const [value, setValue] = useState(initialValue);

    const editor = useMemo(
        () => withLinksAndHtml(withHistory(withReact(createEditor()))),
        []
    );

    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);

    const ifJson = isJson(input.value);

    useEffect(() => {
        let value = ifJson
            ? Array.isArray(JSON.parse(input.value))
                ? JSON.parse(input.value)
                : convertSlate047to050(JSON.parse(input.value))
            : input.value
            ? [
                  {
                      type: 'paragraph',
                      children: [{ text: input.value }]
                  }
              ]
            : [
                  {
                      type: 'paragraph',
                      children: [{ text: '' }]
                  }
              ];
        setValue(value);
    }, []);

    useEffect(() => {
        if (input.value === '') {
            setValue(initialValue);
        }
    }, [input.value]);

    const onChange = newValue => {
        if (value !== newValue) {
            const content = JSON.stringify(newValue);
            input.onChange(content);
        }

        setValue(newValue);
    };

    return (
        <Slate editor={editor} onChange={onChange} value={value}>
            {richText && (
                <Toolbar classes={classes}>
                    <MarkButton format="bold" icon="bold" classes={classes} />
                    <MarkButton
                        format="italic"
                        icon="italic"
                        classes={classes}
                    />
                    <MarkButton
                        format="underline"
                        icon="underline"
                        classes={classes}
                    />
                    <MarkButton format="code" icon="code" classes={classes} />
                    <BlockButton
                        format="heading-one"
                        icon="heading"
                        classes={classes}
                    />
                    <BlockButton
                        format="heading-two"
                        icon="subheading"
                        classes={classes}
                    />
                    <BlockButton
                        format="block-quote"
                        icon="quote"
                        classes={classes}
                    />
                    <BlockButton
                        format="numbered-list"
                        icon="numberedList"
                        classes={classes}
                    />
                    <BlockButton
                        format="bulleted-list"
                        icon="bulletedList"
                        classes={classes}
                    />
                </Toolbar>
            )}
            <Editable
                //This was causing a page to focus on the slate editor rather than the other content.
                // autoFocus
                className={
                    richText ? '' : classNames(classes.wrapper, className)
                }
                placeholder={placeholder}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                onBlur={onBlur}
            />
            {meta && meta.submitFailed && (
                <Typography variant="body2" className={classes.error}>
                    {meta.error}
                </Typography>
            )}
        </Slate>
    );
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const Menu = React.forwardRef(({ className, ...props }, ref) => (
    <div {...props} ref={ref} className={className} />
));

const Toolbar = React.forwardRef(({ className, classes, ...props }, ref) => {
    return <Menu {...props} ref={ref} className={classes.toolbar} />;
});

const BlockButton = ({ classes, format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <Icon className={classes.icon} icon={icon} />
        </Button>
    );
};

const MarkButton = ({ classes, format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon className={classes.icon} icon={icon} />
        </Button>
    );
};

const Button = React.forwardRef(
    ({ className, active, reversed, ...props }, ref) => {
        return (
            <span
                {...props}
                ref={ref}
                style={{
                    cursor: 'pointer',
                    color: reversed
                        ? active
                            ? 'white'
                            : '#aaa'
                        : active
                        ? 'black'
                        : '#ccc',
                    marginLeft: '15px'
                }}
            />
        );
    }
);

const Icon = React.forwardRef(({ className, icon, ...props }, ref) => (
    <span {...props} ref={ref}>
        {React.createElement(iconMappings[icon], {
            className
        })}
    </span>
));

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true
    });

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format
    });

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote
                    {...attributes}
                    style={{
                        borderLeft: '2px solid #ddd',
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: '10px',
                        color: '#aaa',
                        fontStyle: 'italic'
                    }}
                >
                    {children}
                </blockquote>
            );
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>;
        case 'heading-four':
            return <h4 {...attributes}>{children}</h4>;
        case 'heading-five':
            return <h5 {...attributes}>{children}</h5>;
        case 'heading-six':
            return <h6 {...attributes}>{children}</h6>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'link':
            return <span {...attributes}>{children}</span>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = (
            <code style={{ backgroundColor: '#eee', padding: '3px' }}>
                {children}
            </code>
        );
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' })
};

const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true })
};

const deserialize = el => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        return '\n';
    }

    const { nodeName } = el;
    let parent = el;

    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
    ) {
        parent = el.childNodes[0];
    }
    const children = Array.from(parent.childNodes).map(deserialize).flat();

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map(child => jsx('text', attrs, child));
    }

    return children;
};

// Helps create Paste-Linkify behaviour; turning text into clickable links
// And keeps formatted text pasted into editor in the same format
const withLinksAndHtml = editor => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = data => {
        const html = data.getData('text/html');
        const text = data.getData('text/plain');

        if (text && isUrl(text)) {
            wrapLink(editor, text);
            return;
        }

        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html');
            const fragment = deserialize(parsed.body);
            Transforms.insertFragment(editor, fragment);
            return;
        }

        insertData(data);
    };

    return editor;
};

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : []
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, { match: n => n.type === 'link' });
    return !!link;
};

export default exporter(SlateEditor).withStyles(styles).export();
