import { createSelector } from 'reselect';
import { requestLookup } from 'ax/rest';
import types from './types.js';

const getLicenceId = (state, props) => props.match.params.licenceId;
const getLicencesState = state => state.licences.licences;
const getAllLicencesRequestState = state =>
    requestLookup(state, types.load_licences);

const getLicences = createSelector([getLicencesState], licences => {
    return Object.keys(licences).map(id => licences[id]);
});

const getLicence = createSelector(
    [getLicenceId, getLicencesState],
    (licenceId, licences) => {
        var id = licenceId;
        return licences[id] || {}; //.find(x => x.id === id) || {};
    }
);

export { getLicences, getLicence, getAllLicencesRequestState };
