import React from 'react';
import { connect } from 'react-redux';
import { getUser } from '../selectors';

import UserItem from './item';
import { patchUser, getUserById } from '../actions';

const stateProps = function (state, props) {
    return {
        user: getUser(state, props)
    };
};

const dispatchProps = {
    patchUser: patchUser,
    getUserById: getUserById
};

class UserItemContainer extends React.Component {
    componentDidMount() {
        //check if user is empty
        if (!Object.keys(this.props.user).length) {
            this.props.getUserById(this.props);
        }
    }

    saveItem = values => {
        // We only ever update users
        this.props.patchUser(this.props.user, values);
    };

    render() {
        const { user } = this.props;
        return <UserItem onSubmit={this.saveItem} initialValues={user} />;
    }
}

export default connect(stateProps, dispatchProps)(UserItemContainer);
