import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { exporter } from 'utils/exporter';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { isJson } from 'utils/json';
import { convertSlate047to050 } from 'utils/slate';

const styles = theme => ({
    link: {
        color: theme.colors.blue.dark
    }
});

const LinkElement = ({ attributes, children, element }) => {
    const href = element.url ? element.url : element.data.url;
    return (
        <a
            {...attributes}
            href={href}
            rel="noopener noreferrer"
            style={{ color: '#0B82A4' }}
            target="_blank"
        >
            {children}
        </a>
    );
};

const SlateReadOnly = props => {
    const editor = useMemo(() => withReact(createEditor()), []);

    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const ifJson = isJson(props.value);

    const initialValue = ifJson
        ? Array.isArray(JSON.parse(props.value))
            ? JSON.parse(props.value)
            : convertSlate047to050(JSON.parse(props.value))
        : props.value
        ? [
              {
                  type: 'paragraph',
                  children: [{ text: props.value }]
              }
          ]
        : [
              {
                  type: 'paragraph',
                  children: [{ text: '' }]
              }
          ];

    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>;
        }

        if (leaf.code) {
            children = (
                <code style={{ backgroundColor: '#eee', padding: '3px' }}>
                    {children}
                </code>
            );
        }

        if (leaf.italic) {
            children = <em>{children}</em>;
        }

        if (leaf.underline) {
            children = <u>{children}</u>;
        }

        return <span {...attributes}>{children}</span>;
    };

    const renderElement = useCallback(props => {
        switch (props.element.type) {
            case 'link':
                return <LinkElement {...props} />;
            case 'block-quote':
                return (
                    <blockquote
                        {...props}
                        style={{
                            borderLeft: '2px solid #ddd',
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: '10px',
                            color: '#aaa',
                            fontStyle: 'italic'
                        }}
                    />
                );
            case 'bulleted-list':
                return <ul {...props} />;
            case 'heading-one':
                return <h1 {...props} />;
            case 'heading-two':
                return <h2 {...props} />;
            case 'heading-three':
                return <h3 {...props} />;
            case 'heading-four':
                return <h4 {...props} />;
            case 'heading-five':
                return <h5 {...props} />;
            case 'heading-six':
                return <h6 {...props} />;
            case 'list-item':
                return <li {...props} />;
            case 'numbered-list':
                return <ol {...props} />;
            default:
                return <p {...props} style={{ margin: '6px 8px' }} />;
        }
    }, []);

    return (
        <Slate editor={editor} value={initialValue}>
            <Editable
                readOnly={true}
                renderLeaf={renderLeaf}
                renderElement={renderElement}
            />
        </Slate>
    );
};

export default exporter(SlateReadOnly).withStyles(styles).export();
