import React from 'react';
import { reduxForm, Field } from 'redux-form'
import { Link } from "react-router-dom";

import { Button, Grid, Paper, Fade, Typography, FormControlLabel, CircularProgress } from '@material-ui/core';

import { TextField, Checkbox } from 'redux-form-material-ui'

import { validators } from 'ax/form'
import { withStyles } from '@material-ui/core/styles';

import { ValidationDisplay } from 'ax/error'
import { ValidatedCheckbox, CountryDropDown } from 'ax/form'

const styles = theme => ({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing(20),
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        alignItems: 'center',
    },
    error: {
        color: 'red'
    }
});

const matchValidator = validators.match("password", "Passwords must match");

class RegistrationForm extends React.Component {

    render() {
        const { classes, accountState } = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Register
                </Typography>

                <Grid container justify="center">
                    <Grid item xs={6}>
                        <Fade in={!accountState.loading}>
                            <Paper className={classes.paper}>
                                <ValidationDisplay error={accountState.errorResult} />
                                <form onSubmit={this.props.handleSubmit}>
                                    <div>
                                        <Field name="email" margin="normal" validate={[validators.required, validators.email]} component={TextField} label="Email" />
                                    </div>
                                    <div>
                                        <Field name="password" margin="normal" validate={[validators.required]} component={TextField} label="Password" type="password" />
                                    </div>
                                    <div>
                                        <Field name="confirmPassword" margin="normal" validate={[validators.required, matchValidator]} component={TextField} label="Confirm Password" type="password" />
                                    </div>
                                    <div>
                                        <Field name="firstName" margin="normal" validate={[validators.required]} component={TextField} label="First Name" />
                                    </div>
                                    <div>
                                        <Field name="lastName" margin="normal" validate={[validators.required]} component={TextField} label="Last Name" />
                                    </div>
                                    <div>
                                        <Field name="phoneNumber" margin="normal" validate={[validators.required]} component={TextField} label="Phone Number" />
                                    </div>
                                    <CountryDropDown />
                                    <div>
                                        <FormControlLabel control={<Field name="acceptedTerms" component={ValidatedCheckbox} validate={[validators.required]} />} 
                                        label={
                                            <div>
                                                <span>I accept the </span>
                                                <Link to={'/terms'} target="_blank" >terms of use</Link>
                                            </div>} />
                                    </div>
                                    <div>
                                        <Button type="submit" margin="normal">Register</Button>
                                    </div>
                                </form>
                            </Paper>
                        </Fade>

                        <Fade in={accountState.loading}>
                            <CircularProgress className={classes.progress} />
                        </Fade>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

RegistrationForm = reduxForm({
    form: 'registrationForm'
})(RegistrationForm)

export default withStyles(styles)(RegistrationForm);