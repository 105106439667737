import { createSelector } from 'reselect';
import { requestLookup } from 'ax/rest';
import types from './types.js';

const getUserState = state => state.users.users;
const getUserId = (state, props) => props.match.params.userId;
const sortBy = (state, props) => props.sortBy;
const getUserBrowserInfo = state => state.users.browserInfo;
const userActivity = (state, props) =>
    state.users.userActivity && state.users.userActivity.reverse();

const getUserActivityRequestState = state =>
    requestLookup(state, types.get_user_activity);
const getAllUsersRequestState = state =>
    requestLookup(state, types.get_all_users);

const getBrowserInfoRequestState = state =>
    requestLookup(state, types.get_user_browser_info);

const getUsers = createSelector([getUserState, sortBy], (users, sortBy) => {
    let usersArray = Object.keys(users).map(id => users[id]);
    return usersArray;
});

const getUser = createSelector([getUserId, getUserState], (userId, users) => {
    //var id = Number(userId);
    return users[userId] || {}; //.find(x => x.id === id) || {};
});

const getBrowserInfo = createSelector([getUserBrowserInfo], browserInfo => {
    return browserInfo;
});

export {
    getUsers,
    getUser,
    userActivity,
    getBrowserInfo,
    getBrowserInfoRequestState,
    getUserActivityRequestState,
    getAllUsersRequestState
};
