import React from 'react';
import { Route, Switch } from 'react-router';
import ManageFramework from './manage';
import { exporter } from 'utils/exporter';
import { getTag } from 'prism/selectors';
import { FrameworkTypes } from 'prism/constants';

const stateProps = function (state, props) {
    return {
        tag: getTag(state, props)
    };
};

class FrameworkRoutes extends React.Component {
    render() {
        const { tag } = this.props;
        return (
            <Switch>
                <Route
                    exact
                    path="/prism/frameworks/:tagId"
                    component={ManageFramework}
                />
                {tag.frameworkType === FrameworkTypes.ControlAnalysis.value && (
                    <Route
                        path="/prism/frameworks/:tagId/:questionId"
                        component={ManageFramework}
                    />
                )}
                {tag.frameworkType === FrameworkTypes.ControlManager.value && (
                    <Route
                        path="/prism/frameworks/:tagId/:controlId"
                        component={ManageFramework}
                    />
                )}
            </Switch>
        );
    }
}

export default exporter(FrameworkRoutes)
    .withState(stateProps, null)
    .withRouter()
    .export();
