import { handleActions } from 'redux-actions';
import types from './types';

const reducer = handleActions({
  [types.load_app_data.success]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [types.update_app_data]: (state, {payload}) => {
    return { ...state, ...payload };
  }
}, {});

export default reducer;