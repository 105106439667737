import React from 'react';
import { history } from 'ax/utils';
import { exporter } from 'utils/exporter';
import { createTag, tagAdded, patchTag, tagUpdated } from 'prism/actions';
import types from 'prism/types';
import { send } from 'ax/SignalR';
import Tag from './Tag';
import { getTag } from 'prism/selectors';

const stateProps = function (state, props) {
    return {
        tag: getTag(state, props)
    };
};

const dispatchProps = {
    createTag,
    patchTag,
    tagAdded,
    tagUpdated
};

class ManageTag extends React.Component {
    saveItem = tagValues => {
        if (this.props.mode === 'edit') {
            // We have a tag, so its an update
            return this.props.patchTag(this.props.tag, tagValues).then(() => {
                //this.props.tagUpdated(tagValues);
                // send({ type: types.tag_updated, payload: tagValues});
            });
        } else {
            // We dont have a tag yet, create one
            return this.props.createTag(tagValues).then(() => {
                this.props.tagAdded(tagValues);
                send({ type: types.tag_added, payload: tagValues });
                history.push('./' + tagValues.id);
            });
        }
    };

    render() {
        const { tag, mode } = this.props;
        return (
            <Tag
                form="TagForm"
                onSubmit={this.saveItem}
                mode={mode}
                initialValues={tag}
            ></Tag>
        );
    }
}

export default exporter(ManageTag)
    .withForm()
    .withState(stateProps, dispatchProps)
    .withRouter()
    .export();
