import React from 'react';
import { exporter } from 'utils/exporter';
import { getInstanceActivity } from './actions';
import { Grid, Typography, Button } from '@material-ui/core';
import {
    getInstanceActivities,
    getInstance,
    getInstanceActivityRequestState
} from './selectors';
import { AxTable } from 'ax/components';
import { history } from 'ax/utils/history';
import { Loader } from 'ax/Loader';
import { AxButton } from 'ax/components';

const styles = theme => ({
    margin: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing()
    },
    user: {
        color: theme.colors.blue.dark,
        cursor: 'pointer',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    title: {
        marginBottom: theme.spacing(4)
    }
});

const stateProps = function (state, props) {
    return {
        instanceActivities: getInstanceActivities(state),
        instance: getInstance(state, props),
        instanceActivityRequestState: getInstanceActivityRequestState(state)
    };
};

const dispatchProps = {
    getInstanceActivity
};

class InstanceActivities extends React.Component {
    componentDidMount() {
        const { match } = this.props;
        this.props.getInstanceActivity(match.params.instanceId);
    }

    tableOptions = {
        size: 'medium',
        headers: [
            {
                name: 'Action',
                sortable: true,
                orderBy: item => item.action,
                template: item => (
                    <Typography
                        variant="body2"
                        className={this.props.classes.margin}
                    >
                        {item.action}
                    </Typography>
                )
            },
            {
                name: 'User',
                sortable: true,
                orderBy: item => item.userId,
                template: item => (
                    <Typography
                        variant="body2"
                        onClick={() =>
                            history.push(`/users/${item.userId}/activities`)
                        }
                        className={this.props.classes.user}
                    >
                        {item.userId}
                    </Typography>
                )
            },
            {
                name: 'Date',
                sortable: true,
                orderBy: item => item.date,
                template: item => (
                    <Typography
                        variant="body2"
                        className={this.props.classes.margin}
                    >
                        {item.date}
                    </Typography>
                )
            }
        ]
    };

    render() {
        const {
            classes,
            instanceActivities,
            instance,
            instanceActivityRequestState
        } = this.props;

        if (
            instanceActivityRequestState.loading &&
            !instanceActivityRequestState.success
        ) {
            return (
                <Grid container>
                    <Loader loading={true} />
                </Grid>
            );
        }

        return (
            <Grid container>
                <Grid item xs={12} container justify="flex-end">
                    <AxButton
                        variant="containedMicro"
                        onClick={() => window.history.back()}
                    >
                        Go Back
                    </AxButton>
                </Grid>
                {instance && (
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h5">
                            {instance.companyName}
                        </Typography>
                        <Typography variant="body1">{instance.id}</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {instanceActivities.length > 0 && (
                        <AxTable
                            items={instanceActivities}
                            options={this.tableOptions}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default exporter(InstanceActivities)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRouter()
    .export();
