import React from 'react';
import { history } from 'ax/utils';
import { exporter } from 'utils/exporter';
import { ListItem } from '@material-ui/core';
import SlateReadOnly from 'common/SlateEditor/SlateReadOnly';

const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white
            }
        }
    },
    primary: {},
    icon: {}
});

class ControlListItem extends React.Component {
    controlSelected = () => {
        if (this.props.selected) return;
        history.push('./' + this.props.control.id);
    };

    render() {
        const { classes, control, selected } = this.props;
        return (
            <ListItem
                button
                selected={selected}
                className={classes.menuItem}
                onClick={() => this.controlSelected()}
            >
                <SlateReadOnly value={control.revision.text} />
            </ListItem>
        );
    }
}

export default exporter(ControlListItem).withStyles(styles).export();
