import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { history } from 'ax/utils';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick utils
import DateFnsUtils from '@date-io/date-fns';

import rootReducer from 'utils/rootReducer';
import App from './App';

//const history = createBrowserHistory()
const initialState = {};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    connectRouter(history)(rootReducer), // new root reducer with router state
    initialState,
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history) // for dispatching history actions
        ),

        applyMiddleware(thunk)
        //applyMiddleware(signalRMiddleware)
    )
);

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App store={store} history={history} />
            </MuiPickersUtilsProvider>
        </Provider>,
        document.getElementById('root')
    );
};
render();
//registerServiceWorker();
