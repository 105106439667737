import { restService } from 'ax/rest';
import config from '../common/config';
import jsonPatch from 'fast-json-patch';

function getFrameworks() {
    return restService.GET({
        url: config.overseerUrl + 'api/prism/tags/frameworks',
        showError: 'Could not load framework data'
    });
}

function getTags() {
    return restService.GET({
        url: config.overseerUrl + 'api/prism/tags',
        showError: 'Could not load tag data'
    });
}

function createTag(tag) {
    return restService.POST({
        url: config.overseerUrl + 'api/prism/tags',
        showError: 'Could not create tag',
        data: tag
    });
}

function patchTag(original, revision) {
    let patch = jsonPatch.compare(original, revision);
    return restService.PATCH({
        url: config.overseerUrl + 'api/prism/tags/' + original.id,
        showError: 'Could not patch tag',
        data: patch
    });
}

function createQuestion(tag) {
    return restService.POST({
        url: config.overseerUrl + 'api/prism/questions/create/' + tag,
        showError: 'Could not create question'
    });
}

function patchQuestionRevision(original, revision) {
    let patch = jsonPatch.compare(original, revision);

    return restService.PATCH({
        url:
            config.overseerUrl +
            'api/prism/questions/' +
            revision.questionId +
            '/revision/' +
            revision.id,
        showError: 'Could not patch question revision',
        data: patch
    });
}

function getQuestions(tag, mode) {
    return restService.GET({
        url:
            config.overseerUrl + 'api/prism/tags/' + tag + '/questions/' + mode,
        showError: 'Could not load questions',
        data: tag
    });
}

function getIssues() {
    return restService.GET({
        url: config.overseerUrl + 'api/prism/reports/issues',
        showError: 'Could not load issues'
    });
}

export default {
    getFrameworks,
    createTag,
    patchTag,
    getQuestions,
    getTags,
    createQuestion,
    patchQuestionRevision,
    getIssues
};
