import types from './types.js';
import authService from '../services/authService';
import { ActionGenerator } from 'ax/utils';
import { createActions, history } from 'ax/utils';
import config from 'common/config';

const actions = createActions(types);

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.auth.url,
        sendInstance: false
    }
});

const login = details =>
    generator.create({
        action: generator.POST,
        type: types.login,
        options: {
            url: 'connect/token',
            data: (function() {
                const params = new URLSearchParams();
                params.append('grant_type', 'password');
                params.append('resource', 'Overseer');
                params.append('username', details.email);
                params.append('password', details.password);
                params.append('code', details.code ? details.code : '');
                params.append('scope', 'openid offline_access');
                return params;
            })(),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
    });

const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userToken');

    history.push('/login');
    return actions.logout();
};

const register = details => dispatch => {
    dispatch(actions.register_started());

    let request = authService
        .register(details)
        .then(data => {
            dispatch(actions.register_success());
            history.push('/login');
        })
        .catch(error => {
            dispatch(
                actions.register_failed(error.message || 'Error registering')
            );
        });

    return request;
};

const forgotPassword = details => dispatch => {
    dispatch(actions.forgot_password_started());

    let request = authService
        .forgotPassword(details)
        .then(data => {
            dispatch(actions.forgot_password_success());
            history.push('/login');
        })
        .catch(error => {
            dispatch(
                actions.forgot_password_failed(
                    error.message || 'Error sending password rest link'
                )
            );
        });

    return request;
};

const resetPassword = details => dispatch => {
    dispatch(actions.reset_password_started());

    let request = authService
        .resetPassword(details)
        .then(data => {
            dispatch(actions.reset_password_success());
            history.push('/login');
        })
        .catch(error => {
            dispatch(
                actions.reset_password_failed(
                    error.message || 'Error resetting password'
                )
            );
        });

    return request;
};

const confirmEmail = details => dispatch => {
    dispatch(actions.confirm_email_started());

    let request = authService
        .confirmEmail(details)
        .then(data => {
            dispatch(actions.confirm_email_success());
            history.push('/login');
        })
        .catch(error => {
            dispatch(
                actions.confirm_email_failed(
                    error.message || 'Error confirming email'
                )
            );
        });

    return request;
};

const resendTwoFaCode = email =>
    generator.POST(types.resend_two_fa_code, {
        url: 'api/Account/twofactor/resend',
        snackbar: {
            success:
                "You can resend a 2FA code every 5 minutes. If you haven't received one recently we have sent you a new one.",
            error: true
        },
        data: { email },
        shouldThrowFormErrors: false
    });

export {
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    confirmEmail,
    resendTwoFaCode
};
