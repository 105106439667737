import { handleActions, combineActions } from 'redux-actions';
import types from './types';
import jsonPatch from 'fast-json-patch';

const reducer = handleActions(
    {
        // Get all licences
        [types.load_licences.success]: (state, { payload }) => {
            const licences = payload.reduce((map, licence) => {
                map[licence.id] = { ...licence };
                return map;
            }, {});
            const result = {
                ...state,
                licences: licences,
                loading: false
            };
            return result;
        },

        [combineActions(
            types.create_licence.success,
            types.edit_licence.success
        )]: (state, { payload }) => {
            const result = { ...state, licences: { ...state.licences } };
            result.licences[payload.id] = { ...payload };
            return result;
        },

        // signalR Events
        [types.licence_changed]: (state, { payload }) => {
            const current = state.licences[payload.id];
            if (current) {
                let patchedLicence = jsonPatch.applyPatch(
                    { ...current },
                    payload.patch
                ).newDocument;
                var licences = { ...state.licences };
                licences[payload.id] = { ...patchedLicence };
                return { ...state, licences };
            }

            return state;
        }
    },
    { loading: false, licences: {} }
);

export default reducer;
