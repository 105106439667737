const FrameworkTypes = {
    ControlAnalysis: { value: 0, name: 'Control Analysis' },
    ControlManager: { value: 1, name: 'Control Manager' }
};

const Modes = {
    Consume: { value: 0 },
    Edit: { value: 1 }
};

export { FrameworkTypes, Modes };
