import React from 'react';
import { create } from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';
import {
    createMuiTheme,
    ThemeProvider,
    StylesProvider,
    createGenerateClassName
} from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/blueGrey';
import { CssBaseline } from '@material-ui/core';

//Copied directly from phoenix, this should get moved to core
const colors = {
    gray: {
        lighter: '#FAFAFA',
        light: '#F5F8FA',
        normal: '#EDF1F6',
        dark: '#DCE4ED',
        darker: '#A5B7CB'
    },
    green: {
        light: '#0CB754',
        lighter: '#26D16E',
        normal: '#3FAC31',
        dark: '#01773A'
    },
    blue: {
        darker: '#33475B',
        dark: '#0B82A4',
        //not in designs currently contained hover
        light: '#2E94B1', //'rgba(11, 130, 164, 0.85)'
        lighter: '#55C4D7',
        lightest: '#d5eaef'
    },
    branding: {
        blue: '#0B82A4'
    },
    orange: {
        lighter: '#FFE4DE',
        light: '#FF7A59',
        normal: '#EA6A46',
        dark: '#EA6A47'
    },
    purple: {
        light: '#6534AC',
        dark: '#4d4c59'
    },
    red: {
        light: '#C80C0F',
        lighter: '#f44336'
    },
    yellow: {
        normal: '#F0B53B'
    },
    white: '#fff',
    pink: {
        dark: '#f50057'
    }
};

// Most of this is a direct copy from overseer
const theme = createMuiTheme({
    colors: colors,
    palette: {
        //type: 'dark',
        primary: blueGrey,
        secondary: green,
        background: {
            default: '#FFFFFF'
        }
    },
    drawer: {
        background: colors.blue.dark,
        hover: colors.blue.darker,
        selected: '#086C88', // This does not seem to be used elsewhere yet
        icon: {
            color: '#FFFFFF'
        }
    },
    toolbar: {
        background: colors.branding.blue
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Nunito',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '3.25rem',
            fontWeight: '400'
        },
        h2: {
            fontSize: '2.625rem',
            fontWeight: '600'
        },
        h3: {
            fontSize: '2.25rem',
            fontWeight: '600'
        },
        h4: {
            fontSize: '2rem',
            fontWeight: '600'
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: '700'
        },
        h6: {
            fontSize: '1.25rem',
            fontWeight: '600'
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: '700'
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: '500'
        },
        body1: {
            fontSize: '1rem',
            fontWeight: '600'
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: '400'
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: '500'
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: '400'
        },
        button: {
            fontSize: '0.875rem',
            fontWeight: '500'
        }
    },
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: colors.gray.light
                }
            }
        },
        MuiFormHelperText: {
            root: {
                whiteSpace: 'pre'
            }
        }
    }
});

// Create a JSS instance with the default preset of plugins.
// It's optional.
//const jss = create(jssPreset());

// The standard class name generator.
// It's optional.
const generateClassName = createGenerateClassName({
    productionPrefix: 'ax'
});

function withRoot(Component) {
    function WithRoot(props) {
        return (
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <Component {...props} />
                </ThemeProvider>
            </StylesProvider>
        );

        // // JssProvider allows customizing the JSS styling solution.
        // return (
        //     <JssProvider jss={jss} generateClassName={generateClassName}>
        //         {/* MuiThemeProvider makes the theme available down the React tree
        //   thanks to React context. */}
        //         <MuiThemeProvider theme={theme}>
        //             {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        //             <CssBaseline />
        //             <Component {...props} />
        //         </MuiThemeProvider>
        //     </JssProvider>
        // );
    }
    return WithRoot;
}

export { theme };

export default withRoot;
