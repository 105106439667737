import React from 'react';
import { exporter } from 'utils/exporter';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => {
    //Styles that we want to apply to ALL AxButtons
    let baseButton = {
        textTransform: 'none',
        height: '42px',
        fontSize: '14px',
        borderRadius: 2,
        borderWidth: 1,
        boxShadow: 'none',
        '&:active': {
            boxShadow: 'none'
        },
        '&:disabled': {
            color: theme.colors.gray.darker,
            borderColor: theme.colors.gray.dark,
            backgroundColor: theme.colors.gray.dark,
            borderStyle: 'solid',
            borderWidth: '2px',
            '&:hover': {
                backgroundColor: theme.colors.gray.dark
            }
        }
    };

    return {
        //text is the default style
        text: {
            ...baseButton
        },
        outlined: {
            ...baseButton,
            backgroundColor: 'transparent',
            color: theme.colors.blue.darker,
            borderStyle: 'solid',
            borderColor: theme.colors.blue.darker,
            //borderWidth: '1px',
            '&:hover': {
                backgroundColor: 'transparent',
                borderColor: theme.colors.blue.dark,
                //borderWidth: "2px",
                color: theme.colors.blue.dark
            }
        },
        contained: {
            ...baseButton,
            color: 'white',
            backgroundColor: theme.colors.blue.dark,
            borderColor: theme.colors.blue.dark,
            borderStyle: 'solid',
            //borderWidth: "2px",
            padding: '0 60px',
            '&:hover': {
                backgroundColor: theme.colors.blue.light,
                borderColor: theme.colors.blue.dark,
                borderStyle: 'solid'
                //borderWidth: "2px"
            }
        },
        selected: {
            ...baseButton,
            color: theme.colors.blue.dark,
            backgroundColor: 'transparent',
            borderColor: theme.colors.blue.dark,
            borderStyle: 'solid',
            borderWidth: '2px',
            '&:hover': {
                backgroundColor: 'transparent',
                borderColor: theme.colors.blue.dark,
                borderStyle: 'solid'
                //borderWidth: "2px"
            },
            '&:disabled': {
                color: 'white',
                backgroundColor: theme.colors.blue.dark,
                borderColor: theme.colors.blue.dark,
                borderStyle: 'solid',
                borderWidth: '2px'
            }
        }
        // disabled: {
        //     ...baseButton,
        //     color: theme.colors.gray.darker,
        //     borderColor: theme.colors.gray.darker,
        //     backgroundColor: theme.colors.gray.light,
        //     borderStyle: 'solid',
        //     borderWidth: '2px',
        //     padding: '0 60px',
        //     '&:hover': {
        //         backgroundColor: theme.colors.gray.dark
        //     }
        // }
    };
};

class AxButton extends React.PureComponent {
    render() {
        const { variant, classes, className, ...other } = this.props;
        let content = this.props.children;

        //default button variant
        let btnVariant = 'text';

        //useful when we have more custom variants
        if (variant === 'outlined' || variant === 'selected') {
            btnVariant = 'outlined';
        } else if (variant === 'contained' || variant === 'disabled') {
            btnVariant = 'contained';
        }

        return (
            <Button
                className={classNames(this.props.classes[variant], className)}
                variant={btnVariant}
                {...other}
            >
                {content}
            </Button>
        );
    }
}

export default exporter(AxButton)
    .withStyles(styles)
    .export();
