import { handleActions } from 'redux-actions';
import types from './types';
import jsonPatch from 'fast-json-patch';

const reducer = handleActions(
    {
        [types.get_frameworks.started]: state => {
            return { ...state };
        },
        [types.get_frameworks.success]: (state, { payload }) => {
            const tags = payload.reduce((map, tag) => {
                map[tag.id] = { ...tag };
                return map;
            }, {});

            const result = { ...state, tags: { ...state.tags, ...tags } };
            return result;
        },
        [types.create_framework.success]: (state, { payload }) => {
            return state;
        },

        [types.tag_added]: (state, { payload }) => {
            var result = { ...state, tags: { ...state.tags } };
            result.tags[payload.id] = payload;
            return result;
        },

        [types.patch_tag.patched]: (state, { payload }) => {
            const current = state.tags[payload.id];

            if (current) {
                let patchedTag = jsonPatch.applyPatch(
                    { ...current },
                    payload.patch
                ).newDocument;
                var tags = { ...state.tags };
                tags[payload.id] = patchedTag;
                return { ...state, tags };
            }

            return state;
        },

        [types.create_question.success]: (state, { payload }) => {
            var questions = { ...state.questions };
            questions[payload.id] = payload;
            var result = { ...state, questions: questions };
            return result;
        },

        [types.get_tag_questions.success]: (state, { payload }) => {
            const questions = payload.reduce((map, question) => {
                map[question.id] = { ...question };
                return map;
            }, {});

            const result = {
                ...state,
                questions: { ...state.questions, ...questions }
            };
            return result;
        },

        [types.patch_revision.success]: (state, { payload }) => {
            var questions = { ...state.questions };
            var question = { ...questions[payload.questionId] };

            question.revision = payload;
            questions[payload.questionId] = question;

            return { ...state, questions: questions };
        },

        [types.get_issues.success]: (state, { payload }) => {
            return { ...state, issues: { loaded: true, items: payload.items } };
        },

        // controls
        [types.create_control.success]: (state, { payload }) => {
            var controls = { ...state.controls };
            controls[payload.id] = payload;
            var result = { ...state, controls: controls };
            return result;
        },

        [types.get_tag_controls.success]: (state, { payload }) => {
            const controls = payload.reduce((map, control) => {
                map[control.id] = { ...control };
                return map;
            }, {});

            const result = {
                ...state,
                controls: { ...state.controls, ...controls }
            };
            return result;
        },

        [types.patch_control_revision.success]: (state, { payload }) => {
            var controls = { ...state.controls };
            var control = { ...controls[payload.controlId] };

            control.revision = payload;
            controls[payload.controlId] = control;

            return { ...state, controls: controls };
        }
    },
    { tags: {}, questions: {}, issues: { loaded: false }, controls: {} }
);

export default reducer;
