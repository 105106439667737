import types from './types.js';
import { ActionGenerator } from 'ax/utils';
import config from '../common/config';

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.overseerUrl,
        sendInstance: true
    }
});

const loadAppData = () =>
    generator.GET(types.load_app_data, {
        url: 'api/app/data',
        snackbar: { error: 'Could not load app data' }
    });

const updateAppData = payload => {
    return {
        type: types.update_app_data,
        payload
    };
};

export { loadAppData, updateAppData };
