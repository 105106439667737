import { exporter } from 'utils/exporter';
import React from 'react';
import { format, parseISO } from 'date-fns';
import {
    Paper,
    Button,
    IconButton,
    Popover,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteInstance } from 'instances/actions';
import {
    deleteInstanceRequest,
    getInstancesWithLicencesFiltered
} from './selectors';
import { AxTable } from 'ax/components';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';

const styles = theme => ({
    dangerText: {
        color: 'red'
    },
    iconButton: {
        marginBottom: theme.spacing(),
        marginTop: theme.spacing(),
        color: 'red',
        '&:disabled': {
            color: theme.colors.gray.darker
        }
    },
    statsButton: {
        marginBottom: theme.spacing(),
        marginTop: theme.spacing(),
        color: theme.colors.blue.dark,
        '&:disabled': {
            color: theme.colors.gray.darker
        }
    }
});

const stateProps = function (state, props) {
    return {
        deleteInstanceRequest: deleteInstanceRequest(state),
        instances: getInstancesWithLicencesFiltered(state, props)
    };
};

const dispatchProps = {
    deleteInstance
};

class InstanceList extends React.Component {
    state = {
        anchorEl: null,
        open: false,
        popoverId: null,
        instanceId: null,
        instanceName: null
    };

    showDeletePopover = (event, item) => {
        this.setState({
            anchorEl: event.currentTarget,
            popoverId: event.currentTarget + '-popover',
            instanceId: item.id,
            instanceName: item.companyName
        });
    };

    popoverClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
            popoverId: null,
            instanceName: null
        });
    };

    confirmDelete = instanceId => () => {
        return this.props.deleteInstance(instanceId).then(() => {
            this.popoverClose();
        });
    };

    tableOptions = {
        size: 'medium',
        headers: [
            {
                name: 'Id',
                template: item => item.id
            },
            {
                name: 'Company',
                sortable: true,
                orderBy: item => item.companyName,
                template: item => item.companyName
            },
            {
                name: 'URL',
                sortable: true,
                orderBy: item => item.url,
                template: item => item.url
            },
            {
                name: 'Created',
                template: item => (item.isCreated ? 'yes' : 'no')
            },
            {
                name: 'Date Created',
                sortable: true,
                orderBy: item => item.created,
                template: item => format(parseISO(item.created), 'dd/MM/yyyy')
            },
            {
                name: 'Owners Email',
                sortable: true,
                orderBy: item => item.creatorsEmail,
                template: item => item.creatorsEmail
            },
            {
                name: 'Activity',
                template: item => (
                    <IconButton
                        component={Link}
                        to={`/instances/${item.id}/activities`}
                        className={this.props.classes.statsButton}
                    >
                        <InsertChartOutlinedIcon />
                    </IconButton>
                )
            },
            {
                name: 'Delete',
                template: item => (
                    <IconButton
                        onClick={e => this.showDeletePopover(e, item)}
                        className={this.props.classes.iconButton}
                    >
                        <DeleteIcon />
                    </IconButton>
                )
            }
        ]
    };

    render() {
        const { instances, classes, deleteInstanceRequest } = this.props;
        const { anchorEl, instanceName } = this.state;
        const open = Boolean(anchorEl);
        const disabledButtons =
            deleteInstanceRequest.called && deleteInstanceRequest.loading;

        return (
            <>
                <Paper>
                    <AxTable items={instances} options={this.tableOptions} />
                </Paper>
                <Popover
                    open={open}
                    onClose={this.popoverClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <DialogTitle>
                        {`Are you sure you want to delete the instance ${instanceName}?`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dangerText}>
                            PLEASE NOTE THIS IS A HARD DELETE WHICH CANNOT BE
                            UNDONE!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={this.confirmDelete(this.state.instanceId)}
                            color="primary"
                            disabled={disabledButtons}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={this.popoverClose}
                            color="primary"
                            disabled={disabledButtons}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Popover>
            </>
        );
    }
}

export default exporter(InstanceList)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .export();
