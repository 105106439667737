import React from 'react';
import { Button, Typography, Paper } from '@material-ui/core';
import { getQuestionsForTag, createQuestion } from 'prism/actions';
import {
    questionsLoading,
    getGroupedQuestions
} from 'prism/frameworks/manage/questions/selectors';
import { Loader } from 'ax/Loader';
import { history } from 'ax/utils';
import QuestionListItem from 'prism/frameworks/manage/questions/QuestionListItem';
import AddIcon from '@material-ui/icons/Add';
import { exporter } from 'utils/exporter';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    List
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import types from '../../../types';
import { send } from 'ax/SignalR';

const stateProps = function(state, props) {
    return {
        questionsLoading: questionsLoading(state),
        questions: getGroupedQuestions(state, props)
    };
};

const dispatchProps = {
    getQuestionsForTag: getQuestionsForTag,
    createQuestion
};

const styles = theme => ({
    topPadding: {
        paddingTop: theme.spacing(2)
    },
    topMargin: {
        marginTop: theme.spacing(2)
    },
    groupedQuestions: {
        margin: theme.spacing(2)
    },
    groups: {
        marginRight: theme.spacing(2)
    }
});

class QuestionsList extends React.Component {
    componentDidMount() {
        this.props.getQuestionsForTag(this.props.tagId, 1);
    }

    componentDidUpdate() {
        //
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    expansionChange = name => (event, expanded) => {
        let update = {};
        update[name] = expanded;
        this.setState(update);
    };

    renderQuestions = () => {
        const { classes } = this.props;
        if (!this.props.questionsLoading) {
            let result = [];
            for (const name in this.props.questions) {
                if (this.props.questions.hasOwnProperty(name)) {
                    const element = this.props.questions[name];
                    if (element.length) {
                        result.push(
                            <ExpansionPanel
                                expanded={this.state[name]}
                                className={classes.groups}
                                key={name}
                                onChange={this.expansionChange(name)}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>{name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container>
                                        {element &&
                                            element.map(question => {
                                                return (
                                                    <QuestionListItem
                                                        key={question.id}
                                                        question={question}
                                                        selected={
                                                            this.props
                                                                .selectedQuestionId ==
                                                            question.id
                                                        }
                                                    />
                                                );
                                            })}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    }
                }
            }
            return result;
        }
    };

    createClick = () => {
        this.props.createQuestion(this.props.tagId).then(data => {
            send({ type: types.create_question.success, payload: data });
            history.push('./' + data.id);
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <Loader loading={this.props.questionsLoading}>
                <React.Fragment>
                    <Button
                        onClick={this.createClick}
                        variant="contained"
                        color="primary"
                        margin="normal"
                        className={classes.topMargin}
                    >
                        New Question
                        <AddIcon className={classes.rightIcon} />
                    </Button>
                    <List>{this.renderQuestions()}</List>
                </React.Fragment>
            </Loader>
        );
    }
}

export default exporter(QuestionsList)
    .withComponentState()
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .export();
