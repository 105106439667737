import React from 'react';
import { exporter } from 'utils/exporter';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { Paper, IconButton, Grid, Typography } from '@material-ui/core';
import { Loader } from 'ax/Loader';
import { AxTable } from 'ax/components';
import { AxButton } from 'ax/components';
import { getAllLicencesRequestState } from '../selectors';
import EditIcon from '@material-ui/icons/Edit';

const stateProps = function (state, props) {
    return {
        allLicenceRequestState: getAllLicencesRequestState(state, props)
    };
};

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    flex: {
        flex: 1
    },
    loading: {
        textAlign: 'center'
    },
    paper: {
        textAlign: 'center'
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    icon: {
        color: theme.colors.blue.dark,
        marginBottom: theme.spacing(),
        marginTop: theme.spacing()
    },
    buttonContainer: {
        marginBottom: theme.spacing(4)
    }
});

class LicenceList extends React.Component {
    tableOptions = {
        size: 'medium',
        headers: [
            {
                name: 'Id',
                template: item => item.id
            },
            {
                name: 'Instance Id',
                template: item => item.instanceId
            },
            {
                name: 'Instance Identifier',
                sortable: true,
                orderBy: item => item.instanceIdentifier,
                template: item => item.instanceIdentifier
            },
            {
                name: 'Start Date',
                sortable: true,
                orderBy: item => item.startDate,
                template: item => item.startDate
            },
            {
                name: 'End Date',
                sortable: true,
                orderBy: item => item.endDate,
                template: item => item.endDate
            },
            {
                name: 'Type',
                sortable: true,
                orderBy: item => item.licenceType,
                template: item => item.licenceType
            },
            {
                name: 'Edit',
                template: item => {
                    return (
                        <IconButton
                            component={Link}
                            to={'/licences/' + item.id}
                            className={this.props.classes.icon}
                        >
                            <EditIcon />
                        </IconButton>
                    );
                }
            }
        ]
    };
    render() {
        const {
            licenceState,
            classes,
            licences,
            allLicenceRequestState
        } = this.props;
        if (allLicenceRequestState.loading && !allLicenceRequestState.success) {
            return (
                <Grid container>
                    <Loader loading={true} />
                </Grid>
            );
        }

        return (
            <div>
                <Grid
                    container
                    justify="space-between"
                    alignItems="flex-end"
                    className={classes.buttonContainer}
                >
                    <Typography variant="h5">Licences</Typography>
                    <AxButton
                        variant="containedMicro"
                        buttonColor="green"
                        component={Link}
                        to="/licences/create"
                    >
                        <AddIcon /> Create Licence
                    </AxButton>
                </Grid>

                <Paper className={classes.paper}>
                    <AxTable items={licences} options={this.tableOptions} />
                </Paper>
            </div>
        );
    }
}

export default exporter(LicenceList)
    .withState(stateProps)
    .withStyles(styles)
    .export();
