import { createSelector } from 'reselect';
import types from '../types';
import { requestLookup } from 'ax/rest';

const getTagsState = state => state.prism.tags;
const getFrameworkCreationState = state =>
    requestLookup(state, types.create_framework);
const getFrameworkRequestState = state =>
    requestLookup(state, types.get_frameworks);
const getTagIdParam = (state, props) => props.match.params.tagId;

//Component state
const getTagsListState = state =>
    state.components.TagList
        ? state.components.TagList
        : { tags: true, frameworks: true, search: undefined };

const frameworksSelector = createSelector([getTagsState], tags => {
    return Object.keys(tags)
        .map(id => tags[id])
        .filter(x => x.isFramework);
});

const frameworksLoading = createSelector(
    [getFrameworkRequestState],
    getFrameworkRequestState => {
        return getFrameworkRequestState.loading;
    }
);

const getTags = createSelector([getTagsState], tags => {
    return Object.keys(tags).map(id => tags[id]);
});

const getTagsListFiltered = createSelector(
    [getTagsState, getTagsListState],
    (tags, liststate) => {
        var returnTags = Object.keys(tags).map(id => tags[id]);
        if (!liststate.frameworks) {
            returnTags = returnTags.filter(x => !x.isFramework);
        }

        if (!liststate.tags) {
            returnTags = returnTags.filter(x => x.isFramework);
        }

        if (liststate.search) {
            var search = liststate.search.toLowerCase();
            returnTags = returnTags.filter(x =>
                x.id.toLowerCase().includes(search)
            );
        }

        return returnTags;
    }
);

const getTagId = createSelector([getTagIdParam], tagId => {
    return tagId;
});

const getTag = createSelector([getTagIdParam, getTagsState], (tagId, tags) => {
    return tags[tagId] || {};
});

const incompleteTags = createSelector([getTagsState], tags => {
    let mapped = Object.keys(tags).map((key, i) => {
        return tags[key];
    });

    let incomplete = mapped.filter(x => !x.name);
    return incomplete;
});

export {
    frameworksSelector,
    frameworksLoading,
    getTags,
    getTagsListFiltered,
    getTagId,
    getTag,
    incompleteTags
};
