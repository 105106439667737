import React from 'react';
import { exporter } from 'utils/exporter';
import TextField from 'ax/form/TextField';

//Styles that we want to apply to ALL AxTextFields
const baseTextField = {
    borderRadius: 2,
    borderStyle: 'solid',
    borderWidth: 1
};

const styles = theme => ({
    cssLabel: {
        color: 'black',

        transform: 'translate(14px, 14px)',
        '&$cssFocused': {
            color: theme.colors.blue.dark
        }
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            ...baseTextField
        }
    },
    cssOutlinedInput: {
        backgroundColor: '#FFFFFF',
        '& $notchedOutline': {
            ...baseTextField,
            borderColor: theme.colors.blue.darker
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            ...baseTextField,
            borderColor: theme.colors.blue.dark,
            borderWidth: 1,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                borderColor: 'blue'
            }
        },
        '&$focused $notchedOutline': {
            ...baseTextField,
            borderColor: theme.colors.blue.dark,
            borderWidth: 1
        },
        '&$error $notchedOutline': {
            ...baseTextField
        },
        '&$disabled $notchedOutline': {
            ...baseTextField
        }
    },
    focused: {},
    error: {},
    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {},
    notchedOutline: {},
    input: {
        padding: '14px 11px'
    }
});

class AxTextField extends React.PureComponent {
    render() {
        const {
            classes,
            className,
            InputProps,
            InputLabelProps,
            ...other
        } = this.props;

        //set the default style to be outlined if no variant is provided
        other.variant = other.variant ? other.variant : 'outlined';

        let customInputProps = {};
        let customInputLabelProps = {};

        if (other.variant === 'outlined') {
            customInputProps = {
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.focused,
                    errored: classes.errored,
                    disabled: classes.disabled,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input
                }
            };

            customInputLabelProps = {
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                }
            };
        }

        if (InputProps) {
            // Explicitly combine classes from local (custom) and incoming (input) props
            var combinedClasses = {
                ...customInputProps.classes,
                ...InputProps.classes
            };
            customInputProps = {
                ...customInputProps,
                ...InputProps
            };
            customInputProps.classes = combinedClasses;
        }

        if (InputLabelProps) {
            customInputLabelProps = {
                ...customInputLabelProps,
                ...InputLabelProps
            };
        }

        return (
            <TextField
                InputLabelProps={customInputLabelProps}
                InputProps={customInputProps}
                {...other}
            />
        );
    }
}

AxTextField = exporter(AxTextField)
    .withStyles(styles)
    .export();

AxTextField.displayName = 'AxTextField';

export default AxTextField;
