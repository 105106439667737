import React from 'react';
import { exporter } from 'utils/exporter';

import { Button, FormControl } from '@material-ui/core';
import { TextField } from 'redux-form-material-ui';
import { validators } from 'ax/form';
import { Field } from 'redux-form';
import { createTag, tagAdded } from '../../actions';
import { ColorPicker } from 'ax/form';
import AddIcon from '@material-ui/icons/Add';

import types from '../../types';
import { send } from 'ax/SignalR';

const stateProps = function(state, props) {
    return {
        // frameworks: frameworksSelector(state)
    };
};

const dispatchProps = {
    createTag: createTag,
    tagAdded: tagAdded
};

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1)
    }
});

class CreateFramework extends React.Component {
    constructor(props) {
        super(props);
        this.handleColourChange = this.handleColourChange.bind(this);
    }

    handleColourChange(colour) {
        this.props.change('colour', colour);
    }

    create = tag => {
        tag.isFramework = true;
        return this.props.createTag(tag).then(() => {
            this.props.tagAdded(tag);
            send({ type: types.tag_added, payload: tag });
            //history.push('./' + tag.id);
        });
    };

    // Color picker is done badly, revisit!
    render() {
        const {
            pristine,
            dirty,
            submitting,
            classes,
            handleSubmit
        } = this.props;
        return (
            <form onSubmit={handleSubmit(this.create)}>
                <FormControl>
                    <Field
                        name="id"
                        margin="normal"
                        validate={[validators.required]}
                        component={TextField}
                        label="Framework Name"
                    />
                </FormControl>
                <Field
                    ref={input => (this.colourValue = input)}
                    name="colour"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="colour"
                    component={ColorPicker}
                    onColourChange={this.handleColourChange}
                />
                {/* <Button type="submit" disabled={pristine || submitting || !dirty} className={classes.formButton} margin="normal" variant="contained" colour="primary">Save</Button> */}
                <Button
                    to="/prism/frameworks/create/"
                    variant="contained"
                    color="primary"
                    type="submit"
                    margin="normal"
                    disabled={pristine || submitting || !dirty}
                    className={classes.formButton}
                >
                    Create
                    <AddIcon className={classes.rightIcon} />
                </Button>
            </form>
        );
    }
}

export default exporter(CreateFramework)
    .withForm()
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .export();

// CreateFramework = formConnect(CreateFramework, "createFramework");
// export default connect(stateProps, dispatchProps)(withStyles(styles)(CreateFramework));
