import types from './types.js';
import { ActionGenerator } from 'ax/utils';
import config from '../common/config';

const generator = new ActionGenerator({
    rest: {
        baseUrl: config.overseerUrl,
        sendInstance: false
    }
});

const getAll = () => {
    return generator.create({
        action: generator.GET,
        type: types.load_licences,
        shouldUpdate: ({ context, request }) => {
            return !request.called;
        },
        options: {
            url: 'api/licences',
            snackbar: { error: 'Could not get licences' }
        }
    });
};

const createLicence = newLicence => {
    return generator.create({
        action: generator.POST,
        type: types.create_licence,
        options: {
            url: 'api/licences',
            data: newLicence,
            snackbar: { error: 'Failed to create licence' }
        },
        key: newLicence.id
    });
};

const editLicence = (licenceId, update) => {
    return generator.create({
        action: generator.POST,
        type: types.edit_licence,
        options: {
            url: `api/licences/${licenceId}`,
            data: update,
            snackbar: {
                success: 'Licence successfully edited',
                error: 'Failed to edit licence'
            }
        }
    });
};

export { getAll, createLicence, editLicence };
