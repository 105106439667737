import { generateTypes } from 'ax/utils';

export default {
    // Get user list
    get: 'USERS/GET/STARTED',
    stale: 'USERS/GET/STALE',
    get_success: 'USERS/GET/SUCCESS',
    get_failed: 'USERS/GET/FAILED',
    get_all_users: generateTypes('USERS/GET'),

    // Single user
    get_user: 'USER/GET/STARTED',
    get_user_success: 'USER/GET/SUCCESS',
    get_user_failed: 'USER/GET/FAILED',
    user_changed: 'USER/CHANGED',
    get_user_activity: generateTypes('USER/GET/ACTIVITY'),
    get_user_browser_info: generateTypes('USER/GET/BROWSER/INFO'),

    // Patch a user
    patch_started: 'USERS/PATCH/STARTED',
    patch_success: 'USERS/PATCH/SUCCESS',
    patch_failed: 'USERS/PATCH/FAILED',
    patch_user: generateTypes('USERS/PATCH'),

    force_reset_password: generateTypes('USER/PASSWORD/RESET')
};
