import React from 'react';
import { exporter } from 'utils/exporter';
import { Route, Switch } from 'react-router';
import InstanceActivities from './InstanceActivities';
import ListContainer from './listContainer';
import { getInstances } from './actions';
import { instanceRequestState } from './selectors';
import { getAll } from '../licences/actions';
import { getLicences } from '../licences/selectors';

const stateProps = function (state, props) {
    return {
        requestState: instanceRequestState(state),
        licences: getLicences(state)
    };
};

const dispatchProps = {
    getInstances,
    getAll
};

class Instances extends React.Component {
    componentDidMount() {
        let requestState = this.props.requestState;
        if (!requestState.called) {
            this.props.getInstances();
        }

        if (this.props.licences.length == 0) {
            // If no licences loaded then trigger the lices
            this.props.getAll();
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route
                        path="/instances/:instanceId/activities"
                        render={props => <InstanceActivities {...props} />}
                    />
                    <Route
                        exact
                        path="/instances"
                        render={() => <ListContainer />}
                    />
                </Switch>
            </div>
        );
    }
}

export default exporter(Instances)
    .withState(stateProps, dispatchProps)
    .withRouter()
    .export();
