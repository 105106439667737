import { generateTypes } from 'ax/utils';

let tempTag = generateTypes('PRISM/TAGS/PATCH');
tempTag.patched = 'PRISM/TAGS/PATCH/PATCHED';

export default {
    // Get user list
    tab_changed: 'PRISM/TABS/CHANGED',

    tag_added: 'PRISM/TAGS/TAG/ADDED',
    tag_updated: 'PRISM/TAGS/TAG/UPDATED',

    //REST
    get_frameworks: generateTypes('PRISM/TAGS/FRAMEWORKS/GET/'),
    get_issues: generateTypes('PRISM/REPORTS/ISSUES/GET/'),
    create_framework: generateTypes('PRISM/TAGS/FRAMEWORK/CREATE/'),

    patch_tag: tempTag, //generateTypes('PRISM/TAGS/PATCH'),

    get_tag_questions: generateTypes('PRISM/TAGS/QUESTIONS/GET/'),
    create_question: generateTypes('PRISM/TAGS/QUESTIONS/CREATE/'),

    patch_revision: generateTypes('PRISM/QUESTIONS/REVISION/PATCH/'),

    // controls
    get_tag_controls: generateTypes('PRISM/TAGS/CONTROLS/GET/'),
    create_control: generateTypes('PRISM/TAGS/CONTROLS/CREATE/'),
    patch_control_revision: generateTypes('PRISM/CONTROLS/REVISION/PATCH/')
};
