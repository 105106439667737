import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from 'layout/withRoot';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { getUsers } from './selectors';
import UserListContainer from './components/listContainer';
import UserItemContainer from './components/itemContainer';
import UserActivities from './components/userActivities';
import UserBrowserInfo from './components/userBrowserInfo';

const stateProps = function (state, props) {
    return {
        userState: state.users,
        users: getUsers(state, props)
    };
};

const dispatchProps = {};

const styles = theme => ({});

class Users extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route
                        exact
                        path="/users/:userId/activities"
                        render={props => <UserActivities {...props} />}
                    />
                    <Route
                        exact
                        path="/users/:userId/browser"
                        render={props => <UserBrowserInfo {...props} />}
                    />
                    <Route
                        path="/users/:userId"
                        render={props => <UserItemContainer {...props} />}
                    />
                    <Route
                        exact
                        path="/users"
                        render={() => <UserListContainer />}
                    />
                </Switch>
            </div>
        );
    }
}

export default connect(
    stateProps,
    dispatchProps
)(withRoot(withStyles(styles)(Users)));
