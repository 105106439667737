import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from 'layout/withRoot';
import { Route, Switch, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Tags from './tags';
import IssuesReport from './reports/issues';
import Frameworks from './frameworks';
import { Loader } from 'ax/Loader';
import { exporter } from 'utils/exporter';

import AddIcon from '@material-ui/icons/Add';

import { frameworksLoading } from './selectors';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getInstances } from 'instances/actions';
import { getInstancesFiltered } from 'instances/selectors';
import { getTags } from './actions';

import { Grid, Paper, Tabs, Tab, Slide, Button } from '@material-ui/core';

const stateProps = function(state, routeParams) {
    return {
        routeParams,
        loading: frameworksLoading(state),
        instances: getInstancesFiltered(state)
    };
    // return {
    //     userState: state.users,
    //     users: getUsers(state)
    // }
};

const dispatchProps = {
    getTags,
    getInstances
};

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    rel: {
        position: 'relative'
    },
    gridContainer: {
        textAlign: 'center',
        padding: theme.spacing(2)
    },
    grid: {
        padding: theme.spacing(2),
        border: '1px solid black'
    },
    paper: {
        textAlign: 'center'
    }
});

const AnimatedPageRoute = props => (
    <Route path={props.path} exact={props.exact}>
        <Slide
            in={true}
            direction="up"
            mountOnEnter={true}
            unmountOnExit={true}
        >
            {props.children}
        </Slide>
    </Route>
);

class Prism extends React.Component {
    componentDidMount() {
        this.props.getTags();
        this.props.getInstances();
    }

    render() {
        const { classes, routeParams, loading } = this.props;
        return (
            <Grid container>
                {/* <Grid item xs={12}>
                        <Paper className={classes.root} square={true}>
                            <Tabs
                                value={routeParams.match.params.tab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab to="/prism/frameworks/" value="frameworks" component={Link} label="Frameworks" />
                                <Tab to="/prism/tags/" value="tags" component={Link} label="Tags" />
                            </Tabs>
                        </Paper>
                    </Grid> */}
                <Grid item xs={12}>
                    <Loader loading={loading}>
                        <div>
                            <Switch>
                                <Route path="/prism/frameworks/">
                                    <Frameworks />
                                </Route>
                                <Route path="/prism/reports/issues">
                                    <IssuesReport />
                                </Route>
                                <Route path="/prism/">
                                    <Tags />
                                </Route>
                            </Switch>
                        </div>
                    </Loader>
                </Grid>
            </Grid>

            // <Paper className={classes.root}>
            //     <Tabs
            //         value={routeParams.match.params.tab}
            //         indicatorColor="primary"
            //         textColor="primary"
            //         centered
            //     >
            //         <Tab to="/prism/frameworks" value="frameworks" component={Link} label="Frameworks" />
            //         <Tab to="/prism/tags" value="tags" component={Link} label="Tags" />
            //     </Tabs>

            //     <Slide in={true} direction="up" mountOnEnter={true} unmountOnExit={true}>

            //         <Switch>
            //             <AnimatedPageRoute path="/prism/frameworks">
            //                 <Frameworks />
            //             </AnimatedPageRoute>
            //             <AnimatedPageRoute path="/prism/tags">
            //                 <Tags />
            //             </AnimatedPageRoute>
            //         </Switch>
            //     </Slide>

            // <Paper >
            // <div className={classes.rel}>
            //     <TransitionGroup>
            //         <CSSTransition key={location.key} classNames="fade" timeout={100}>
            //             <Switch location={location}>
            //                <Route path="/prism/frameworks" render={(props) => <Frameworks/> } />
            //                 <Route path="/prism/tags" render={() => <Tags />} />
            //             </Switch>
            //         </CSSTransition>
            //      </TransitionGroup>
            //      </div>
            //  </Paper>

            // <Switch>
            //     <Route path="/prism/frameworks" render={(props) => <Frameworks/> } />
            //     <Route path="/prism/tags" render={() => <Tags />} />
            // </Switch>
            // </Paper>
        );
    }
}

//export default withRoot(Users);

export default exporter(Prism)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRoot()
    .withRouter()
    .export();

//export default withRouter(connect(stateProps, dispatchProps)(withRoot(withStyles(styles)(Prism))));
