import { encode, decode } from 'utils/string';
import { isJson } from 'utils/json';
import { validators } from 'ax/form';

let cleanDocument = (value, method) => {
    let newDocument = value.document;
    for (const key in newDocument.nodes) {
        if (newDocument.nodes.hasOwnProperty(key)) {
            const node = newDocument.nodes[key];

            newDocument.nodes[key] = cleanNode(node, method);
        }
    }
    return { ...value, document: newDocument };
};

let cleanNode = (node, method) => {
    let newNode = node;

    if (newNode.text) {
        newNode.text = method(newNode.text);
    }

    if (newNode.nodes) {
        let newNodes = [];
        for (const key in newNode.nodes) {
            if (newNode.nodes.hasOwnProperty(key)) {
                const element = newNode.nodes[key];

                newNodes[key] = cleanNode(element, method);
            }
        }
        newNode.nodes = newNodes;
    }

    return newNode;
};

// Used to clean up any html tags within text
let encodeDocument = value => {
    return cleanDocument(value, encode);
};

// Decodes any encoded values such as < > / &
let decodeDocument = value => {
    if (!isJson(value)) return value;

    // Parse it to work with it - this could be made smarter to see if we need to.
    let parsed = JSON.parse(value);

    if (!parsed || !parsed.document) return value;

    //Return it stringified as expected
    return JSON.stringify(cleanDocument(parsed, decode));
};

const convertNode = node => {
    const { object, type, data, nodes, ...rest } = node;
    // We drop `object`, pull up data, convert `nodes` to children and copy the rest across
    const element = {
        type,
        ...rest,
        ...(nodes ? { children: nodes.map(convertNode) } : {})
    };
    if (
        (!element.type || element.type === 'text') &&
        typeof element.text !== 'undefined'
    ) {
        delete element.type;
        if (Array.isArray(element.marks)) {
            for (const mark of element.marks) {
                if (typeof mark === 'string') element[mark] = true;
                else if (typeof mark === 'object' && mark.type) {
                    element[mark.type] = mark.hasOwnProperty('value')
                        ? mark.value
                        : true;
                }
            }
        }
    }
    if (data) element.data = data;
    // if(element.type) element.type = element.type.replace("-", "_")

    // Atomic blocks must now have children
    if (element.type && !element.children) {
        element.children = [
            {
                text: ''
            }
        ];
    }
    return element;
};
const convertSlate047to050 = object => {
    const { nodes } = object.document;
    return nodes.map(convertNode);
};

const checkSlateText = arr => {
    let result;
    if (arr) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].children) {
                result = arr[i].children.find(
                    x => typeof x.text === 'string' && x.text.trim().length > 0
                );
            }
            if (result) {
                result = result.text;
                return result;
            }
            if (arr[i].children) {
                result = checkSlateText(arr[i].children);
            }
            if (result) {
                return result;
            }
        }
    }
};

const slateValidator = value => {
    let text;
    if (value) {
        let parsedValue = JSON.parse(value);
        text = checkSlateText(parsedValue);
    }
    return validators.required(text);
};

export { encodeDocument, decodeDocument, convertSlate047to050, slateValidator };
