import React from 'react';
import { history } from 'ax/utils';

import { exporter } from 'utils/exporter';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white
            }
        }
    },
    primary: {},
    icon: {}
});

class QuestionListItem extends React.Component {
    componentDidMount() {}

    componentDidUpdate() {
        //
    }

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    questionSelected = () => {
        if (this.props.selected) return;
        history.push('./' + this.props.question.id);
    };

    render() {
        const { classes, question } = this.props;
        const { selected } = this.props;
        return (
            <ListItem
                button
                selected={selected}
                className={classes.menuItem}
                onClick={() => this.questionSelected()}
            >
                <ListItemText primary={question.revision.text} />
            </ListItem>
        );
    }
}

//Question = formConnect(Question);

export default exporter(QuestionListItem)
    .withStyles(styles)
    .export();
