import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import CreateFramework from '../create'

import { Description } from '@material-ui/icons';

import {
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Chip,
    Grid,
    Typography,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
} from '@material-ui/core';


import withRoot from 'layout/withRoot';

const styles = theme => ({
    gridContainer: {
        textAlign: 'center',
        //padding: theme.spacing.unit * 2,
    },
    gridContainerPadded: {
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    icon: {
        margin: theme.spacing(2),
        fontSize: 60,
        '&:hover': {
            color: "grey",
        },
    },
    grid: {
        padding: theme.spacing(2),
    },
    linkStyle: {
        flexGrow: 1,
        textDecoration: 'none',
        color: 'inherit'
    }
});


class FrameworkList extends React.Component {

    render() {
        const { frameworks, classes } = this.props;
        return (

            <Grid container className={classes.gridContainer}>
                <Grid item xs={12}>

                            <CreateFramework form="createFrameworkForm" />

                    {/* <Paper square={true}>
                        {/* <Switch>
                            <Route path="/prism/frameworks/">
                            <Grid container className={classes.gridContainerPadded}>
                            <Grid item xs={9}>
                                xs=9
                                </Grid>
                            <Grid item xs={3}>
                                <Button component={Link} to="/prism/frameworks/create/" variant="contained" color="primary" className={classes.button}>
                                    Framework
                                        <AddIcon className={classes.rightIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                            </Route>
                        </Switch> 

                        <Grid container className={classes.gridContainerPadded}>
                            
                        </Grid>

                        
                    </Paper> */}
                </Grid>

                {frameworks.map(n => {
                    return (
                        <Grid item key={n.id} xs={2} className={classes.grid}>
                            <Card>
                                <CardActionArea component="div">
                                    <CardContent component={Link} to={"/prism/frameworks/" + n.id + "/"} className={classes.linkStyle}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {n.id}
                                        </Typography>
                                        <Typography component="p">
                                            <Description className={classes.icon} style={{ color: n.colour }} />
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                    );
                })}

            </Grid>

        )
    }
}


export default withRoot(withStyles(styles)(FrameworkList));