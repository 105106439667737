import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import withRoot from '../withRoot';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createTag, tagAdded } from '../actions';
import ColorPicker from 'ax/form';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { validators } from 'ax/form';
import AddIcon from '@material-ui/icons/Add';
import Bookmark from '@material-ui/icons/Bookmark';
import Description from '@material-ui/icons/Description';

import { getTagsListFiltered } from '../selectors';

import types from '../types';
import { send } from 'ax/SignalR';

import { exporter } from 'utils/exporter';

import TagList from './TagList';
import TagContainer from './TagContainer';

import {
    Typography,
    Paper,
    Tabs,
    Tab,
    FormControl,
    Card,
    CardActionArea,
    Grid,
    Button,
    CardContent,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

const stateProps = function(state, routeParams) {
    return {
        routeParams,
        tags: getTagsListFiltered(state)
    };
};

const dispatchProps = {
    createTag: createTag,
    tagAdded: tagAdded
};

const styles = theme => ({
    gridContainer: {
        textAlign: 'center',
        flexGrow: 1
        //padding: theme.spacing.unit * 2,
    },
    gridContainerPadded: {
        textAlign: 'center',
        padding: theme.spacing(2)
    },
    icon: {
        margin: theme.spacing(2),
        fontSize: 60,
        '&:hover': {
            color: 'grey'
        }
    },
    grid: {
        padding: theme.spacing(2)
    },
    linkStyle: {
        flexGrow: 1,
        textDecoration: 'none',
        color: 'inherit'
    }
});

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.handleColourChange = this.handleColourChange.bind(this);

        this.state = {
            frameworks: true,
            tags: true,
            searchText: undefined
        };
    }

    increment = () => {
        this.setState({ count: this.state.count + 1 });
    };

    handleColourChange(colour) {
        this.props.change('colour', colour);
    }

    create = tag => {
        tag.isFramework = false;
        return this.props.createTag(tag).then(() => {
            this.props.tagAdded(tag);

            send({ type: types.tag_added, payload: tag });
        });
    };

    filter = name => event => {
        var update = {};
        update[name] = event.target.checked;
        this.setState(update);
    };

    search = event => {
        var update = { search: event.target.value };
        console.log(update);
        this.setState(update);
    };

    render() {
        const { tags, classes } = this.props;
        return (
            <Grid container className={classes.gridContainer}>
                <Switch>
                    <Route
                        exact
                        path="/prism/tags/create/"
                        render={props => (
                            <TagContainer mode="create" {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/prism/tags/:tagId"
                        render={props => (
                            <TagContainer mode="edit" {...props} />
                        )}
                    />
                    <Route path="/prism/">
                        <TagList
                            tags={tags}
                            filter={this.filter}
                            search={this.search}
                            filterState={this.state}
                        />
                    </Route>
                </Switch>
            </Grid>
        );
    }
}

//export default withRoot(Users);

export default exporter(Tags)
    .withComponentState(() => 'TagList')
    .withForm()
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRouter()
    .export();

// Tags = formConnect(Tags, "createTag");
// export default connect(stateProps, dispatchProps)(withStyles(styles)(Tags));
