import { generateTypes } from 'ax/utils';

export default {
    // Get licence list
    load_licences: generateTypes('LICENCES/GET'),

    // Single licence
    get_licence: 'LICENCE/GET',
    licence_changed: 'LICENCE/CHANGED',

    // Licence creation
    create_licence: generateTypes('LICENCE/CREATE'),

    // Edit a licence
    edit_licence: generateTypes('LICENCE/EDIT')
};
