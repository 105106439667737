import { createSelector } from 'reselect';
import { requestLookup } from 'ax/rest';
import types from './types';

const accessProfile = state => state.profile.profile;
const accessProfileRequestState = state =>
    requestLookup(state, types.load_profile);

const getProfile = createSelector(
    [accessProfile],
    profile => {
        return profile;
    }
);

const getProfileRequestState = createSelector(
    [accessProfileRequestState],
    requestState => {
        return requestState.called ? requestState.loading : false;
    }
);

export { getProfile, getProfileRequestState };
