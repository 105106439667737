import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { AxTextField } from 'ax/components';

// This file exists in core and needs to be merged one day...
export const AxDatePickerInput = ({
    timezone,
    showErrorsInline,
    input: { onChange, value, name },
    meta: { touched, error, form },
    dispatch,
    maxDate,
    ...other
}) => {
    return (
        <DatePicker
            name={name}
            maxDate={maxDate}
            error={touched && Boolean(error)}
            helperText={touched && error}
            value={value}
            onChange={onChange}
            format="dd/MM/yyyy"
            margin="normal"
            TextFieldComponent={AxTextField}
            {...other}
        />
    );
};

export default AxDatePickerInput;
