import { createSelector } from 'reselect'

const questionsState = (state) => state.prism.questions;
const selectedQuestionId = (state, props) => props.match.params.questionId;


const getQuestion = createSelector(
  [ selectedQuestionId, questionsState ],
  (questionId, questions) => {
    return questions[questionId] || {};//.find(x => x.id === id) || {};
  }
)


export {
  getQuestion
}