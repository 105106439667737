import React from 'react';
import { exporter } from 'utils/exporter';
import { Grid, Typography, Button } from '@material-ui/core';
import { AxTable } from 'ax/components';
import { getUserActivity } from '../actions';
import { userActivity, getUser, getUsers } from '../selectors';
import { formatDistance } from 'date-fns';
import { format, parseISO } from 'date-fns';
import { Paper } from '@material-ui/core';

const styles = theme => ({
    margin: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing()
    }
});

const stateProps = function (state, props) {
    return {
        userActivity: userActivity(state, props),
        getUser: getUser(state, props),
        users: getUsers(state, props)
    };
};

const dispatchProps = {
    getUserActivity
};

class UserActivitiesSessionTable extends React.Component {
    tableOptions = {
        size: 'small',
        headers: [
            {
                name: `Date`,
                sortable: true,
                orderBy: item => item.date,
                template: item => {
                    return (
                        <Grid item>
                            <Typography variant="body2">
                                {format(parseISO(item.date), 'Pp')}
                            </Typography>
                        </Grid>
                    );
                }
            },
            {
                name: 'Action',
                sortable: true,
                orderBy: item => item.action,
                template: item => (
                    <Grid item>
                        <Typography variant="body2">{item.action}</Typography>
                    </Grid>
                )
            }
        ]
    };

    getSessionDuration = (data, x) => {
        let duration = formatDistance(
            new Date(data[x][0].date),
            new Date(data[x][data[x].length - 1].date)
        );

        return duration;
    };

    render() {
        const { data } = this.props;

        return (
            <Grid item xs={12} container spacing={4}>
                {Object.keys(data).map(x => {
                    let start = data[x][data[x].length - 1].date;
                    let end = data[x][0].date;
                    return (
                        <Grid item xs={12} key={start + end}>
                            <Paper>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            className={
                                                this.props.classes.margin
                                            }
                                        >
                                            Session Start{' '}
                                            {format(parseISO(start), 'Pp')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            className={
                                                this.props.classes.margin
                                            }
                                        >
                                            Session End{' '}
                                            {format(parseISO(end), 'Pp')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            className={
                                                this.props.classes.margin
                                            }
                                        >
                                            Session Duration{' '}
                                            {this.getSessionDuration(data, x)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <AxTable
                                        options={this.tableOptions}
                                        items={data[x]}
                                    />
                                </Grid>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}

export default exporter(UserActivitiesSessionTable)
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .withRouter()
    .export();
