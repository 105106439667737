import React from 'react';
import { Field } from 'redux-form';

import {
    Button,
    Grid,
    Paper,
    Fade,
    Typography,
    CircularProgress
} from '@material-ui/core';

import { TextField } from 'redux-form-material-ui';

import { validators } from 'ax/form';
import { ValidationDisplay } from 'ax/error';
import { exporter } from 'utils/exporter';

const styles = theme => ({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing(20),
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        alignItems: 'center'
    },
    error: {
        color: 'red'
    }
});

const matchValidator = validators.match('password', 'Passwords must match');

class ResetPasswordForm extends React.Component {
    render() {
        const { classes, accountState } = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Reset Password
                </Typography>

                <Grid container justify="center">
                    <Grid item xs={6}>
                        <Fade in={!accountState.loading}>
                            <Paper className={classes.paper}>
                                <ValidationDisplay
                                    error={accountState.errorResult}
                                />
                                <form onSubmit={this.props.handleSubmit}>
                                    <div>
                                        <Field
                                            name="email"
                                            margin="normal"
                                            validate={[
                                                validators.required,
                                                validators.email
                                            ]}
                                            component={TextField}
                                            label="Email"
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="password"
                                            margin="normal"
                                            validate={[validators.required]}
                                            component={TextField}
                                            label="New Password"
                                            type="password"
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="confirmPassword"
                                            margin="normal"
                                            validate={[
                                                validators.required,
                                                matchValidator
                                            ]}
                                            component={TextField}
                                            label="Confirm New Password"
                                            type="password"
                                        />
                                    </div>
                                    <div>
                                        <Button type="submit" margin="normal">
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            </Paper>
                        </Fade>

                        <Fade in={accountState.loading}>
                            <CircularProgress className={classes.progress} />
                        </Fade>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default exporter(ResetPasswordForm)
    .withForm()
    .withState()
    .withStyles(styles)
    .export();

// ResetPasswordForm = formConnect(ResetPasswordForm, "resetPasswordForm");

// export default withStyles(styles)(ResetPasswordForm);
