import React from 'react';
import { getLicence } from '../selectors';
import LicenceItem from './item';
import { createLicence, editLicence } from '../actions';
import jsonPatch from 'fast-json-patch';
import { exporter } from 'utils/exporter';

const stateProps = function (state, props) {
    return {
        licence: getLicence(state, props)
    };
};

const dispatchProps = {
    createLicence: createLicence,
    editLicence
};

class LicenceItemContainer extends React.Component {
    saveItem = values => {
        // We have a licence, so its an update
        if (this.props.mode === 'edit') {
            let licence = { ...this.props.licence };
            let newLicence = { ...values };
            let { licenceData } = newLicence;

            delete licence['licenceData'];
            delete newLicence['licenceData'];

            var patch = jsonPatch.compare(licence, newLicence);

            var result = {
                licencePatch: patch,
                metaData: licenceData
            };

            return this.props.editLicence(licence.id, result);
        } else {
            // We dont have a licence object, create one
            return this.props.createLicence(values);
        }
    };

    render() {
        const { licence, mode } = this.props;

        return (
            <LicenceItem
                onSubmit={this.saveItem}
                mode={mode}
                initialValues={licence}
                form="LicenceItem"
            />
        );
    }
}

export default exporter(LicenceItemContainer)
    .withState(stateProps, dispatchProps)
    .export();
