import { restService } from 'ax/rest';
import config from '../common/config';

function login(email, password) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('resource', 'Phoenix');
    params.append('username', email);
    params.append('password', password);

    return restService.POST({
        url: config.auth.url + 'connect/token',
        data: params,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
}

function register(details) {
    details['AppUrl'] = config.frontEndUrl;

    return restService.POST({
        url: config.auth.url + 'api/Account/Register',
        data: details
    });
}

function forgotPassword(details) {
    details['AppUrl'] = config.frontEndUrl;

    return restService.POST({
        url: config.auth.url + 'api/Account/ForgotPassword',
        data: details
    });
}

function resetPassword(details) {
    return restService.POST({
        url: config.auth.url + 'api/Account/ResetPassword',
        data: details
    });
}

function confirmEmail(details) {
    return restService.POST({
        url: config.auth.url + 'api/Account/ConfirmEmail',
        data: details
    });
}

export default {
    login,
    register,
    forgotPassword,
    resetPassword,
    confirmEmail
};
