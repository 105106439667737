import React from 'react';
import { Field } from 'redux-form';
import { Button, Grid, Typography, Collapse } from '@material-ui/core';
import { Loader } from 'ax/Loader';
import { validators } from 'ax/form';
import { exporter } from 'utils/exporter';
import { Link } from 'react-router-dom';
import { AxTextField } from 'ax/components';
import { AxButton } from '../../common/AxButton';
import { resendTwoFaCode } from 'account/actions';
import { formValueSelector } from 'redux-form';

const styles = theme => ({
    loginroot: {
        textAlign: 'left',
        flexGrow: 1
    },
    error: {
        color: 'red'
    },
    buttonText: {
        color: theme.colors.blue.dark
    },
    buttonRoot: {
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            textDecorationColor: theme.colors.blue.dark
        }
    },
    forget: {
        textAlign: 'right'
    },
    loginTitle: {
        paddingBottom: theme.spacing(1)
    },
    loginButton: {
        paddingTop: '36px'
    },
    linkText: {
        color: theme.colors.blue.dark,
        padding: '6px 8px'
    },
    cursor: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const stateProps = (state, props) => {
    return {
        email: formValueSelector(props.form)(state, 'email')
    };
};

const dispatchProps = {
    resendTwoFaCode
};

class LoginForm extends React.Component {
    resendCode = () => {
        return this.props.resendTwoFaCode(this.props.email);
    };

    render() {
        const {
            classes,
            accountState,
            pristine,
            submitting,
            dirty,
            loginRequest
        } = this.props;

        let display2Fa =
            accountState.loginNotification &&
            accountState.loginNotification.errors
                ? accountState.loginNotification.errors.some(
                      e => e.field === '2fa-req' || e.field === 'code'
                  )
                : false;

        return (
            <Grid
                container
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.loginroot}
            >
                <Grid item xs={12} className={classes.loginTitle}>
                    <Typography variant="h4" gutterBottom>
                        Login
                    </Typography>
                </Grid>

                <Loader loading={loginRequest.loading && loginRequest.called}>
                    <Grid item xs={12}>
                        {accountState.loginNotification ? (
                            <Typography className={classes.error}>
                                {accountState.loginNotification.message}
                            </Typography>
                        ) : null}
                        <form onSubmit={this.props.handleSubmit}>
                            <Collapse in={!display2Fa}>
                                <Grid item xs={12}>
                                    <Field
                                        name="email"
                                        margin="normal"
                                        validate={[
                                            validators.required,
                                            validators.email
                                        ]}
                                        component={AxTextField}
                                        variant="outlined"
                                        fullWidth
                                        label="Email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="password"
                                        margin="normal"
                                        validate={[validators.required]}
                                        component={AxTextField}
                                        variant="outlined"
                                        fullWidth
                                        label="Password"
                                        type="password"
                                    />
                                </Grid>
                            </Collapse>

                            {display2Fa && (
                                <Grid item xs={12}>
                                    <Field
                                        name="code"
                                        margin="normal"
                                        component={AxTextField}
                                        variant="outlined"
                                        fullWidth
                                        label="Code"
                                        validate={[validators.required]}
                                    />
                                </Grid>
                            )}
                            <Grid container>
                                <Grid item xs={6} md={6} lg={6}>
                                    {display2Fa && (
                                        <Typography
                                            variant="body2"
                                            className={classes.linkText}
                                        >
                                            <a
                                                className={classes.cursor}
                                                onClick={this.resendCode}
                                            >
                                                Resend Code
                                            </a>
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    lg={6}
                                    className={classes.forget}
                                >
                                    <Button
                                        to={'/forgotpassword'}
                                        component={Link}
                                        classes={{
                                            root: classes.buttonRoot
                                        }}
                                        disableRipple
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.buttonText}
                                        >
                                            Forgot Password?
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.loginButton}>
                                <AxButton
                                    type="submit"
                                    margin="normal"
                                    variant="contained"
                                    fullWidth
                                    disabled={pristine || submitting || !dirty}
                                >
                                    Login
                                </AxButton>
                            </Grid>
                        </form>
                    </Grid>
                </Loader>
            </Grid>
        );
    }
}

export default exporter(LoginForm)
    .withForm()
    .withState(stateProps, dispatchProps)
    .withStyles(styles)
    .export();
