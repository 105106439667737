import { generateTypes } from 'ax/utils';

export default {
    login: generateTypes('ACCOUNT/LOGIN'),

    // Logout
    logout: 'ACCOUNT/LOGOUT',

    // Registration
    register: generateTypes('ACCOUNT/REGISTER'),

    // Forgot Password
    forgot_password_started: 'ACCOUNT/FORGOTPASSWORD/STARTED',
    forgot_password_success: 'ACCOUNT/FORGOTPASSWORD/SUCCESS',
    forgot_password_failed: 'ACCOUNT/FORGOTPASSWORD/FAILED',

    // Reset Password
    reset_password_started: 'ACCOUNT/RESETPASSWORD/STARTED',
    reset_password_success: 'ACCOUNT/RESETPASSWORD/SUCCESS',
    reset_password_failed: 'ACCOUNT/RESETPASSWORD/FAILED',

    // Confirm Email
    confirm_email_started: 'ACCOUNT/CONFIRMEMAIL/STARTED',
    confirm_email_success: 'ACCOUNT/CONFIRMEMAIL/SUCCESS',
    confirm_email_failed: 'ACCOUNT/CONFIRMEMAIL/FAILED',

    // Resend Email
    resend_email: generateTypes('ACCOUNT/RESENDEMAIL'),

    // Change Password
    change_password: generateTypes('ACCOUNT/CHANGEPASSWORD'),

    // Resend TwoFA Code
    resend_two_fa_code: generateTypes('ACCOUNT/TWOFACTOR/RESEND')
};
