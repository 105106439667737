import { handleActions } from 'redux-actions';
import types from './types';
import { colourGenerator } from 'ax/utils';

const reducer = handleActions(
    {
        [types.load_profile.success]: (state, { payload }) => {
            payload.avatarColour = colourGenerator(payload.id);
            return { ...state, profile: payload };
        },
        [types.patch_profile.success]: (state, { payload }) => {
            //var existing = { ...state.profile };
            //jsonPatch.applyPatch(existing, payload);
            return { ...state, profile: payload };
        },
        [types.avatar_updated]: state => {
            var existing = { ...state.profile };
            existing.profileImageExists = true;
            return { ...state, profile: existing };
        },
        [types.send_validation_code.success]: (state, { payload }) => {
            var existing = { ...state.profile };
            payload.avatarColour = existing.avatarColour;
            return { ...state, profile: payload };
        }
    },
    { profile: {} }
);

export default reducer;
