import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from 'layout/withRoot';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { exporter } from 'utils/exporter';

const stateProps = function(state) {
    return {
        user: state.account.user
    };
};

const styles = theme => ({});

class Account extends React.Component {
    state = {};

    render() {
        const { user } = this.props;
        return (
            <div>
                <Typography noWrap>ACCOUNT!</Typography>

                <pre>{JSON.stringify(user, null, 2)}</pre>
            </div>
        );
    }
}

export default exporter(Account)
    .withState(stateProps)
    .withStyles()
    .export();

//export default connect(stateProps, null)(withRoot(withStyles(styles)(Account)));
