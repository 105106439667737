import React from 'react';
import { history, exporter } from 'ax/utils';
import { login } from '../../account/actions';
import LoginForm from './LoginForm';
import { getLoginRequest } from '../../account/selectors';

const stateProps = function(state) {
    return {
        accountState: state.account,
        loginRequest: getLoginRequest(state)
    };
};
const dispatchProps = {
    login: login
};

class LoginFormContainer extends React.Component {
    login = values => {
        return this.props.login(values).then(() => {
            // Same as before but only runs if Authenticated
            if (this.props.accountState.authenticated) {
                // this is for redirects after login
                if (this.props.location.state) {
                    history.push(this.props.location.state.from);
                } else {
                    history.push('/');
                }
            }
        });
    };

    render() {
        const { accountState } = this.props;
        return (
            <LoginForm
                onSubmit={this.login}
                loginRequest={this.props.loginRequest}
                accountState={accountState}
                form="LoginForm"
            />
        );
    }
}

export default exporter(LoginFormContainer)
    .withState(stateProps, dispatchProps)
    .export();
