import React from 'react';
import { Route, Switch } from 'react-router';
import { getAll } from './actions';
import { getLicences } from './selectors';
import LicenceList from './components/list';
import { exporter } from 'utils/exporter';
import LicenceItemContainer from './components/itemContainer';
import { Typography } from '@material-ui/core';

const stateProps = function (state) {
    return {
        licenceState: state.licences,
        licences: getLicences(state)
    };
};

const dispatchProps = {
    getAll: getAll
};

class Licences extends React.Component {
    componentDidMount() {
        this.props.getAll();
    }

    render() {
        const { licenceState, licences } = this.props;
        return (
            <div>
                <Switch>
                    <Route
                        path="/licences/create"
                        render={props => (
                            <LicenceItemContainer mode="create" {...props} />
                        )}
                    />
                    <Route
                        path="/licences/:licenceId"
                        render={props => (
                            <LicenceItemContainer mode="edit" {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/licences"
                        render={() => (
                            <LicenceList
                                licenceState={licenceState}
                                licences={licences}
                            />
                        )}
                    />

                    {/* <Route component={NotFound} /> */}
                </Switch>
            </div>
        );
    }
}

export default exporter(Licences).withState(stateProps, dispatchProps).export();
