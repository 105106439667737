import { restService } from 'ax/rest';
import config from '../common/config';

function getAll() {
    return restService.GET({
        url: config.overseerUrl + 'api/users',
        showError: 'Could not load user data'
    });
}

function getUserById(userId) {
    return restService.GET({
        url: config.overseerUrl + 'api/users/' + userId,
        showError: 'Could not load user data'
    });
}

function getCountries() {
    return restService.GET({
        url: config.overseerUrl + 'api/users/country',
        showError: 'Could not load user data'
    });
}

function patchUser(userId, patch) {
    return restService.PATCH({
        url: config.overseerUrl + 'api/users/' + userId,
        showError: 'Failed to save.',
        data: patch
    });
}

export default {
    getAll,
    patchUser,
    getCountries,
    getUserById
};
